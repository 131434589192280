(angular => {
  "use strict";

  angular.module("App").factory("AuthService", [
    "$rootScope",
    "$timeout",
    "jwtHelper",
    "$window",
    authService
  ]);

  function authService($rootScope, $timeout, jwtHelper, $window) {

    let user;

    const isValidToken = token => {
      try {
        return token && !jwtHelper.isTokenExpired(token);
      } catch (err) {
        return false;
      }
    };

    const getToken = () => $window.sessionStorage.getItem("token");

    const getTokenData = () => {

      if (!isLoggedIn()) {
        return null;
      }

      user = jwtHelper.decodeToken(getToken());
      return user;
    };

    const setToken = token => {
      if (!isValidToken(token)) {
        return false;
      }

      $window.sessionStorage.setItem("token", token);
      $rootScope.$broadcast("login");
    };

    const removeToken = () => {
      $window.sessionStorage.clear();
      $rootScope.currentUser = false;

      $timeout(function() {
        $rootScope.$apply();
        $rootScope.$broadcast("logout");
      });

    };

    const isLoggedIn = () => isValidToken(getToken());

    const hasRole = (role) => {

      if (!isLoggedIn()) {
        return false;
      }

      if (typeof role === "string") {
        role = [role];
      }

      return role.indexOf(getTokenData().role) >= 0;
    };

    const hasEventSelected = () => {
      if (!isLoggedIn()) {
        return false;
      }

      return !!getTokenData().eventId;
    };

    const hasCompanyType = types => {

      types = typeof types === 'string' ? [types] : types;
      if (!types) {
        return true;
      }

      if (!user) {
        return false;
      }
      if (user.role !== 'company_manager') {
        return true;
      }

      return user.companyTypes.some(type => types.includes(type));
    };

    return {
      getTokenData,
      setToken,
      removeToken,
      isLoggedIn,
      getToken,
      hasRole,
      hasEventSelected,
      hasCompanyType
    };
  }

})(angular);
