(angular => {
  "use strict";

  angular.module("Common").factory("FormRedirectService", ["AuthService", service]);

  function service(AuthService) {

    return {
      submit: (url, params) => {
        params = params || {};
        const template = `
          <form action="${url}" method="post" target="_blank">
            <input type="hidden" name="authToken" value="${AuthService.getToken()}" />
            ${Object.keys(params).map(key => {
              return `<input type="hidden" name="${key}" value="${params[key] || ''}" />`;
            }).join("")}
          </form>
        `;

        const form = angular.element(template);
        form.appendTo("body");
        form.submit();
        form.remove();
      }
    };

  }

})(angular);
