(angular => {
  "use strict";

  angular.module("Accommodation").controller("Accommodation.FormPersonCtrl", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "AccommodationService",
    "rooms",
    "room",
    "person",
    "recreations",
    FormPersonCtrl
  ]);

  function FormPersonCtrl(
    $scope,
    $mdDialog,
    loader,
    toast,
    accommodationService,
    rooms,
    room,
    person,
    recreations
  ) {
    this.model = {};
    this.rooms = rooms;
    this.recreations = recreations.map(recreation => {
      recreation.selected = person.recreations.some(r => r.id === recreation.id);
      return recreation;
    });

    if (room) {
      this.model.room = rooms.filter(r => r.id === room.id)[0];
      this.model.checkin = person.checkin;
      this.model.checkout = person.checkout;
    }

    this.submit = () => {
      const recreations = this.recreations.filter(r => r.selected).map(r => r.id);
      loader.add(accommodationService.save(this.model.room.id, person.participant_id, person.dependent_id, this.model.checkin, this.model.checkout, recreations)).then(result => {
        $mdDialog.hide(result);
      }).catch(err => {
        switch ((err.data || {}).message) {
          case 'less-spots':
            toast.show('Número de pessoas no quarto é maior que o permitido pelo tipo', 'error');
            break;
          default:
            toast.httpHandler(err);
        }
      });
    };

    this.cancel = $mdDialog.cancel;
  }

})(angular || {});
