(angular => {

  angular.module("Participant", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/participant", {
        controller: "Participant.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/participant/list/list.html`,
        role: ['admin', 'user', 'company_manager', 'organizer', 'coordinator']
      });
  }

})(angular);
