(angular => {
  "use strict";

  angular.module("Company").factory("CompanyService", [
    "$http",
    "API",
    "$mdDialog",
    CompanyService
  ]);

  function CompanyService($http, API, $mdDialog) {

    const list = (params) => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/company`,
        params: params || this.params,
      }).then(res => res.data);
    };


    const save = company => {
      return $http.post(`${API}/company/`, company).then(res => res.data);
    };

    const get = (companyId) => {
      return $http.get(`${API}/company/${companyId}`).then(res => res.data);
    };

    const remove = (companyId) => {
      return $http.delete(`${API}/company/${companyId}`).then(res => res.data);
    };

    const form = ($event, company) => {
      return $mdDialog.show({
        templateUrl: `admin/company/form/form.html`,
        controller: "Company.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        event: $event,
        locals: {
          company: angular.copy(company)
        }
      });
    };

    const listTypesSponsorShip = () => {
      return angular.copy(['business club', 'event punctual', 'prospect']);
    };

    const listTypes = () => {
      return angular.copy(['not informed', 'commercial partner', 'associated', 'midia', 'invited', 'entity', 'government', 'organizer', 'stand_builder', 'ticket sale']);
    };

    const loadCompanyTables = () => {
      return $http.get(`${API}/company/company-tables`).then(res => res.data);
    };

    return {
      list,
      get,
      save,
      remove,
      form,
      listTypes,
      listTypesSponsorShip,
      loadCompanyTables
    };
  }
})(angular);
