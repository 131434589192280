(angular => {

  angular.module("ActivityPremium", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/atividade-premium", {
        controller: "ActivityPremium.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/activityPremium/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'company_manager']
      })
      .when("/atividade-premium/:companyId", {
        controller: "ActivityPremium.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/activityPremium/form/form.html`
      });
  }

})(angular);
