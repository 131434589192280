(angular => {
  "use strict";

  angular.module("EventCompany").controller("EventCompany.ListCtrl", [
    "$scope",
    "$timeout",
    "$q",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyService",
    "AuthService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, $q, Loader, Toast, EventService, EventCompanyService, authService) {
    let queryTimeout = null;
    this.promiseCompany = null;
    this.promiseExhibitor = null;
    this.orderBy = 'company.name';
    this.user = authService.getTokenData();

    EventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      const query = angular.copy(this.query);

      this.promiseCompany = EventCompanyService.list(query)
        .then(companies => this.eventCompanies = companies);

      this.promiseExhibitor = EventCompanyService.listExhibitors()
        .then(exhibitors => this.eventExhibitors = exhibitors);

      return $q.all([
          this.promiseCompany,
          this.promiseExhibitor
        ])
        .catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, eventCompany) => {
      if (this.currentTab === 0) {
        return EventCompanyService.form($event, eventCompany)
          .then(() => this.load());
      }
      return EventCompanyService.formExhibitor($event, eventCompany)
        .then(() => this.load());
    };

    this.remove = (eventCompany) => {
      Loader.add(EventCompanyService.remove(eventCompany.id)).then(() => {
        Toast.show("Empresa excluída com sucesso", "success");
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    this.removeExhibitor = (eventCompany) => {
      Loader.add(EventCompanyService.removeExhibitor(eventCompany.id)).then(() => {
        Toast.show("Empresa excluída com sucesso", "success");
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.load(), 500);
    }, true);

    $scope.$watch('headerTabs.current', value => {
      this.currentTab = value;
    });

  }
})(angular || {});
