(angular => {

  angular.module("EmailMarketing")

    .filter("emailStatus", [CompanyTypeFilter]);

  function CompanyTypeFilter() {

    const types = {
      "waiting": "Agendado",
      "processing": "Processando",
      "sent": "Enviado",
      "error": "Erro"
    };

    function filter(type) {
      if (!types[type]) {
        return type;
      }
      return types[type];
    }

    return filter;
  }

})(angular);
