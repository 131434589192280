(angular => {

  angular.module("Contract")

    .filter("classificationStatus", [ClassificationStatusFilter]);

  function ClassificationStatusFilter() {

    const classifications = {
      "pre-event": "Pré evento",
      "post-event": "Pós evento",
      "event": "Evento",
      "registrations": "Inscrições",
      "enrolled": "Inscritos",
      "sponsored networks": "Rede Patrocinadas",
      "forum-de-alta-gestao": "Fórum de Alta Gestão",
      "magazine": "Revista",
      "post-event-material": "Material Pós evento",
      "pharmaceutical-assistance-portal": "Portal Assistência Farmacêutica",
      "portal-abrafarma-business-club": "Portal Abrafarma Business Club",
      "today-in-the-press": "Hoje na Imprensa",
      "sales-thermometer": "Termômetro de Vendas",
      "reports": "Relatórios",
      "fia-usp": "FIA-USP",
      "specials-reports": "Relatórios especiais",
      "participation-pack": "Pacote de Participação",
      "others": "Outros"
    };

    function filter(classification) {
      return classifications[classification] || "-";
    }

    return filter;
  }

})(angular);
