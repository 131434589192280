(angular => {
  "use strict";

  angular.module("App").factory("BankService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    BankService
  ]);

  function BankService($q, $http, $mdDialog, API) {
    const list = () => {
      return $http.get(`${API}/banks/`).then(res => res.data);
    };

    return {
      list
    };
  }
})(angular);
