(angular => {

  angular.module("EventOfficialProvider", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/servicos-oficiais", {
        controller: "EventOfficialProvider.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventOfficialProvider/list/list.html`,
        role: ['admin', 'user', 'company_manager']
      })
      .when("/servicos-oficiais/:eventOfficialProviderId", {
        controller: "EventOfficialProvider.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventOfficialProvider/form/form.html`
      });
  }

})(angular);
