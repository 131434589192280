(angular => {
  "use strict";

  angular.module("EventCost").controller("EventCost.IndexCtrl", [
    "$mdDialog",
    "Loader",
    "Toast",
    "EventCostService",
    IndexCtrl
  ]);

  function IndexCtrl($mdDialog, Loader, Toast, eventCostService) {
    this.event;
    this.query = { orderBy: "payment_date" };

    this.loadCosts = () => {
      this.promise = eventCostService.list().then(costs => {
        eventCostService.totalCosts(costs).then(total => {
          this.total = total;
          this.eventCost = costs;
        });

      }).catch(err => Toast.httpHandler(err));
    };
    this.loadCosts();

    this.form = ($event, model) => {
      eventCostService.form($event, model).then(() => {
        this.loadCosts();
      });
    };

    this.removeCost = (cost) => {
      Loader.add(eventCostService.remove(cost.id)).then(() => {
        Toast.show("Custo excluído com sucesso", "success");
        this.loadCosts();
      }).catch(err => Toast.httpHandler(err));
    };


    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
