(angular => {
  "use strict";

  angular.module("App")
    .controller("App.LoginCtrl", ["jwtHelper", "$location", "Toast", "Loader", "LoginService", "AuthService", LoginCtrl]);

  function LoginCtrl(jwtHelper, $location, Toast, Loader, LoginService, AuthService) {
    this.TABS = {
      login: 0,
      recover: 1,
      recoverMail: 2,
      newPassword: 3,
      tokenExpired: 4
    };

    const token = $location.search().t;

    this.user = {};
    this.newPassword = { token };
    this.currentTab = this.TABS.login;

    if (token) {
      this.tokenData = jwtHelper.decodeToken(token);
      this.currentTab = !this.tokenData || jwtHelper.isTokenExpired(token) ?
        this.TABS.tokenExpired :
        this.TABS.newPassword;
    }

    if (AuthService.isLoggedIn()) {
      $location.search({});
      $location.path("/event");
      return;
    }

    this.recover = () => {
      Loader.add(LoginService.recover(this.user.email)).then(() => {
        this.currentTab = this.TABS.recoverMail;
      }).catch((err) => {
        Toast.show(err.data.message || "E-mail não encontrado", "error");
      });
    };

    this.login = () => {
      Loader.add(LoginService.doLogin(this.user)).then(() => {
        $location.path("/event");
      }).catch((err) => {
        Toast.show(err.data.message || "Falha ao efetuar login", "error");
      });
    };

    this.resetPassword = () => {
      Loader.add(LoginService.doReset(this.newPassword)).then(() => {
        $location.search({});
        $location.path("/event");
      }).catch((err) => {
        Toast.show(err.data.message || "Falha ao resetar a sua senha", "error");
      });
    };

  }

})(angular);
