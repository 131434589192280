(angular => {
  "use strict";

  angular.module("Accommodation").controller("Accommodation.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "Loader",
    "Toast",
    "EventService",
    "EventHotelService",
    "AccommodationService",
    "ParticipantService",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, Loader, Toast, eventService, eventHotelService, accommodationService, participantService) {
    let lastSelectedRoom;

    this.eventCompany = null;
    this.persons = [];

    const typeSpots = eventHotelService.getTypes().reduce((acc, type) => {
      acc[type.type] = type.spots;
      return acc;
    }, {});

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      Loader.add(accommodationService.get($routeParams.companyId)).then(eventCompany => {
        this.eventCompany = eventCompany;
      }).catch(err => Toast.httpHandler(err));
    };
    this.load();

    this.queryParticipant = term => {
      return participantService.query(term, this.eventCompany.company_id);
    };

    this.changeParticipant = participant => {
      if (!participant) return;

      this.persons = [{
        participant_id: participant.id,
        participant: participant,
        name: participant.name,
        document: participant.cpf,
        age: participant.birth_date ? moment().diff(participant.birth_date, 'year') : null,
        added: this.eventCompany.rooms.some(r => r.persons.some(p => p.participant_id === participant.id && !p.dependent_id)),
        recreations: [],
        checkin: participant.checkin,
        checkout: participant.checkout
      }];

      if (!this.event.allow_relatives) return;

      participant.dependents.forEach(dependent => {
        this.persons.push({
          participant_id: participant.id,
          participant: participant,
          dependent_id: dependent.id,
          name: dependent.name,
          document: dependent.document,
          age: dependent.birth_date ? moment().diff(dependent.birth_date, 'year') : null,
          added: this.eventCompany.rooms.some(r => r.persons.some(p => p.dependent_id === dependent.id)),
          recreations: [],
          checkin: dependent.checkin,
          checkout: dependent.checkout
        });
      });
    };

    this.formRoom = ($event, room) => {
      return accommodationService.formRoom($event, this.eventCompany.id, room).then(() => {
        this.load();
      });
    };

    this.removeRoom = room => {
      Loader.add(accommodationService.removeRoom(room.id)).then(() => {
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    this.formPerson = ($event, person, currentRoom) => {
      currentRoom = currentRoom || lastSelectedRoom;
      const recreations = (this.eventCompany.hotel || {}).recreations || [];

      return accommodationService.formPerson($event, this.eventCompany.rooms, person, currentRoom, recreations).then(() => {
        person.added = true;
        this.load();
      });
    };

    this.removePerson = (room, person) => {
      Loader.add(accommodationService.remove(person.participant_id, person.dependent_id)).then(() => {
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    this.isRoomFull = room => {
      const spots = typeSpots[room.type];
      const personCount = room.persons.filter(p => !p.age || p.age >= this.event.relatives_min_age).length;

      return spots <= personCount;
    };
  }
})(angular || {});
