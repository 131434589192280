(angular => {
  "use strict";

  angular.module("Participant")
    .directive("exportCreate", ["$mdDialog", ExportCreate]);

  function ExportCreate($mdDialog) {
    return {
      restrict: "A",
      scope: {
        selected: "=exportCreate",
        participants: "=participants",
        type: "=type"
      },
      link: (scope, element) => {
        element.on("click", () => {
          const participants = (scope.selected.length ? scope.selected : scope.participants || []);
          const type = scope.type;
          $mdDialog.show({
            templateUrl: `admin/participant/export/export.html`,
            controller: "Participant.ExportModalCtrl",
            controllerAs: "$ctrl",
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
              participants: angular.copy(participants),
              type: angular.copy(type)
            },
          });
        });
      }
    };
  }

})(angular);
