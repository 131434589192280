(angular => {
  'use strict';

  angular.module('Event').controller('Event.CreateCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    '$mdDialog',
    'lodash',
    'Loader',
    'Toast',
    'EventService',
    'CompanyService',
    'event',
    CreateCtrl
  ]);

  function CreateCtrl($scope, $filter, $timeout, $mdDialog, lodash, Loader, Toast, EventService, CompanyService, event) {
    this.query = { orderBy: 'name' };
    this.isEditing = !!event;
    this.event = event || { status: 'open', activity_confirmation: false };
    this.event.organizersId = [];
    this.event.assets = this.event.assets || [];
    this.readonly = false;
    this.stand_builders = [];
    this.filenameTerm = "";

    CompanyService.list().then(builders => {
      this.stand_builders = builders.filter(s => s.type === 'stand_builder');
      if (this.event.stand_builder) {
        this.loadCompanies();
      }
    });

    this.loadCompanies = () => {
      this.stand_builders.forEach(s => {
        if (s.id === this.event.stand_builder.company_id) {
          this.event.stand_builder.email = s.email;
          this.event.stand_builder.contact = s.contact;
        }
      });
    };

    this.cancel = $mdDialog.cancel;
    this.filesTypes = [
      { type: 'logo', name: 'Logo', size: '300 x 300' },
      { type: 'banner', name: 'Banner', size: '1366 x 250' },
      { type: 'footer', name: 'Rodapé', size: '2480 x 591' },
      { type: 'invitation', name: 'Convite', size: '600 x 900' },
      { type: 'confirmation', name: 'Confirmação', size: '600 x 900' },
      { type: 'certificate', name: 'Certificado', size: '3500 x 2480' },
    ];

    const generateCredentials = (credentials = []) => {

      const colors = {
        'not informed': '#001dff',
        'commercial partner': '#00ff2e',
        'associated': '#ff0000',
        'invited': '#ff8800',
        'government': '#ff8800',
        'midia': '#ff8800',
        'entity': '#ff8800',
        'organizer': '#001dff',
        'provider': '#ff8800',
        'speaker': '#ff8800',
        'stand_builder': '#ff8800'
      };

      const types = CompanyService
        .listTypes()
        .concat('provider', 'speaker')
        .map(t => ({ type: t, color: colors[t] }));

      return lodash.uniqBy(credentials.concat(types), ({ type }) => type);
    };

    this.event.credentialColors = generateCredentials(this.event.credentialColors);

    Loader.add(CompanyService.list(this.query)).then(companies => {
      this.companies = companies.filter(c => c.type === 'organizer');
    }).catch(err => Toast.httpHandler(err));

    if (this.event.id) {
      Loader.add(EventService.listOrganizers(this.event.id)).then(list => {
        this.event.organizersId = list.map(l => l.company_id);
      }).catch(err => Toast.httpHandler(err));
    }

    this.addFilename = () => {
      if (!this.standBuilderFile.file || !this.standBuilderFile.fileExtensions) {
        Toast.show('É necessário inserir o nome do arquivo e a extensão', 'error');
        return;
      }

      this.event.stand_builder.stand_builder_files = this.event.stand_builder.stand_builder_files || [];
      this.event.stand_builder.stand_builder_files.push({
        file: this.standBuilderFile.file,
        extensions: _.uniq(
          this.standBuilderFile.fileExtensions
          .split(',')
          .map(e => e.replace(/\./g, '').toLowerCase().trim())
          .filter(e => e)
        )
      });

      this.standBuilderFile = {};
    };

    this.hasFileTerm = (filename) => {
      return this.event.assets.some(a => a.filename === filename);
    };

    this.uploadTerm = (result) => {
      const type = 'event_term';
      this.removeUploadTerm(type);
      this.filenameTerm = result.filename;
      this.event.assets.push({ filename: result.filename, base64: result.base64, type: type});
    };

    this.removeUploadTerm = (type) => {
      this.filenameTerm = "";
      this.event.assets = this.event.assets.filter(f => f.type !== type);
    };

    this.getTerm = (filename) => {
      const file = this.event.assets.filter(a => a.filename === filename)[0];

      if (file) this.filenameTerm = file.filename;

    };

    this.removeFilename = (filename) => {
      this.event.stand_builder.stand_builder_files = this.event.stand_builder.stand_builder_files.filter(file => file.file !== filename);
    };

    this.hasFile = (type) => {
      return this.event.assets.some(a => a.type === type);
    };

    this.upload = (result, type) => {
      this.removeUpload(type);
      this.event.assets.push({ filename: result.filename, base64: result.base64, type });
    };

    this.removeUpload = (type) => {
      this.event.assets = this.event.assets.filter(f => f.type !== type);
    };

    this.hasFile = (type) => {
      return this.event.assets.some(a => a.type === type);
    };

    this.getFileUrl = (type) => {
      const file = this.event.assets.filter(a => a.type === type)[0];

      if (!file) return '/img/sem-imagem.jpg';
      return file.base64 || `/content/${file.path}`;
    };

    this.save = () => {

      if (!this.event.has_stand_builder) delete this.event.stand_builder;
      if (!this.event.activity_confirmation) this.event.minimum_activities = null;
      if (!this.event.has_event_table_reservation) this.event.event_table_reservation_description = null;

      return Loader.add(EventService.save(this.event)).then(() => {
        Toast.show('Evento salvo com sucesso', 'success');
        $mdDialog.hide(this.event);
      }).catch(response => {
        const messages = {
          'less-spots': `Quantidade de vagas inválida: há ${(response.data.data || {}).count} participantes confirmados no evento`,
          'activity-date-already-taken': `Data inválida: há uma atividade cadastrada no dia ${$filter('date')((response.data.data || {}).date, 'dd/MM/yyyy')}`,
          'company-spot-exceeded': `Existe uma empresa que ultrapassou o limite já definido: ${((response.data.data || {}).company || {}).name}`
        };

        const message = messages[response.data.message];
        if (message) {
          return Toast.show(message, 'error');
        }

        Toast.httpHandler(response);
      });
    };

    $scope.$watchGroup(['$ctrl.event.start_date', '$ctrl.event.end_date', '$ctrl.event.limit_date'], () => {
      const start = moment(this.event.start_date);
      const end = moment(this.event.end_date);
      const limit = moment(this.event.limit_date);

      this.formEvent.start_date.$setValidity('start', start.isSameOrBefore(end, 'day'));
      this.formEvent.limit_date.$setValidity('limit', limit.isSameOrBefore(start, 'day'));
    });

    $scope.data = { swt: false };
    $scope.label = 'Não';
    $scope.onChange = cbState => {
      $scope.label = cbState ? 'Sim' : 'Não';
    };

    $scope.$watch('$ctrl.event.activity_confirmation', () => {
      if (this.event.activity_confirmation) this.event.activity_admin_confirmation = false;
    });

    $scope.$watch('$ctrl.event.activity_admin_confirmation', () => {
      if (this.event.activity_admin_confirmation) this.event.activity_confirmation = false;
    });

    $scope.$watch('$ctrl.event.stand_builder.company_id', () => {
      if (this.event.stand_builder) {
        this.event.stand_builder.email = null;
        this.event.stand_builder.contact = null;
        this.loadCompanies();
      }
    });

  }
})(angular || {});
