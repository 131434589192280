(angular => {
  "use strict";

  angular.module("Contract").controller("Contract.FormModelCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "ContractService",
    "model",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, loader, contractService, model) {
    this.model = model || {};

    this.save = () => {
      return loader.add(contractService.save(this.model)).then(() => {
        $mdDialog.hide(this.model);
      });
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
