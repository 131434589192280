(angular => {
  "use strict";

  angular.module("FindRetailerNotBussinesClub")
    .controller("FindRetailerNotBussinesClub.IndexCtrl", [
      "$scope",
      "$timeout",
      "$routeParams",
      "$mdDialog",
      "$q",
      "XLSReader",
      "Loader",
      "Toast",
      "AuthService",
      "FindRetailerNotBussinesClubService",
      IndexCtrl
    ]);

  function IndexCtrl($scope, $timeout, $routeParams, $mdDialog, $q, XLSReader, Loader, Toast, authService, FindRetailerNotBussinesClubService) {
    this.user = authService.getTokenData();
    this.promise = null;
    this.query = { orderBy: "name" };
    this.promise = null;
    this.participants = [];
    this.pagination = { page: 1, limit: 10 };
    this.statusSelected = '';
    let queryTimeout;

    this.loadParticipants = () => {
     return this.promise = $q.all([
      FindRetailerNotBussinesClubService.listForNotBussinessClub(this.user.eventId, this.user.companyIds[0], this.query.orderBy)
     ]).then((participants) => {
        this.participants = this.filterQuery(participants[0]);
     }).catch(err => Toast.httpHandler(err));
    };

    this.filterQuery = (participants) => {
      const query = angular.copy(this.search);
      if(query){
        return participants.filter(p => {
            return p.name.toUpperCase().indexOf(query.toUpperCase()) > -1
            //|| p.guest_company !== undefined && p.guest_company !== null && p.guest_company !== '' ? p.guest_company.toUpperCase().indexOf(query.toUpperCase()) > -1 : false
            || p.position.toUpperCase().indexOf(query.toUpperCase()) > -1;
        });
      }else {
        return participants;
      }
    };

    this.openModal = (participant) => {
      $mdDialog.show({
        templateUrl: `admin/findRetailerNotBussinesClub/listInvitedCompany/inviteCompany.html`,
        controller: "FindRetailerNotBussinesClub.InviteCompanyCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          participant: participant
        }
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        this.pagination.page = 1;
        this.loadParticipants();
      }, 500);
    }, true);

    $scope.$watch(() => this.search, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        this.pagination.page = 1;
        this.loadParticipants();
      });

  });

}
})(angular || {});
