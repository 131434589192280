(angular => {
  "use strict";

  angular.module("ActivityPremium").component("activitiesPremium", {
    templateUrl: 'admin/activityPremium/list/general/general.html',
    controller: [
      "Loader",
      "Toast",
      "EventService",
      "EventCompanyService",
      "ActivityPremiumService",
      ListCtrl
    ]
  });

  function ListCtrl(Loader, Toast, eventService, eventCompanyService, activityPremiumService) {

    eventService.current().then(event => {
      this.event = event;
    });

    Loader.add(eventCompanyService.list()).then(eventCompanies => {
      activityPremiumService.listWithParticipants().then(activities => {
        activities = activities.filter(a => a.attendance_list === true);
        this.eventCompanies = eventCompanies.filter(c => c.spots_activity_premium > 0);
        this.eventCompanies.map(e => e.activities = activities);
      });
    });

  }
})(angular || {});
