(angular => {
  "use strict";

  angular.module("Participant").controller("Participant.WizardCtrl", [
    "$mdDialog",
    "participant",
    "participantType",
    "currentStep",
    "company",
    "EventService",
    "AuthService",
    WizardCtrl
  ]);

  function WizardCtrl($mdDialog, participant, participantType, currentStep, company, EventService, AuthService) {
    EventService.current().then(event => {
      this.step = 0;
      this.participant = participant;
      this.event = event;
      this.currentStep = currentStep;
      this.company = company;
      this.participantType = participantType;
      this.type = company ? 'Executivo' : 'Participante';
      const user = AuthService.getTokenData();

      switch (currentStep) {
        case 'dependents':
          this.type = 'Acompanhantes';
          this.step = 1;
          break;
        case 'activity':
          this.type = 'Atividades';
          this.step = 2;
          break;
        default:
      }

      this.completedStep = (step, state) => {
        if (state.type === 'staff') {
          return $mdDialog.hide(this.participant);
        } else {
          if (currentStep) {
            $mdDialog.hide(this.participant);
          }

          switch (step) {
            case 'form':
              this.participant = state;

              if (this.event.allow_relatives) {
                this.type = 'Acompanhantes';
                return this.step = 1;
              }

              if (this.event.activity_confirmation || this.event.activity_admin_confirmation) {
                this.type = 'Atividades';
                return this.step = 2;
              }

              $mdDialog.hide(this.participant);
              break;
            case 'dependents':
              if (this.event.activity_confirmation || this.event.activity_admin_confirmation) {
                this.type = 'Atividades';
                if (user.role !== 'admin') { // eslint-disable-line
                  $mdDialog.hide(this.participant);
                }
                return this.step = 2;
              }

              $mdDialog.hide(this.participant);
              break;
            case 'activity':
              $mdDialog.hide(this.participant);
          }
        }
      };

      this.backStep = (step) => {
        switch (step) {
          case 'dependents':
            this.step = 0;
            break;
          case 'activity':
            this.step = 1;
            break;
        }
        if (currentStep) $mdDialog.hide(this.participant);
      };


      this.cancel = () => {
        $mdDialog.cancel();
      };
    });


  }
})(angular || {});
