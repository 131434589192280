((angular) => {

  angular.module("Common")
    .directive("emailValidator", ["lodash", Validator]);

  function Validator(_) {

    return {
      require: "ngModel",
      scope: {
        enabled: "=emailValidator"
      },
      link: function(scope, elem, attrs, ctrl) {
        const email = ["gmail", "hotmail", "bol.com", "yahoo", "terra.com", "outlook", "uol", "globo.com"];
        ctrl.$validators["email-validator"] = (modelValue, viewValue) => {
          modelValue = modelValue.toLowerCase();
          viewValue = viewValue.toLowerCase();
          if (!scope.enabled) return true;
          return _.isEmpty(modelValue) || (!(new RegExp("\\b" + email.join("\\b|\\b") + "\\b")).test(viewValue));
        };
      }
    };

  }

})(angular);
