(angular => {

  angular.module("Participant")

    .filter("participantRelatives", [ParticipantRelativeFilter]);

  function ParticipantRelativeFilter() {

    const types = {
      "spouse": "Cônjuge",
      "children": "Filho(a)",
      "relative": "Parente",
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
