(angular => {

  angular.module("Common")

    .filter("typeUpload", [EventTypeUpload]);

  function EventTypeUpload() {

    const types = {
      'associateManual': 'Manual Associado',
      'exhibitorManual': 'Manual Expositor'
    };

    function filter(type) {
      if (!types[type]) {
        return type;
      }
      return types[type];
    }

    return filter;
  }

})(angular);
