(angular => {

  angular.module("EventHotel", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/hotel", {
        controller: "EventHotel.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventHotel/index/index.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'organizer', 'coordinator']
      })
      .when("/hotel/:hotelId/", {
        controller: "EventHotel.FormHotelGuestRoomCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventHotel/form-hotel-guest-room/form-hotel-guest-room.html`,
        allowWithoutEvent: true,
        role: ['admin']
      });
  }

})(angular);
