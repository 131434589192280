(angular => {
  "use strict";

  angular.module("EffectiveRevenues").controller("EffectiveRevenues.RevenuesListCtrl", [
    "$scope",
    "$timeout",
    "$routeParams",
    "Loader",
    "Toast",
    "$location",
    "EffectiveRevenuesService",
    RevenuesListCtrl
  ]);

  function RevenuesListCtrl($scope, $timeout, $routeParams, Loader, Toast, $location, EffectiveRevenuesService) {
    this.promise = null;
    let queryTimeout = null;
    this.effectiveRevenues = null;
    this.revenuesCostsData = null;
    this.revenueCostTotal = null;

    EffectiveRevenuesService.getAccountingYear($routeParams.accountingYearId)
      .then(accountingYear => this.accountingYear = accountingYear)
      .then(({ year }) => EffectiveRevenuesService.listRevenuesCosts(year))
      .then(revenuesCosts => this.revenuesCosts = revenuesCosts)
      .then(revenuesCosts => EffectiveRevenuesService.revenuesCostsTotal(revenuesCosts))
      .then(revenueCostTotal => this.revenueCostTotal = revenueCostTotal)
      .catch(err => Toast.httpHandler(err));


    this.form = ($event, accountingYear) => {
      EffectiveRevenuesService.form($event, accountingYear).then(() => {
        this.load();
      });
    };

    this.formAccountingYear = ($event, accountingYear) => {
      EffectiveRevenuesService.formAccountingYear($event, accountingYear).then(() => {
        this.load();
      });
    };

    this.load = () => {
      Loader.add(EffectiveRevenuesService.list($routeParams.accountingYearId).then(effectiveRevenues => {
        this.effectiveRevenues = effectiveRevenues;
        EffectiveRevenuesService.totalCosts(effectiveRevenues).then(total => {
          this.total = total;
        });
      }).catch(err => Toast.httpHandler(err)));
    };

    this.remove = (effectiveRevenue) => {
      EffectiveRevenuesService.remove(effectiveRevenue.id).then(() => {
        this.load();
      });
    };

    this.removeAccountingYear = (accountingYear) => {
      EffectiveRevenuesService.removeAccountingYear(accountingYear.id).then(() => {
        $location.path("/revenues");
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.load(), 500);
    }, true);

    const POSITIVE = 1;
    const NEUTRAL = 0;
    const NEGATIVE = -1;

    const COLOR = {
      [POSITIVE]: 'green',
      [NEUTRAL]: 'black',
      [NEGATIVE]: 'red'
    };

    this.getColor = revenueCostTotal => COLOR[Math.sign(revenueCostTotal)];

  }
})(angular || {});
