(angular => {

  angular.module("EventTax", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/taxas", {
        controller: "EventTax.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventTax/index/index.html`,
        role: ['admin', 'user', 'organizer', 'company_manager', 'stand_builder', 'coordinator']
      });
  }
})(angular);
