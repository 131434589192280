(angular => {

  angular.module("Common")

    .filter("participantStatus", [ParticipantStatusFilter]);

  function ParticipantStatusFilter() {

    const types = {
      "not invited": "Convite não Enviado",
      "invited": "Convite Enviado",
      "accepted": "Convite Aceito",
      "unauthorized": "Não Autorizado",
      "present": "Presente",
      "present at time": "Presente na hora",
      "not present": "Ausente",
      "confirmed": "Confirmado",
      "subscription at time": "Inscrição na Hora"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
