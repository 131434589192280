(angular => {

  angular.module('Event')

    .filter('activityTypes', ['EventService', activityTypeFilter]);

  function activityTypeFilter(eventService) {

    return type => {
      if (type === 'event') {
        return 'Evento';
      }
      return eventService.getStatus()[type] || type;
    };
  }

})(angular);
