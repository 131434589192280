(angular => {

  angular.module("User").filter("userRole", [
    UserRoleFilter
  ]);

  function UserRoleFilter() {
    const roles = {
      admin: "Administrador",
      user: "Usuário",
      company_manager: "Gestor",
      activity_speaker: "Palestrante",
      sender: "Remetente",
      organizer: "Organizador"
    };

    return role => roles[role] || "-";
  }

})(angular);
