(angular => {
  "use strict";

  angular.module("Common").factory("Dialog", ["$rootScope", "$mdDialog", service]);

  function service($rootScope, $mdDialog) {
    const defaults = {
      templateUrl: null,
      controller: ['$mdDialog', 'data', function($mdDialog, data = {}) {
        this.cancel = $mdDialog.cancel;
        angular.extend(this, data);
      }],
      controllerAs: "$ctrl",
      clickOutsideToClose: true,
      escapeToClose: true,
      fullscreen: true,
      locals: { data: null },
      targetEvent: null,
    };

    return (options) => {
      if (!options.controller && options.locals) {
        options.locals = { data: options.locals };
      }

      return $mdDialog.show(angular.extend({}, defaults, options));
    };

  }

})(angular);
