(angular => {
  'use strict';

  angular.module('Event').controller('Event.ImportCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    '$mdDialog',
    'lodash',
    'Loader',
    'Toast',
    'EventService',
    'event',
    'FormRedirectService',
    'API',
    'eventImport',
    ImportCtrl
  ]);

  function ImportCtrl($scope, $filter, $timeout, $mdDialog, lodash, Loader, Toast, EventService, event, FormRedirectService, API, eventImport) {
    this.eventDays = [];
    this.eventDays = EventService.getEventDays(event);
    this.dateEventFind = this.eventDays[0];
    this.event = event;
    this.eventImport = eventImport;
    this.error = false;
    this.query = { page: 1, limit: 10 };

    this.importTransmissionPlatform = () => {
      return Loader.add(EventService.importListTransmission(this.dateEventFind, this.event.id, this.eventImport)).then((result) => {
        if (!result.errors.length) {
          Toast.show("Participantes importados com sucesso", "success");
          $mdDialog.hide();
          return;
        }
        this.error = true;
        this.query.page = 1;
        this.successCount = this.eventImport.length - result.errors.length;
        this.eventImport = result.errors.map(data => {
          data.import.error = data.error;
          return data.import;
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
