(angular => {
  "use strict";

  angular.module("App").component("selectEventComponent", {
    template: `
      <div ng-click="$ctrl.open($event)" class="select-event-component">
        <p class="md-caption"> Dados do Evento</p>
        <p class="event-name">{{$ctrl.currentEvent.title || 'Selecione...'}}</p>
        <md-icon md-svg-icon="chevron-right"></md-icon>
      </div>
    `,
    controller: [
      "$scope",
      "$route",
      "$window",
      "Loader",
      "AuthService",
      "EventService",
      "LoginService",
      SelectEventCtrl
    ]
  });

  function SelectEventCtrl($scope, $route, $window, loader, authService, eventService, loginService) {
    this.currentEvent = {};
    this.user = authService.getTokenData();
    this.$onInit = () => {
      this.loadEvent();
    };

    this.open = ($event) => {
      $event.stopPropagation();
      eventService.openSelect($event).then(event => {
        if (!this.currentEvent || this.currentEvent.id === event.id) return;
        loader.add(loginService.changeEvent(event.id)).then(() => {
          $scope.$emit('event-change', event);
          $route.reload();
        });
      });
    };

    this.loadEvent = () => {

      if (!this.user.eventId) {
        this.currentEvent = {};
        return;
      }

      loader.add(eventService.current(true)).then(event => {
        $scope.$emit('event-change', event);
        this.currentEvent = event;
      });
    };

    $scope.$on("login", () => {
      this.loadEvent();
    });

    $scope.$on("event-updated", (info, event) => {
      if (event.id !== this.currentEvent.id) return;
      this.loadEvent();
    });
  }

})(angular);
