(angular => {
  "use strict";

  angular.module("Config", ["Common"])
    .constant("API", "/api/admin")
    .config(["$routeProvider", "$locationProvider", configRouter])
    .config(["$httpProvider", configInterceptor])
    .run(["$rootScope", "$location", "$timeout", "AuthService", "LoginService", run]);

  function configRouter($routeProvider, $locationProvider) {
    $locationProvider.hashPrefix('');
    $routeProvider.otherwise({ redirectTo: "/" });
  }

  function configInterceptor($httpProvider) {
    $httpProvider.defaults.timeout = 90000;
    $httpProvider.interceptors.push("authInterceptor");
  }

  function run($rootScope, $location, $timeout, AuthService, LoginService) {
    $rootScope.$on("$routeChangeStart", ($event, next) => {
      if (!next.$$route || next.$$route.allowAnonymous || next.$$route.redirectTo) {
        return true;
      }

      if (!AuthService.isLoggedIn()) {
        next.$$route.resolve = next.$$route.resolve || {};
        next.$$route.resolve.login = () => LoginService.login($event);
        return;
      }

      if (next.$$route.role && !AuthService.hasRole(next.$$route.role)) {
        $event.preventDefault();

        if (AuthService.getTokenData().role === 'company_manager') {
          $location.path("/company");
          return;
        }

        if (AuthService.getTokenData().role === 'organizer') {
          $location.path("/event");
          return;
        }

        if (AuthService.getTokenData().role === 'stand_builder') {
          $location.path("/event-company");
          return;
        }

        $location.path("/event");
        return;
      }

      if (next.$$route.companyType && !AuthService.hasCompanyType(next.$$route.companyType)) {
        $location.path('/company');
        return;
      }

      if (!AuthService.hasEventSelected() && !next.$$route.allowWithoutEvent) {
        $event.preventDefault();
        $location.path("/event");
        return;
      }
    });

    $rootScope.$on("$routeChangeError", LoginService.logout);
  }



})(angular);
