(angular => {
  "use strict";

  angular.module("EffectiveRevenues").controller("EffectiveRevenues.YearFormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "accountingYear",
    "EffectiveRevenuesService",
    YearFormCtrl
  ]);

  function YearFormCtrl($q, $routeParams, $filter, $scope, $timeout, $mdDialog, Loader, Toast, accountingYear, EffectiveRevenuesService) {
    let queryTimeout;
    this.model = accountingYear;

    this.save = () => {
      EffectiveRevenuesService.saveAccountingYear(this.model).then(() => {
        $mdDialog.hide(this.model);
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
    }, true);

    this.cancel = $mdDialog.hide;

  }
})(angular || {});
