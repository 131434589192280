(angular => {
  "use strict";

  angular.module("PushNotification")
    .controller("PushNotification.HistoryCtrl", [
      "$mdDialog",
      "Loader",
      "Toast",
      "PushNotificationService",
      "notification",
      HistoryCtrl
    ]);

  function HistoryCtrl($mdDialog, Loader, Toast, PushNotificationService, notification) {

    this.notification = notification;
    this.history = [];
    this.promise = null;

    this.pagination = { page: 1, limit: 10 };


    const load = (notification, query) => {
      this.promise = PushNotificationService.listHistory(notification, query);

      this.promise
        .then(history => this.history = history)
        .catch(Toast.httpHandler);
    };
    load(this.notification);

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
