(angular => {
  "use strict";

  angular.module("EventTax").component("eventTaxListComponent", {
    templateUrl: 'admin/eventTax/index/list/list.html',
    controller: [
      "$mdDialog",
      "Loader",
      "Toast",
      "EventService",
      "EventTaxService",
      "EventService",
      ListCtrl
    ],
    bindings: {
      loadCallback: '='
    }
  });

  function ListCtrl($mdDialog, loader, toast, eventService, eventTaxService) {
    eventService.current().then(event => this.event = event);

    this.loadCallback = this.loadTaxes = () => {
      this.promise = eventTaxService.list().then(taxs => {
        this.eventTaxs = taxs;
      }).catch(err => toast.httpHandler(err));
    };
    this.loadTaxes();

    this.form = ($event, model) => {
      eventTaxService.form($event, model).then(() => {
        this.loadTaxes();
      });
    };

    this.removeTax = (tax) => {
      loader.add(eventTaxService.remove(tax.id)).then(() => {
        toast.show("Taxa excluída com sucesso", "success");
        this.loadTaxes();
      }).catch(err => toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
