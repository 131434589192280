(angular => {
  "use strict";

  angular.module("EventOfficialProvider").factory("EventOfficialProviderService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    EventOfficialProviderService
  ]);

  function EventOfficialProviderService($q, $http, $mdDialog, API) {
    const list = () => {
      return $http.get(`${API}/event/official-provider/`).then(res => res.data);
    };

    const save = eventCompanyEmployee => {
      return $http.post(`${API}/event/official-provider/`, eventCompanyEmployee).then(res => res.data);
    };

    const remove = (eventCompanyEmployeeId) => {
      return $http.delete(`${API}/event/official-provider/${eventCompanyEmployeeId}`);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventOfficialProvider/form/form.html`,
        controller: "EventOfficialProvider.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };


    return {
      list,
      save,
      remove,
      form
    };
  }
})(angular);
