(angular => {
  "use strict";

  angular.module("EventHotel").factory("EventHotelService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "AuthService",
    "FormRedirectService",
    EventHotelService
  ]);

  function EventHotelService($q, $http, $mdDialog, API, AuthService, FormRedirectService) {

    const list = () => {
      return $http.get(`${API}/event/hotel/`).then(res => res.data);
    };

    const form = ($event, hotel) => {
      return $mdDialog.show({
        templateUrl: `admin/eventHotel/form-hotel/form-hotel.html`,
        controller: "EventHotel.FormHotelCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          hotel: angular.copy(hotel)
        },
        targetEvent: $event
      });
    };

    const formRecreation = ($event, hotel, recreation) => {
      return $mdDialog.show({
        templateUrl: `admin/eventHotel/form-recreation-hotel/form-recreation-hotel.html`,
        controller: "EventHotel.FormRecreationHotelCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          hotel: angular.copy(hotel),
          recreation: angular.copy(recreation)
        },
        targetEvent: $event
      });
    };

    const save = hotel => {
      return $http.post(`${API}/event/hotel/`, hotel).then(res => res.data);
    };

    const saveRecreation = recreation => {
      return $http.post(`${API}/event/hotel/recreation/`, recreation).then(res => res.data);
    };

    const remove = (hotelId) => {
      return $http.delete(`${API}/event/hotel/${hotelId}`);
    };

    const removeRecreation = (recreationId) => {
      return $http.delete(`${API}/event/hotel/recreation/${recreationId}`);
    };

    const getTypes = (ignoreRole) => {
      const user = AuthService.getTokenData();
      const types = angular.copy([
        { type: 'single', display: 'Individual', spots: 1 },
        { type: 'double', display: 'Duplo', spots: 2 },
        { type: 'triple', display: 'Triplo', spots: 3 },
        { type: 'quadriple', display: 'Quadruplo', spots: 4 }
      ]);

      if (user.role !== 'company_manager' || ignoreRole) {
        return types;
      }

      return types.filter(t => {
        return t.type === 'single' || t.type === 'double';
      });

    };

    const exportList = () => {
      return FormRedirectService.submit(`${API}/event/hotel/export`);
    };

    const listPriceRooms = () => {
      return $http.get(`${API}/event/hotel/total-price-rooms`).then(res => res.data);
    };


    return {
      list,
      save,
      saveRecreation,
      remove,
      removeRecreation,
      form,
      formRecreation,
      getTypes,
      exportList,
      listPriceRooms
    };
  }
})(angular);
