((angular) => {
  'use strict';

  angular
    .module('EventQuestion')
    .factory('EventQuestionService', [
      '$q',
      '$http',
      '$mdDialog',
      'dateHelper',
      'API',
      'AuthService',
      EventQuestionService,
    ]);

  function EventQuestionService($q, $http, $mdDialog, dateHelper, API) {
    const list = () => {
      return $http.get(`${API}/event/questions`).then((res) => res.data);
    };

    const listAnswers = () => {
      return $http.get(`${API}/event/questions/answers`).then((res) => {
        return res.data.map((p) => {
          p = dateHelper.parseObj(p);
          p.answers = p.answers.map((a) => dateHelper.parseObj(a));
          p.date = p.answers[0].created_at;
          return p;
        });
      });
    };

    const formSection = ($event, section) => {
      return $mdDialog.show({
        templateUrl: `admin/eventQuestion/form-section/form-section.html`,
        controller: 'EventQuestion.FormSectionCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          section: angular.copy(section),
        },
        targetEvent: $event,
      });
    };

    const formQuestion = ($event, section, question) => {
      return $mdDialog.show({
        templateUrl: `admin/eventQuestion/form-question/form-question.html`,
        controller: 'EventQuestion.FormQuestionCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          section: angular.copy(section),
          question: angular.copy(question),
        },
        targetEvent: $event,
      });
    };

    const saveSection = (section) => {
      return $http
        .post(`${API}/event/questions/section`, section)
        .then((res) => res.data);
    };

    const saveQuestion = (question) => {
      return $http
        .post(`${API}/event/questions/`, question)
        .then((res) => res.data);
    };

    const removeSection = (sectionId) => {
      return $http.delete(`${API}/event/questions/section/${sectionId}`);
    };

    const removeQuestion = (questionId) => {
      return $http.delete(`${API}/event/questions/${questionId}`);
    };

    const saveQuestionOrder = (data) => {
      return $http
        .post(`${API}/event/questions/order`, data)
        .then((res) => res.data);
    };

    const saveSectionOrder = (data) => {
      return $http
        .post(`${API}/event/questions/section/order`, data)
        .then((res) => res.data);
    };

    const publish = (publish) => {
      return $http
        .post(`${API}/event/questions/publish`, { publish })
        .then((res) => res.data);
    };

    return {
      list,
      listAnswers,
      saveSection,
      saveQuestion,
      removeSection,
      removeQuestion,
      formSection,
      formQuestion,
      saveQuestionOrder,
      saveSectionOrder,
      publish,
    };
  }
})(angular);
