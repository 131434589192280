(function() {
  "use strict";

  angular.module("Common")
    .directive("scrollTop", ["$rootScope", directive]);

  function directive($rootScope) {

    return {
      restrict: "A",
      scope: false,
      link: (scope, element) => {
        $rootScope.$on("scroll-top", () => {
          angular.element(element).animate({ scrollTop: 0 }, 300);
        });
      }
    };

  }

})();
