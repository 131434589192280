(angular => {
  "use strict";

  angular.module("EventOfficialProvider").controller("EventOfficialProvider.FormCtrl", [
    "$q",
    "$routeParams",
    "$mdDialog",
    "$filter",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventOfficialProviderService",
    "model",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $mdDialog, $filter, $scope, $timeout, Loader, Toast, eventService, eventOfficialProviderService, model) {
    this.model = model || {};
    if (model) {
      this.model.phone = this.model.phone ? this.model.phone.split(',') : [];
    } else {
      this.model.phone = [];
    }

    eventService.current().then(event => {
      this.event = event;
    });

    this.form = ($event, model) => {
      eventOfficialProviderService.form($event, model, this.company.id).then(() => {});
    };

    this.getImage = (image) => {
      if (!image) return "/img/sem-imagem.jpg";
      return image.base64 || `/content/${image}`;
    };

    this.setLogo = result => {
      this.model.logo = {
        filename: result.filename,
        base64: result.base64
      };
    };

    this.removeLogo = () => {
      this.model.logo = null;
    };

    this.cancel = $mdDialog.cancel;

    this.save = () => {
      Loader.add(eventOfficialProviderService.save(this.model).then(() => {
        Toast.show("Serviço oficial cadastrado com sucesso", "success");
        $mdDialog.hide(this.model);
      }));
    };

  }
})(angular || {});
