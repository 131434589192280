(angular => {
  "use strict";

  angular.module("EffectiveRevenues").controller("EffectiveRevenues.RevenuesDetailsCtrl", [
    "$q",
    "$route",
    "$routeParams",
    "$filter",
    "$mdpDatePicker",
    "$scope",
    "$timeout",
    "$mdDialog",
    "$location",
    "Loader",
    "Toast",
    "CompanyService",
    "EffectiveRevenuesService",
    RevenuesDetailsCtrl
  ]);

  function RevenuesDetailsCtrl($q, $route, $routeParams, $filter, $mdpDatePicker, $scope, $timeout, $mdDialog, $location, Loader, Toast, CompanyService, EffectiveRevenuesService) {
    this.load = () => {
      EffectiveRevenuesService.get($routeParams.revenueId).then(revenue => {
        this.model = revenue;
      }).catch(err => Toast.httpHandler(err));
    };
    this.load();
    this.form = ($event, model) => {
      EffectiveRevenuesService.form($event, model).then(() => {
        $route.reload();
      });
    };

    this.pay = (parcel, $event) => {
      $mdpDatePicker(new Date(), { targetEvent: $event }).then(selectedDate => {
        parcel.pay_day = selectedDate;
        Loader.add(EffectiveRevenuesService.pay(parcel.id, parcel.pay_day)).then(() => {
          Toast.show("Parcela paga com sucesso", "success");
          this.load();
        }).catch(err => Toast.httpHandler(err));

      });
    };

    this.receiptMail = (parcel) => {
      return Loader.add(EffectiveRevenuesService.receiptMail(parcel.id)).then(() => {
        Toast.show("Recibo gerado e enviado com sucesso", "success");
      }).catch(err => Toast.httpHandler(err));
    };

    this.generateReceipt = (parcel) => {
      return EffectiveRevenuesService.generateReceipt(parcel.id);
    };

    this.remove = () => {
      EffectiveRevenuesService.remove(this.model.id).then(() => {
        $location.path("/revenues");
      }).catch(err => Toast.httpHandler(err));
    };
  }
})(angular || {});
