(function(angular) {
  "use strict";

  angular.module("Common").factory("dateHelper", [
    "$filter",
    DateHelper
  ]);

  function DateHelper($filter) {

    function merge(date, hour) {
      date = angular.copy(date);

      if (hour) {
        const parts = hour.split(":");

        date.setHours(parts[0]);
        date.setMinutes(parts[1]);
        date.setSeconds(parts[2] || 0);
      }

      return date;
    }

    function isISODate(value) {
      return /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d+)Z/.test(value);
    }

    function parseObj(obj, fields) {
      if (!fields) {
        fields = Object.keys(obj).filter(x => x.toLowerCase().indexOf("date") > -1 || x.toLowerCase() === "created_at");
      }

      fields.forEach(key => {
        if (!obj[key] || !isISODate(obj[key])) return;
        obj[key] = new Date(obj[key]);
      });

      return obj;
    }

    function getTime(date) {
      if (!date) return;
      return $filter("date")(date, "HH:mm");
    }

    return { merge, parseObj, getTime };

  }

})(angular);
