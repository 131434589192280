(angular => {
  "use strict";

  angular.module("PushNotification")
    .controller("PushNotification.IndexCtrl", [
      "$scope",
      "Toast",
      "PushNotificationService",
      IndexCtrl
    ]);

  function IndexCtrl($scope, Toast, PushNotificationService) {

    this.query = {};
    this.promise = null;
    this.notifications = [];

    const load = query => {
      this.promise = PushNotificationService.list(query);

      return this.promise
        .then(notifications => this.notifications = notifications)
        .catch(Toast.httpHandler);
    };

    this.form = $event => {
      return PushNotificationService
        .form($event)
        .then(() => load(this.query));
    };

    this.history = ($event, notification) => {
      return PushNotificationService
        .history($event, notification)
        .then(() => load(this.query));
    };

    $scope.$watch(() => this.query, load, true);
  }
})(angular || {});
