(angular => {
  "use strict";

  angular.module("EventCompanyEmployee").factory("EventCompanyEmployeeService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    EventCompanyEmployeeService
  ]);

  function EventCompanyEmployeeService($q, $http, $mdDialog, API) {

    const listByCompany = (companyId) => {
      return $http.get(`${API}/event/company-employee/${companyId}`).then(res => res.data);
    };

    const save = eventCompanyEmployee => {
      return $http.post(`${API}/event/company-employee/`, eventCompanyEmployee).then(res => res.data);
    };

    const remove = (eventCompanyEmployeeId) => {
      return $http.delete(`${API}/event/company-employee/${eventCompanyEmployeeId}`);
    };

    const resendInvite = (providerId) => {
      return $http.post(`${API}/event/company-employee/resend/${providerId}`)
        .then(res => res.data);
    };

    const standBuilder = () => {
      return $http.get(`${API}/event/company-employee/standBuilder`)
        .then(res => res.data);
    };

    const form = ($event, model, exhibitorId) => {
      return $mdDialog.show({
        templateUrl: `admin/eventCompanyEmployee/form/formUser/formUser.html`,
        controller: "EventCompanyEmployee.FormUserCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model),
          exhibitorId: angular.copy(exhibitorId)
        }
      });
    };


    return {
      listByCompany,
      save,
      remove,
      form,
      resendInvite,
      standBuilder
    };
  }
})(angular);
