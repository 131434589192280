(angular => {

  angular.module("PushNotification", [
      "Config"
    ])
    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/push-notification", {
        controller: "PushNotification.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/pushNotification/index/index.html`,
        role: "admin"
      });
  }

})(angular);
