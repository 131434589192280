(angular => {
  "use strict";

  angular.module("EmailMarketing").controller("EmailMarketing.IndexCtrl", [
    "$scope",
    "$mdDialog",
    "$timeout",
    "Loader",
    "Toast",
    "dateHelper",
    "EventService",
    "EmailMarketingService",
    IndexCtrl
  ]);

  function IndexCtrl($scope, $mdDialog, $timeout, loader, toast, dateHelper, eventService, emailMarketingService) {
    let queryTimeout = null;
    this.query = { orderBy: "-send_date" };

    eventService.current().then(event => this.event = event);
    this.emails;

    this.loadEmails = () => {
      const query = angular.copy(this.query);
      loader.add(emailMarketingService.list(query)).then(emails => {
        this.emails = emails;
        this.emails.map(e => {
          e.send_time = dateHelper.getTime(e.send_date);
          return e;
        });
      }).catch(err => toast.httpHandler(err));
    };

    this.form = ($event, model, type) => {
      if (type === 'duplicate') delete model.id;

      emailMarketingService.form($event, model, type).then(() => {
        this.loadEmails();
      });
    };

    this.historic = ($event, model) => {
      emailMarketingService.historic($event, model.id).then(() => {
        emailMarketingService.list().then(emails => {
          this.emails = emails;
        }).catch(err => toast.httpHandler(err));
      });
    };

    this.removeEmail = (email) => {
      loader.add(emailMarketingService.remove(email.id)).then(() => {
        toast.show("Email excluído com sucesso", "success");
        this.loadEmails();

      }).catch(err => toast.httpHandler(err));
    };

    $scope.$watch(() => this.emails, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadEmails(), 500);
    }, true);

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadEmails(), 500);
    }, true);

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
