(angular => {
  "use strict";

  angular.module("User").factory("UserService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    UserService
  ]);

  function UserService($q, $http, $mdDialog, API) {

    this.params = {};

    const list = (params) => {
      params = angular.copy(params);
      params.direction = "asc";

      if (params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/user`,
        params: params || this.params
      }).then((res) => {
        return res.data;
      });
    };

    const form = ($event, user, company, role) => {
      return $mdDialog.show({
        templateUrl: `admin/user/form/form.html`,
        controller: "User.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          user: angular.copy(user),
          company: angular.copy(company),
          role: angular.copy(role)
        },
        targetEvent: $event
      });
    };

    const select = ($event, role) => {
      return $mdDialog.show({
        templateUrl: `admin/user/select/select.html`,
        controller: "User.SelectCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          role: angular.copy(role)
        },
        targetEvent: $event
      });
    };

    const save = (user) => {
      return $http.post(`${API}/user`, user).then(res => res.data);
    };

    const remove = (user) => {
      return $http.delete(`${API}/user/${user.id}`);
    };

    const removeCompany = (userId, companyId) => {
      return $http.delete(`${API}/user/remove-company/${userId}/${companyId}`);
    };

    return {
      list,
      remove,
      removeCompany,
      form,
      select,
      save
    };
  }
})(angular);
