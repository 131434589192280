(function() {
  "use strict";

  angular.module("App")
    .directive("satisfactionGraph", ["c3", directive]);

  function directive(c3) {

    return {
      restrict: "E",
      scope: { data: '=', isPrint: '=' },
      link: (scope, element) => {


        const average = scope.data.map(question => question.average);
        const standardDeviation = scope.data.map(question => question.standardDeviation);
        const questions = scope.data.map(question => question.description);
        const size = questions.length * 80;
        angular.element(element).css({
          display: 'block',
          height: size + 'px'
        });

        c3.generate({
          size: {
            width: scope.isPrint ? '650' : ''
          },
          bindto: element[0],
          data: {
            columns: [
              _.flattenDeep(['Desvio Padrão', standardDeviation]),
              _.flattenDeep(['Média', average]),
            ],
            types: {
              'Média': 'bar',
            },
            labels: true,
            colors: {
              'Média': '#B2DFDB',
              'Desvio Padrão': '#B71C1C',
            },
          },
          axis: {
            rotated: true,
            x: {
              type: 'category',
              categories: questions,
              label: {
                text: 'Perguntas',
                position: 'outer-middle'
              }
            },
            y: {
              tick: {
                values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              }
            }
          }

        });
      }
    };

  }

})();
