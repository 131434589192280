(angular => {

  angular.module("EventManualUpload", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/upload-manuais", {
        controller: "EventManualUpload.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventManualUpload/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'company_manager', 'organizer', 'stand_builder', 'coordinator']
      })
      .when("/upload-manuais/:eventManualUploadId", {
        controller: "EventManualUpload.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventManualUpload/form/form.html`
      });
  }

})(angular);
