(angular => {
  "use strict";

  angular.module("App").controller("App.DashboardCtrl", [
    DashboardCtrl
  ]);

  function DashboardCtrl() {

    this.enabledTabs = [];

    this.enableTab = tab => this.enabledTabs[tab] = true;
    this.print = () => window.print();
  }

})(angular || {});
