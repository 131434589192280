((angular) => {
  'use strict';

  angular
    .module('FindRetailerSession')
    .controller('FindRetailerSession.IndexCtrl', [
      '$scope',
      '$timeout',
      '$routeParams',
      '$mdDialog',
      '$q',
      'XLSReader',
      'Loader',
      'Toast',
      'EventService',
      'ParticipantService',
      'AuthService',
      'ActivityService',
      'CompanyService',
      'FormRedirectService',
      'API',
      '$sce',
      IndexCtrl,
    ]);

  function IndexCtrl(
    $scope,
    $timeout,
    $routeParams,
    $mdDialog,
    $q,
    XLSReader,
    Loader,
    Toast,
    EventService,
    ParticipantService,
    authService,
    ActivityService,
    CompanyService,
    FormRedirectService,
    API,
    $sce
  ) {
    this.user = authService.getTokenData();
    let queryTimeout;
    this.promise = null;
    this.selected = [];
    this.query = { orderBy: 'name' };
    this.pagination = { page: 1, limit: 10 };
    this.participants = [];
    this.activities = [];
    this.eventDays = [];
    this.statusSelected = '';
    this.status = ['Selecionado', 'Não Selecionado', 'Consta na Lista'];
    this.formateDate = '';
    this.query1 = { orderBy: 'name' };
    this.formatedDescription;

    this.formatTime = (date) => {
      if (date !== undefined) return date.substring(11, 15);
    };

    EventService.current().then((event) => {
      this.event = event;
      this.eventDays = EventService.getEventDays(event);
      this.query.date = this.eventDays[0];
    });

    this.loadParticipants = () => {
      const query = angular.copy(this.query1);
      return (this.promise = $q
        .all([
          ActivityService.findActivityByEvent(
            this.user.eventId,
            this.user.companyIds[0]
          ),
          ParticipantService.findParticipantsByEventSession(
            this.user.eventId,
            this.user.companyIds[0],
            query.orderBy
          ),
        ])
        .then(([activities, participants]) => {
          if (activities.length > 0) {
            this.participants = this.selectList(
              activities,
              this.filterQuery(participants)
            );
            this.activities = activities;
            this.activities.map((activity) => {
              activity.formatedDescription = $sce.trustAsHtml(
                activity.description
              );
            });
            // console.log(this.activities);
            this.formateDate = this.formateDateActivity(
              this.activities[0].date
            );
            this.filterStatus();
            // console.log(this.participants);
          } else {
            Toast.show(
              'Sua empresa não possui nenhuma atividade agendada',
              'error'
            );
          }
        })
        .catch((err) => Toast.httpHandler(err)));
    };

    this.exportParticipantFindRetailerSession = () => {
      FormRedirectService.submit(
        `${API}/event/${this.user.eventId}/${this.user.companyIds[0]}/exportParticipantFindRetailerSession`,
        event
      );

      $mdDialog.hide();
      Toast.show('Planilha exportada com sucesso', 'success');
      this.loadData();
    };

    this.filterStatus = () => {
      if (this.statusSelected === 'Selecionado') {
        this.participants = this.participants.filter((p) => {
          return p.selected === true;
        });
      } else if (this.statusSelected === 'Não Selecionado') {
        return (this.participants = this.participants.filter((p) => {
          return p.selected === false;
        }));
      } else if (this.statusSelected === 'Consta na Lista') {
        return (this.participants = this.participants.filter((p) => {
          return p.bussinessSource === true;
        }));
      }
    };

    this.filterQuery = (participants) => {
      const query = angular.copy(this.search);
      if (query) {
        return participants.filter((p) => {
          if (p.guest_company) {
            return (
              p.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
              p.company.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
              p.position.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
              p.guest_company.toUpperCase().indexOf(query.toUpperCase()) > -1
            );
          } else {
            return (
              p.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
              p.company.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
              p.position.toUpperCase().indexOf(query.toUpperCase()) > -1
            );
          }
        });
      } else {
        return participants;
      }
    };

    this.selectList = (activities, participants) => {
      participants.forEach((p) => {
        p.selected = false;
        p.status_participant = false;
        p.bussinessSource = false;
      });

      const selectedRestricted = activities[0].restricted_list.filter((rl) => {
        return rl.status === 'SELECIONADO';
      });

      const selectedRestrictedBussines = activities[0].restricted_list.filter(
        (rl) => {
          return rl.selection_source === 'Admin';
        }
      );

      const confirmedInvited = activities[0].restricted_list.filter((rl) => {
        return rl.status_participant === 1;
      });

      selectedRestrictedBussines.forEach((rl) => {
        participants.forEach((p) => {
          if (
            rl.cpf.toUpperCase() === p.cpf.toUpperCase() &&
            rl.selection_source === 'Admin'
          ) {
            p.bussinessSource = true;
          }
        });
      });

      confirmedInvited.forEach((rl) => {
        participants.forEach((p) => {
          if (rl.cpf === p.cpf && rl.status_participant === 1) {
            p.status_participant = true;
          }
        });
      });

      selectedRestricted.forEach((rl) => {
        participants.forEach((p) => {
          if (
            rl.cpf.toUpperCase() === p.cpf.toUpperCase() &&
            rl.status === 'SELECIONADO'
          ) {
            p.selected = true;
          }
        });
      });
      // console.log(participants);
      return participants;
    };

    this.compare = (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };

    this.toogleAll = () => {
      if (!this.all) {
        this.participants.forEach((p) => {
          p.selected = true;
        });
      } else {
        this.participants.forEach((p) => {
          p.selected = false;
        });
      }
    };

    this.formateDateActivity = (date) => {
      if (date !== undefined) {
        let format = new Date(date);
        let dia = format.getDate().toString();
        let diaF = dia.length === 1 ? '0' + dia : dia;
        let mes = (format.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        let mesF = mes.length === 1 ? '0' + mes : mes;
        let anoF = format.getFullYear();

        return diaF + '/' + mesF + '/' + anoF;
      }
    };

    this.addRestrictedList = () => {
      queryTimeout = $timeout(() => {
        let participantRestricted = this.participants.map((val) => ({
          activity_id: this.activities[0].id,
          company_name: val.company.name,
          company_id: val.company.id,
          name: val.name,
          cpf: val.cpf,
          status: val.selected ? 'SELECIONADO' : '',
          selection_source: val.selected ? 'Bussiness' : '',
          status_participant: 1,
        }));

        this.activities[0].restricted_list = participantRestricted;
        const model = angular.copy(this.activities[0]);
        return Loader.add(ActivityService.saveRestrictedListSession(model))
          .then((result) => {
            $mdDialog.hide(result);

            if (
              !result.importationResult ||
              !result.importationResult.errors.length
            ) {
              this.loadParticipants();
              Toast.show('Adicionado com sucesso', 'success');
              return;
            }

            const participants = result.importationResult.errors.map((data) => {
              const errors = {
                'participant-not-found':
                  'O participante não está inscrito no evento',
                'participant-associated':
                  'O participante não é um associado dessa rede',
                'participant-duplicated': 'O cpf já foi inserido',
                'Nonexistent-company': 'Essa Empresa não está cadastrada',
              };

              data.participant.error = errors[data.error] || data.error;
              return data.participant;
            });

            $mdDialog.show({
              templateUrl: `admin/activity/import/import.html`,
              controller: 'Activity.ImportCtrl',
              controllerAs: '$ctrl',
              clickOutsideToClose: true,
              escapeToClose: true,
              fullscreen: true,
              locals: { participants },
            });
          })
          .catch((err) => {
            if (err.data && err.data.message === 'activity_speaker_pkey') {
              return Toast.show('Este palestrante já foi cadastrado', 'error');
            }

            if (err.data && err.data.message === 'import-list-error') {
              return Toast.show(
                'Nem todos os dados foram salvos, verifique o arquivo de erros!',
                'error'
              );
            }

            return Toast.httpHandler(err);
          });
      }, 500);
    };

    this.getCompanyName = (idCompany) => {
      CompanyService.get(idCompany)
        .then((company) => {
          return company.name;
        })
        .catch((err) => {
          Toast.show(err);
        });
    };

    this.isChecked = () => {
      let selected = this.participants.filter((p) => {
        return p.selected === true;
      });
      return selected.length === this.participants.length;
    };
    $scope.$watch(
      () => this.query1,
      () => {
        $timeout.cancel(queryTimeout);
        queryTimeout = $timeout(() => {
          this.pagination.page = 1;
          this.loadParticipants();
        }, 500);
      },
      true
    );

    $scope.$watch(
      () => this.search,
      () => {
        $timeout.cancel(queryTimeout);
        queryTimeout = $timeout(() => {
          this.pagination.page = 1;
          this.loadParticipants();
        }, 500);
      },
      true
    );

    $scope.$watch(
      () => this.statusSelected,
      () => {
        $timeout.cancel(queryTimeout);
        queryTimeout = $timeout(() => {
          this.pagination.page = 1;
          this.loadParticipants();
        }, 500);
      },
      true
    );

    $scope.$watch('headerTabs.current', (value) => {
      this.query = null;
      this.currentTab = value;
      this.type = ['common', 'staff', 'speaker'][value] || 'common';
    });

    this.verifyShow = () => {
      return (
        this.user.companyTypes[0] === 'commercial partner' ||
        this.user.role === 'admin'
      );
    };

    this.loadParticipants();
  }
})(angular || {});
