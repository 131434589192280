(angular => {
  "use strict";

  angular.module("Company").component("companyDetailsUserOrganizer", {
    templateUrl: 'admin/company/details/components/details-user-organizer.html',
    controller: [
      "Loader",
      "Toast",
      "UserService",
      DetailsUser
    ],
    bindings: {
      company: "="
    }
  });

  function DetailsUser(Loader, Toast, UserService) {

    this.$onInit = () => {
      this.query = {
        orderBy: "name",
        companyId: this.company.id
      };
      this.loadUsers();
    };

    this.loadUsers = () => {
      UserService.list(this.query).then(user => {
        this.users = user;
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, user) => {
      UserService.form($event, user, this.company, 'organizer').then(() => {
        this.loadUsers();
      });
    };

    this.add = ($event) => {
      UserService.select($event, "organizer").then(user => {
        user.company_id = this.company.id;
        return Loader.add(UserService.save(user));
      }).then(() => {
        Toast.show("Usuário salvo com sucesso", "success");
        this.loadUsers();
      }).catch(err => {
        if (!err) return;
        Toast.httpHandler(err);
      });
    };

    this.removeCompany = ($event, user) => {
      Loader.add(UserService.removeCompany(user.id, this.company.id)).then(() => {
        Toast.show("Usuário removido da Empresa com sucesso", "success");
        this.loadUsers();
        return this.company;
      }).catch(err => Toast.httpHandler(err));
    };
  }

})(angular);
