(function(angular, $) {
  "use strict";

  angular.module('Common').directive('webcam', [ "webcamjs", directive ]);

  const controller = [ '$scope', '$timeout', 'webcamjs', webcamController ];
  function webcamController($scope, $timeout, webcamjs) {

    $scope.ngModel = $scope.ngModel || {};

    const width  = $scope.width  = $scope.width  || 200;
    const height = $scope.height = $scope.height || 150;

    this.usingWebcam = false;

    this.canUseWebcam = ((navigator) => {
      if (!navigator) {
        return false;
      }
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        return true;
      }
      return !!navigator.getUserMedia;
    })(navigator);

    this.getUrl = () => {

      const { base64, filename } = $scope.ngModel;

      if (base64) {
        return $scope.ngModel.base64;
      }
      if (filename) {
        return `/content/${filename}`;
      }

      return '/img/sem-imagem.jpg';
    };

    this.removePhoto = () => $scope.ngModel = {};
    this.uploadPhoto = ({ filename, base64 }) => $scope.ngModel = { filename, base64 };

    this.takePhoto = () => {
      webcamjs.snap(base64 => $scope.ngModel = { filename: 'xpto.jpg', base64 });
      this.closeWebcam();
    };

    this.openWebcam = () => {

      this.usingWebcam= true;

      webcamjs.set({
        width,
        height,
        image_format: 'jpeg',
        jpeg_quality: 90,
        flip_horiz: true
      });
      webcamjs.attach($('#webcam')[0]);
    };

    this.closeWebcam = () => {
      webcamjs.reset();
      this.usingWebcam = false;
    };
  }

  function directive(webcamjs) {
    return {
      restrict: 'E',
      scope: {
        ngModel: '=',
        width: '@',
        heigth: '@'
      },
      link: () => webcamjs.set({width: 200, heigth: 160}),
      controller,
      controllerAs: '$ctrl',
      templateUrl: '_common/directives/webcam/webcam.html'
    };
  }

})(angular, angular.element);
