(angular => {
  "use strict";

  angular.module("EventGuest").factory("EventGuestService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "XLSReader",
    "FormRedirectService",
    EventGuestService
  ]);

  function EventGuestService($q, $http, $mdDialog, API, XLSReader, FormRedirectService) {

    const list = (params) => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/event/guest`,
        params: params,
      }).then((res) => res.data);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventGuest/form/form.html`,
        controller: "EventGuest.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const disapproveGuestForm = (guest, $event, selected) => {
      return $mdDialog.show({
        templateUrl: `admin/eventGuest/disapprove/disapprove.html`,
        controller: "EventGuest.DisapproveCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          guest: angular.copy(guest),
          selected: angular.copy(selected)
        },
        targetEvent: $event
      });
    };

    const save = guest => {
      return $http.post(`${API}/event/guest`, guest).then(res => res.data);
    };

    const approve = (guestIds) => {
      return $http.post(`${API}/event/guest/approve`, { guestIds }).then(res => res.data);
    };

    const disapprove = (guestIds, reason) => {
      return $http.post(`${API}/event/guest/disapprove`, { guestIds, reason }).then(res => res.data);
    };

    const importList = (data, eventId, userEmail) => {
      return $http.post(`${API}/event/guest/import`, { data, eventId, userEmail }).then(result => result.data);
    };

    const formImport = $event => {
      return XLSReader.loadFile('guest-list-public').then(guests => {
        return $mdDialog.show({
          templateUrl: `admin/eventGuest/import/import.html`,
          controller: "EventGuest.ImportCtrl",
          controllerAs: "$ctrl",
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: true,
          locals: { guests },
          targetEvent: $event
        });
      });
    };


    const resendInvite = (resendInviteIds, eventId) => {
      return $http.post(`${API}/event/guest/resend`, { resendInviteIds, eventId }).then(res => res.data);
    };

    const remove = (guestIds) => {
      return $http.post(`${API}/event/guest/remove`, { guestIds }).then(res => res.data);
    };

    const exportList = (status) => {
      return FormRedirectService.submit(`${API}/event/guest/export/${status}`);
    };

    return {
      list,
      form,
      save,
      approve,
      resendInvite,
      disapprove,
      formImport,
      importList,
      disapproveGuestForm,
      remove,
      exportList
    };

  }
})(angular);
