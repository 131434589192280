(angular => {
  "use strict";

  angular.module("ActivityPremium").controller("ActivityPremium.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "ActivityService",
    "ParticipantService",
    "ActivityPremiumService",
    "EventCompanyService",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, $scope, $timeout, Loader, Toast, eventService, activityService, participantService, activityPremiumService, eventCompanyService) {
    let queryTimeout;
    this.query = {};
    this.eventCompany = null;
    this.tickets = [];
    this.person = {};
    this.participant = null;
    this.search = null;

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadParticipants = () => {
      this.participant = null;
      this.search = null;
      eventCompanyService.getV2($routeParams.companyId).then(eventCompany => {
        this.eventCompany = eventCompany;
        activityPremiumService.listWithParticipants().then(activities => this.activities = activities);
        this.queryParticipant();
      }).catch(err => Toast.httpHandler(err));
    };

    this.queryParticipant = term => {
      return participantService.query(term, this.eventCompany.company_id);
    };

    this.changeParticipant = participant => {
      if (!participant) return;
      const types = ['accepted'];
      if (!types.includes(participant.status)) return Toast.show(`Participante ainda não aceitou o convite`, "error");
      this.participant = participant;
    };

    this.addPerson = ($event) => {
      const limitDate = moment(this.event.limit_date_activity_premium);
      const currentDate = moment(new Date());
      if (currentDate.isAfter(limitDate, 'day')) return Toast.show(`A data para cadastro de atividades premium neste evento já expirou`, "error");
      if (!this.participant) return;

      this.hasSpotsActivities = this.verifySpotsPremium();
      activityPremiumService.formSPot($event, this.participant, this.activities, this.hasSpotsActivities).then(() => this.loadParticipants());
    };

    this.verifySpotsPremium = () => {
      const hasSpotsActivities = [];
      const companyId = this.eventCompany.company_id;
      const spotsPremium = this.eventCompany.spots_activity_premium;
      const activities = this.activities.filter(a => a.type === 'premium');
      activities.forEach(a => {
        const participantsByCompany = a.participants.filter(p => p.company_id === companyId);
        if (participantsByCompany.length < spotsPremium) {
          hasSpotsActivities.push(a.id);
        }
      });
      return hasSpotsActivities;
    };
    this.removePerson = (participant, activity) => {
      participantService.removeActivities(participant.id, [activity.id]).then(() => {
        Toast.show("Participante removido da atividade com sucesso", "success");
        this.loadParticipants();
      });

    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadParticipants(), 500);
    }, true);

  }
})(angular);
