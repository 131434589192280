(angular => {
  "use strict";

  angular.module("Company").controller("Company.ListCtrl", [
    "$scope",
    "$location",
    "$timeout",
    "Loader",
    "Toast",
    "CompanyService",
    "AuthService",
    ListCtrl
  ]);

  function ListCtrl($scope, $location, $timeout, Loader, Toast, CompanyService, authService) {
    let queryTimeout = null;

    this.promise = null;
    this.selected = [];
    this.statuses = ["active", "inactive"];
    this.pagination = { page: 1, limit: 10 };

    this.loadCompanies = () => {
      const user = authService.getTokenData();
      const query = angular.copy(this.query);
      this.promise = CompanyService.list(query).then(companies => {
        this.companies = user.role==='organizer' ? companies.filter((company)=> company.type === 'organizer' || company.type==='stand_builder'): companies;
      }).catch(err => Toast.httpHandler(err));
    };

    this.remove = (company) => {
      Loader.add(CompanyService.remove(company.id)).then(() => {
        Toast.show("Empresa excluída com sucesso", "success");
        this.loadCompanies();
        return company;
      }).catch(err => {
        Toast.show(err.data.message, "error");
      });
    };

    this.form = ($event) => {
      CompanyService.form($event).then(company => {
        $location.path(`/company/${company.id}`);

      });
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadCompanies(), 500);
    }, true);

  }
})(angular || {});
