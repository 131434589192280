(angular => {
  "use strict";

  angular.module("Contract").controller("Contract.FormBenefitsCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "ContractService",
    FormBenefitsCtrl
  ]);

  function FormBenefitsCtrl($q, $routeParams, $filter, $scope, $timeout, Loader, Toast, contractService) {
    let queryTimeout;
    this.model = null;


    this.loadBenefits = () => {
      contractService.get($routeParams.modelId).then(model => {
        this.model = model;
        contractService.listBenefits(this.model.id).then(benefits => {
          this.benefits = benefits;
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.inCategory = (value, category) => {
      return !!(value & category);
    };

    this.remove = (benefit) => {
      contractService.removeBenefit(benefit.id).then(() => {
        Toast.show("Benefício removido com sucesso", "success");
        this.loadBenefits();
      });
    };

    this.form = (benefit) => {
      contractService.formBenefits(benefit, this.model.id).then(() => {
        this.loadBenefits();
      });
    };

    this.importList = ($event) => {
      contractService.formImportList($event, this.model.id).then(() => {
        this.loadBenefits();
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadBenefits(), 500);
    }, true);

  }
})(angular || {});
