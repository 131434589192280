(angular => {

  angular.module("Common")

    .filter("phone", [PhoneFilter]);

  function PhoneFilter() {
    function filter(type) {
      if (!type) return;
      return `(${type.substring(0, 2)}) ${type.substring(2, 6)}-${type.substring(6, 11)}`;
    }

    return filter;
  }

})(angular);
