(angular => {
  "use strict";

  angular.module("EffectiveRevenues").factory("EffectiveRevenuesService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "FormRedirectService",
    EffectiveRevenuesService
  ]);

  function EffectiveRevenuesService($q, $http, $mdDialog, API, FormRedirectService) {
    const list = (accountingYearId) => {
      return $http.get(`${API}/effective-revenues/${accountingYearId}`).then(res => res.data);
    };

    const get = (revenueId) => {
      return $http.get(`${API}/effective-revenues/details/${revenueId}`)
        .then(res => res.data);
    };

    const findDefaultBank = () => {
      return $http.get(`${API}/banks/default-bank`)
        .then(res => res.data);
    };

    const pay = (parcel_id, date) => {
      return $http.post(`${API}/effective-revenues/pay`, { parcel_id, date }).then(res => res.data);
    };

    const listAccountingYear = () => {
      return $http.get(`${API}/accounting-year/`).then(res => res.data);
    };

    const getAccountingYear = (accountingYearId) => {
      return $http.get(`${API}/accounting-year/${accountingYearId}`).then(res => res.data);
    };

    const saveAccountingYear = (accountingYear) => {
      return $http.post(`${API}/accounting-year/`, accountingYear).then(res => res.data);
    };

    const save = (revenue) => {
      return $http.post(`${API}/effective-revenues/`, revenue).then(res => res.data);
    };

    const formAccountingYear = (ticket, accountingYear) => {
      return $mdDialog.show({
        templateUrl: `admin/effectiveRevenues/accountingYear/form/accountingYearForm.html`,
        controller: "EffectiveRevenues.YearFormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          ticket: angular.copy(ticket),
          accountingYear: angular.copy(accountingYear),
        }
      });
    };

    const form = (ticket, model) => {
      return $mdDialog.show({
        templateUrl: `admin/effectiveRevenues/form.html`,
        controller: "EffectiveRevenues.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const remove = (effectiveRevenueId) => {
      return $http.delete(`${API}/effective-revenues/${effectiveRevenueId}`);
    };

    const removeAccountingYear = (accountingYearId) => {
      return $http.delete(`${API}/accounting-year/${accountingYearId}`);
    };

    const totalCosts = (effectiveRevenues) => {
      const totalContracts = effectiveRevenues.reduce((prev, value) => prev + Number(value.contract_value), 0);
      const total = totalContracts;
      return Promise.resolve(total);
    };

    const receiptMail = (parcelId) => {
      return $http.post(`${API}/effective-revenues/receipt/receipt-email/${parcelId}`).then(res => res.data);
    };

    const generateReceipt = (parcelId) => {
      return FormRedirectService.submit(`${API}/effective-revenues/receipt/${parcelId}`);
    };

    const listRevenuesCosts = (years, grouped = false) => {
      years = (Array.isArray(years) ? years : [years]).map(year => Number(year));
      return $http.post(`${API}/accounting-year/revenues-costs`, { years, grouped }).then(({ data }) => data);
    };

    const revenuesCostsTotal = revenuesCosts => {
      return Object
        .values(revenuesCosts)
        .reduce((total, { type, value }) => total + (type === 'cost' ? -value : value), 0);
    };

    return {
      list,
      get,
      pay,
      getAccountingYear,
      listAccountingYear,
      form,
      save,
      saveAccountingYear,
      formAccountingYear,
      removeAccountingYear,
      remove,
      totalCosts,
      generateReceipt,
      receiptMail,
      listRevenuesCosts,
      revenuesCostsTotal,
      findDefaultBank
    };
  }
})(angular);
