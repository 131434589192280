(angular => {
  "use strict";

  angular.module("Activity").controller("Activity.IndexCtrl", [
    "$scope",
    "$timeout",
    "$routeParams",
    "API",
    "$mdDialog",
    "$window",
    "Loader",
    "Toast",
    "EventService",
    "ActivityService",
    "LoginService",
    "$sce",
    IndexCtrl
  ]);

  function IndexCtrl($scope, $timeout, $routeParams, API, $mdDialog, $window, Loader, Toast, EventService, ActivityService, LoginService, $sce) {
    let queryTimeout;

    let eventActually;
    this.promise = null;
    this.eventDays = [];
    this.selectedTab;
    this.query = {};
    this.tabs = [];
    this.event = {};

    EventService.current().then(event => {
      this.event = event;
      eventActually = event;
      this.publishAppointmentBook = event.publish_appointment_book;
      this.eventDays = EventService.getEventDays(event);
      this.query.date = this.eventDays[0];

      if (event.activity_confirmation || event.activity_admin_confirmation) this.controlActivity = true;
      this.tabs = [
        { type: 'main', label: 'Corporativa' },
        { type: 'additional', label: 'Complementar' }
      ];
      if (event.activity_premium) {
        this.tabs.push({ type: 'premium', label: event.name_activity_premium });
      }
      if (event.marketing_activity) {
        this.tabs.push({ type: 'marketing_activity', label: event.marketing_activity_name });
      }
    });

    this.openModal = (activity) => {
      $mdDialog.show({
        templateUrl: `admin/activity/formAttach/formAttach.html`,
        controller: "Activity.FormAttachCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          activity: activity
        }
      }).then(() => {
        this.loadActivities();
      });
    };

    this.loadActivities = () => {
      this.event = eventActually;
      this.promise = Loader.add(ActivityService.listByDay(this.query)).then(activities => {
        this.activities = activities;
        this.activities.map(activity => {
          activity.speakers = activity.speakers.map(s => s.speaker);
          if (!activity.spots) activity.spots = 0;
          activity.description = $sce.trustAsHtml(activity.description);
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.remove = ($activity, activity) => {
      Loader.add(ActivityService.remove(activity)).then(() => {
        Toast.show("Atividade excluída com sucesso", "success");
        this.loadActivities();
      }).catch(err => Toast.httpHandler(err));
    };

    this.addAttachment = (result, activity) => {
      if (!result.success) {
        switch (result.reason) {
          case "invalidExtension":
            Toast.show("Extensão inválida", "error");
            break;
          case "maxFileSize":
            Toast.show("Tamanho máximo é de 5MB", "error");
            break;
        }
        return;
      }

      Loader.add(ActivityService.addAttachment(activity, result.filename, result.base64)).then(() => {
        Toast.show("Anexo criada com sucesso", "success");
        this.loadActivities();
      }).catch(err => Toast.httpHandler(err));
    };

    this.addQuestion = (activity) => {
      ActivityService.formQuestion(activity).then(() => this.loadActivities());
    };

    this.inviteAll = () => {
      ActivityService.formSendInvite(this.event.id).then(() => this.loadActivities());
    };

    this.inviteActivity = () => {
      ActivityService.formSendInviteActivity(this.event).then(() => this.loadActivities());
    };

    this.sendConfirmationActivity = () => {
      ActivityService.sendConfirmationActivity(this.event).then(() => this.loadActivities());
    };

    this.confirmPresence = () => {
      ActivityService.formConfirmPresenceActivity(this.event).then(() => this.loadActivities());
    };

    this.publishQuestion = question => {
      return Loader.add(ActivityService.publishQuestion({ id: question.id, published: !question.published }))
        .then(() => question.published = !question.published)
        .then(() => Toast.show(`Enquete ${question.published ? 'publicada' : 'bloqueada' } com sucesso`, 'success'));
    };

    this.showAnswers = (activityId) => {
      window.open(`${window.origin}/#/activity/statistics/${activityId}`, "", "menubar=no,toolbar=no,left=500,width=800,height=700");
      return;
    };

    this.sendInvite = (activity) => {

      Loader.add(ActivityService.inviteRestrictedList(activity)).then(() => {
        Toast.show("Convite enviado com sucesso", "success");
      }).catch(error => {
        if (error.message === 'empty-restricted-list') {
          return Toast.show('Não há participantes cadastrados na lista restrita', "error");
        }
        if (error.message === 'empty-activity-list') {
          return Toast.show('Não há participantes cadastrados na atividade', "error");
        }
        if (error.message === 'error-service-email') {
          $mdDialog.hide();
          return Toast.show('Erro ao enviar e-mail.', "error");
        }
        Toast.httpHandler(error);
      });
    };

    this.sendInviteAll = () => {
      Loader.add(ActivityService.inviteRestrictedListAll(this.event.id)).then(() => {
        Toast.show("Convites enviados com sucesso", "success");
      }).catch(error => {
        Toast.httpHandler(error);
      });
    };

    this.removeAttachment = (activity, attach) => {
      Loader.add(ActivityService.removeAttachment(activity, attach.path)).then(() => {
        Toast.show("Anexo removido com sucesso", "success");
        this.loadActivities();
      }).catch(err => Toast.httpHandler(err));
    };

    this.changePublishAppointmentBookStatus = () => {
      this.publishAppointmentBook = !this.publishAppointmentBook;
      return Loader.add(ActivityService.publishAppointmentBook(this.publishAppointmentBook))
        .catch(err => Toast.httpHandler(err));
    };

    this.changeSendConfirmation = () => {
      return Loader.add(ActivityService.sendConfirmationActivity())
      .catch(err => Toast.httpHandler(err));
    };

    this.preview = () => {
      Loader.add(LoginService.previewToken()).then(token => {
        window.open(`/participante#?t=${token}`);
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch("$ctrl.query", () => {
      $timeout.cancel(queryTimeout);

      queryTimeout = $timeout(() => this.loadActivities(), 2000);
    }, true);

    $scope.$watch('headerTabs.current', value => {
      this.currentTab = value;
      this.selectedTab = this.tabs[this.currentTab].type;
    });
  }
})(angular || {});
