(angular => {
  "use strict";

  angular.module("EventGuest").controller("EventGuest.ImportCtrl", [
    "$mdDialog",
    "Toast",
    "Loader",
    "EventService",
    "EventGuestService",
    "guests",
    ImportCtrl
  ]);

  function ImportCtrl($mdDialog, Toast, Loader, eventService, eventGuestService, guests) {
    this.error = false;
    this.query = { page: 1, limit: 10 };
    this.guests = guests;

    this.save = () => {
      return Loader.add(eventGuestService.importList(this.guests)).then((result) => {
        if (!result.errors.length) {
          Toast.show("Convidados importados com sucesso", "success");
          $mdDialog.hide();
          return;
        }
        this.error = true;
        this.query.page = 1;
        this.successCount = this.guests.length - result.errors.length;
        this.guests = result.errors.map(data => {
          data.guest.error = data.error;
          return data.guest;
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
    this.close = $mdDialog.hide;
  }
})(angular || {});
