(angular => {
  "use strict";

  angular.module("EventTableReservation").controller("EventTableReservation.FormPersonCtrl", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventTableReservationService",
    "tables",
    "table",
    "person",
    FormPersonCtrl
  ]);

  function FormPersonCtrl(
    $scope,
    $mdDialog,
    loader,
    toast,
    EventTableReservation,
    tables,
    table,
    person
  ) {
    this.model = {};
    this.tables = tables;

    if (table) {
      this.model.table = tables.filter(r => r.id === table.id)[0];
    }

    this.submit = () => {
      loader.add(EventTableReservation.save(this.model.table.id, person.participant_id, person.dependent_id)).then(result => {
        $mdDialog.hide(result);
      }).catch(err => {
        switch ((err.data || {}).message) {
          case 'less-spots':
            toast.show('Número de pessoas na mesa é maior que o permitido', 'error');
            break;
          case 'company-table-reservation-excedded':
            toast.show('Vagas para empresa foram excedidas', 'error');
            break;
          case 'company-table-reservation-no-spots':
            toast.show('Não existem vagas cadastradas para essa empresa', 'error');
            break;
          default:
            toast.httpHandler(err);
        }
      });
    };
    this.cancel = $mdDialog.cancel;
  }

})(angular || {});
