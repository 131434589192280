(angular => {

  angular.module("FindRetailerSession", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/findRetailerSession", {
        controller: "FindRetailerSession.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/findRetailerSession/list/list.html`,
        role: ['admin', 'company_manager']
      });
  }

})(angular);
