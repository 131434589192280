((angular) => {
  'use strict';

  angular
    .module('FindRetailerBussinesClub')
    .controller('FindRetailerBussinesClub.FormCtrl', [
      '$scope',
      '$timeout',
      '$route',
      '$routeParams',
      '$mdDialog',
      '$q',
      'XLSReader',
      'Loader',
      'Toast',
      'EventService',
      'ParticipantService',
      'AuthService',
      'ActivityService',
      'activity',
      'spots_company',
      'CompanyService',
      'FindRetailerBussinesClubService',
      FormCtrl,
    ]);

  function FormCtrl(
    $scope,
    $timeout,
    $route,
    $routeParams,
    $mdDialog,
    $q,
    XLSReader,
    Loader,
    Toast,
    EventService,
    ParticipantService,
    authService,
    ActivityService,
    activity,
    spots_company,
    companyService,
    FindRetailerBussinesClubService
  ) {
    this.user = authService.getTokenData();
    this.participant = {};
    this.spots_company = spots_company;
    this.idDelete;

    this.cancel = () => {
      $mdDialog.hide();
    };
    this.listRestrictedListBussines = () => {
      return (this.promise = $q
        .all([
          ActivityService.getById(activity.id),
          FindRetailerBussinesClubService.listRestrictedListBussines(
            activity.id,
            this.user.companyIds[0]
          ),
        ])
        .then(([activities, restricteList]) => {
          this.activity = activities;
          this.activity.restricted_list = restricteList;
        })
        .catch((err) => Toast.httpHandler(err)));
    };

    this.addParticipant = () => {
      FindRetailerBussinesClubService.findRestrictedListByCpfActivity(
        this.model.cpf,
        this.activity.id
      )
        .then((restrictedList) => {
          if (restrictedList) {
            Toast.show(
              'O participante já consta na lista desta sessão.',
              'error'
            );
          } else if (
            this.activity.restricted_list.length < this.spots_company
          ) {
            if (!this.activity.restricted_list)
              this.activity.restricted_list = [];
            FindRetailerBussinesClubService.findParticipantByCpf(this.model.cpf)
              .then((participant) => {
                if (participant) {
                  this.participant = participant;
                  this.activity.restricted_list.push({
                    activity_id: this.activity.id,
                    company_name: this.participant.company.name,
                    company_id: this.participant.company_id,
                    name: this.participant.name,
                    cpf: this.participant.cpf,
                    status: '',
                    selection_source: 'Bussiness',
                    status_participant: 1,
                    company_invite: this.user.companyIds[0],
                  });
                } else {
                  Toast.show(
                    'Este CPF não esta cadastrado como participante.',
                    'error'
                  );
                }
              })
              .catch((err) => Toast.httpHandler(err));
          } else {
            Toast.show('Limite de participantes excedido.', 'error');
          }
        })
        .catch((err) => Toast.httpHandler(err));
    };

    this.save = () => {
      const model = angular.copy(this.activity);

      let participantRestricted = this.activity.restricted_list.map((val) => ({
        activity_id: this.activity.id,
        company_id: val.company_id,
        name: val.name,
        cpf: val.cpf,
        status: '',
        selection_source: 'Bussiness',
        status_participant: 1,
        company_invite: this.user.companyIds[0],
      }));

      model.restricted_list = participantRestricted;

      return Loader.add(
        FindRetailerBussinesClubService.saveRestrictedListBussines(model)
      )
        .then((result) => {
          $mdDialog.hide(result);
          if (
            !result.importationResult ||
            !result.importationResult.errors.length
          ) {
            Toast.show('Lista restrita atualizada com sucesso', 'success');
            $route.reload();
            return;
          }

          const participants = result.importationResult.errors.map((data) => {
            const errors = {
              'participant-not-found':
                'O participante não está inscrito no evento',
              'participant-associated':
                'O participante não é um associado dessa rede',
              'participant-duplicated': 'O cpf já foi inserido',
              'Nonexistent-company': 'Essa Empresa não está cadastrada',
            };

            data.participant.error = errors[data.error] || data.error;
            return data.participant;
          });

          $mdDialog.show({
            templateUrl: `admin/activity/import/import.html`,
            controller: 'Activity.ImportCtrl',
            controllerAs: '$ctrl',
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: { participants },
          });
        })
        .catch((err) => {
          if (err.data && err.data.message === 'activity_speaker_pkey') {
            return Toast.show('Este palestrante já foi cadastrado', 'error');
          }

          if (err.data && err.data.message === 'import-list-error') {
            return Toast.show(
              'Nem todos os dados foram salvos, verifique o arquivo de erros!',
              'error'
            );
          }

          return Toast.httpHandler(err);
        });
    };

    this.confirmDelete = (id) => {
      this.idDelete = id;
      $mdDialog
        .show({
          templateUrl: `admin/findRetailerBussinesClub/confirmDelete/confirmDelete.html`,
          controller: 'FindRetailerBussinesClub.ConfirmCtrl',
          controllerAs: '$ctrl',
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: false,
          locals: {
            id: id,
          },
        })
        .then(() => {
          this.activity.restricted_list = this.activity.restricted_list.filter(
            (participant) => {
              if (participant.id !== id) return participant;
            }
          );
        });
    };

    this.listRestrictedListBussines();
  }
})(angular || {});
