((angular) => {
  "use strict";

  angular.module("Participant").directive("participantWizardModalButtonsOutput", ["$rootScope", Directive]);

  function Directive($rootScope) {

    return {
      scope: false,
      replace: true,
      link: ($scope, elem) => {
        $rootScope.$on("change-participant-wizard-modal-buttons", (info, data) => {
          elem.html(data);
        });
      }
    };

  }

})(angular);
