(angular => {
  "use strict";


  angular.module("EventCompanyEmployeeCredential").controller("EventCompanyEmployeeCredential.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyService",
    "EventCompanyEmployeeService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, eventService, EventCompanyService, eventCompanyEmployeeService) {
    let queryTimeout = null;
    this.promise = null;
    this.exhibitors = [];
    this.pagination = { page: 1, limit: 10 };

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      this.promise = EventCompanyService.listExhibitors().then(exhibitors => {
        this.exhibitors = exhibitors.map(e => {
          e.totalCredentials = (e.employees || []).reduce((acc, i) => acc + (i.credential_amount || 0), 0);
          e.usedCredentials = (e.employees || []).reduce((acc, i) => acc + (i.credentials || []).length, 0);
          return e;
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.exportTickets = () => {
      eventCompanyEmployeeService.exportList();
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.load(), 500);
    }, true);

  }
})(angular || {});
