(angular => {
  "use strict";

  angular.module("EventCompany").factory("EventCompanyService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "Toast",
    EventCompanyService
  ]);

  function EventCompanyService($q, $http, $mdDialog, API, Toast) {

    const list = (params) => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/event/companies`,
        params: params || this.params,
      }).then(res => res.data);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventCompany/form/form.html`,
        controller: "EventCompany.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        },
        targetEventCompany: $event
      });
    };

    const save = eventCompany => {
      return $http.post(`${API}/event/companies`, eventCompany)
        .then(res => res.data);
    };

    const remove = (eventCompanyId) => {
      return $http.delete(`${API}/event/companies/${eventCompanyId}`)
        .then(res => res.data);
    };

    const get = eventCompanyId => {
      return $http.get(`${API}/event/companies/${eventCompanyId}`).then(res => res.data);
    };

    const getV2 = eventCompanyId => {
      return $http.get(`${API}/event/companies/v2/${eventCompanyId}`).then(res => res.data);
    };

    const validations = (eventCompany, event) => {
      let hasParicipants;
      eventCompany.rooms.forEach(r => {
        if (r.persons.length) {
          hasParicipants = 0;
        }
      });
      if (eventCompany.air_travel.length) {
        hasParicipants = 1;
      }

      eventCompany.company.participant.forEach(c => {
        const hasPremium = c.activities.filter(a => a.type === 'premium');
        if (hasPremium.length) {
          hasParicipants = 2;
        }
      });
      switch (hasParicipants) {
        case 0:
          Toast.show("Esta empresa possui participantes cadastrados: Hospedagem", "error");
          break;
        case 1:
          Toast.show("Esta empresa possui participantes cadastrados: Passagens aéreas", "error");
          break;
        case 2:
          Toast.show(`Esta empresa possui participantes cadastrados: ${event.name_activity_premium}`, "error");
          break;
      }
      if (hasParicipants) {
        return true;
      }
    };

    const listExhibitors = () => {
      return $http.get(`${API}/event/exhibitors`).then(res => res.data);
    };

    const getExhibitor = (exhibitorId) => {
      return $http.get(`${API}/event/exhibitors/get/${exhibitorId}`).then(res => res.data);
    };

    const saveExhibitor = eventCompany => {
      return $http.post(`${API}/event/exhibitors`, eventCompany)
        .then(res => res.data);
    };

    const removeExhibitor = (eventExhibitorsId) => {
      return $http.delete(`${API}/event/exhibitors/${eventExhibitorsId}`)
        .then(res => res.data);
    };

    const formExhibitor = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventCompany/formExhibitors/form.html`,
        controller: "EventCompany.FormExhibitorsCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        },
        targetEventCompany: $event
      });
    };

    return {
      list,
      listExhibitors,
      form,
      formExhibitor,
      save,
      saveExhibitor,
      remove,
      removeExhibitor,
      get,
      getV2,
      validations,
      getExhibitor
    };
  }
})(angular);
