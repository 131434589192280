(angular => {
  "use strict";

  angular.module("EventAccess").controller("EventAccess.AllowedCtrl", [
    "$mdDialog",
    "access",
    "EventAccessService",
    AllowedCtrl
  ]);

  function AllowedCtrl($mdDialog, access, eventAccessService) {

    this.access = access;
    this.participants = [];

    eventAccessService.listAllowed(access.activityId)
      .then(participants => this.participants = participants);

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
