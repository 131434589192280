(angular => {
  "use strict";

  angular.module("User")
    .controller("User.FormCtrl", ["$mdDialog", "Loader", "Toast", "UserService", "user", "company", "role", FormCtrl]);

  function FormCtrl($mdDialog, Loader, Toast, UserService, user, company, role) {
    this.isEditing = !!user;
    this.company = company || {};
    this.user = user || (company ? { role: role } : { role: "user" });
    this.roles = ['admin', 'user', 'company_manager', 'organizer', 'stand_builder', 'coordinator'];

    this.save = () => {
      this.error = "";
      this.user.company_id = this.company.id;
      return Loader.add(UserService.save(this.user)).then(() => {
        Toast.show("Usuário salvo com sucesso", "success");
        $mdDialog.hide(this.user);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
