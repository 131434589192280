(angular => {
  "use strict";

  angular.module("EventTax").controller("EventTax.OrderHistoryCtrl", [
    "$mdDialog",
    "order",
    OrderHistoryCtrl
  ]);

  function OrderHistoryCtrl($mdDialog, order) {
    this.order = order;

    this.hide = $mdDialog.hide;

  }
})(angular || {});
