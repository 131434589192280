(angular => {
  "use strict";

  angular.module("App").component("generalGraph", {
    templateUrl: 'admin/app/dashboard/components/general.html',
    controller: [
      '$q',
      '$scope',
      '$timeout',
      'Loader',
      'EventService',
      'ParticipantService',
      'CompanyService',
      Controller
    ],
    bindings: {
      type: "@",
      onReady: "&"
    }
  });

  function Controller($q, $scope, $timeout, loader, eventService, participantService, companyService) {
    let queryTimeout, fullData = [];
    this.loading = true;
    this.hasData = false;
    this.orderBy = "name";
    this.companyTypes = companyService.listTypes();

    eventService.current().then(event => {
      this.event = event;
      this.selectedCompanyTypes = this.companyTypes.filter(companyType => {
        if (this.event.title.includes('Future')) return companyType !== "organizer";
        return companyType !== "commercial partner" && companyType !== "organizer";
      });
    });

    if (this.type === 'before') {
      this.keys = [{
        id: _.uniqueId(),
        type: 'registered',
        display: 'Inscritos',
        filter: p => p
      }, {
        id: _.uniqueId(),
        type: 'confirmed',
        display: 'Confirmados',
        filter: p => p.status === "accepted"
      }, {
        id: _.uniqueId(),
        type: 'notConfirmed',
        display: 'Não confirmados',
        filter: p => p.status === 'invited' || p.status === 'not invited'
      }];
    }

    if (this.type === 'after') {
      this.keys = [{
        id: _.uniqueId(),
        type: 'registered',
        display: 'Inscritos',
        filter: p => p
      }, {
        id: _.uniqueId(),
        type: 'notPresent',
        display: 'Ausentes',
        filter: p => p.status === "not present"
      }, {
        id: _.uniqueId(),
        type: 'present',
        display: 'Presentes',
        filter: p => p.status === "present"
      }, {
        id: _.uniqueId(),
        type: 'present at time',
        display: 'Presentes na hora',
        filter: p => p.status === "present at time"
      }, {
        id: _.uniqueId(),
        type: 'Total presentes',
        display: 'Total presentes',
        filter: p => p.status === "present at time" || p.status === "present"
      }];
    }


    this.$onInit = () => {

      participantService.listWithDependents().then((participants) => {
        this.loading = false;
        this.hasData = participants.length;

        if (!this.hasData) {
          return;
        }

        this.tableResult = fullData = this.formatData(participants);
        this.hasData = !!this.tableResult.length;
        this.calculate();
      }).then(() => this.onReady && this.onReady());
    };

    this.calculate = () => {
      this.filterCompanies = false;
      this.total = {};

      this.keys.forEach(key => {
        this.total[key.type] = this.tableResult.reduce((prev, t) => prev + t[key.type], 0);
      });

      $timeout(() => this.filterCompanies = true);
    };

    this.formatData = (participants) => {
      return participants
        .filter(p => p.company)
        .reduce((acc, p) => {
          let company = acc.find(c => c.id === p.company.id);

          if (!company) {
            company = p.company;
            company.participants = [];
            acc.push(company);
          }

          company.participants.push(p);
          delete p.company;

          return acc;
        }, []).map(company => {
          const data = { name: company.name, type: company.type };

          this.keys.forEach(key => {
            data[key.type] = company.participants.filter(key.filter).length;
          });
          return data;
        });
    };

    $scope.$watchGroup(['$ctrl.selectedCompanyTypes'], () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        this.tableResult = fullData.filter(t => this.selectedCompanyTypes.indexOf(t.type) > -1);
        this.calculate();
      }, 500);
    });

  }

})(angular);
