(angular => {
  "use strict";

  angular.module("EventCompanyEmployee").controller("EventCompanyEmployee.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyEmployeeService",
    "ParticipantService",
    "EventCompanyService",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, $scope, $timeout, Loader, Toast, eventService, eventCompanyEmployeeService, participantService, eventCompanyService) {
    let queryTimeout;
    this.eventCompany = null;
    this.tickets = [];
    this.person = {};

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadParticipants = () => {
      eventCompanyService.getExhibitor($routeParams.exhibitorId).then(exhibitor => {
        this.exhibitor = exhibitor;
      }).catch(err => Toast.httpHandler(err));
      eventCompanyEmployeeService.listByCompany($routeParams.exhibitorId).then(users => {
        this.usersProvider = users;
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, model) => {
      eventCompanyEmployeeService.form($event, model, this.exhibitor.id).then(() => {
        this.loadParticipants();
      });
    };

    this.remove = (user) => {
      eventCompanyEmployeeService.remove(user.id).then(() => {
        Toast.show(`Prestador removido com sucesso`, "success");
        this.loadParticipants();
      }).catch(err => Toast.httpHandler(err));
    };

    this.resend = (userId) => {
      eventCompanyEmployeeService.resendInvite(userId).then(() => {
        Toast.show(`Nova credencial enviada com sucesso`, "success");
        this.loadParticipants();
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadParticipants(), 500);
    }, true);

  }
})(angular || {});
