(angular => {
  "use strict";

  angular.module("Activity")
    .directive("activityCreate", ["$mdDialog", ActivityCreate]);

  function ActivityCreate($mdDialog) {
    return {
      restrict: "A",
      scope: {
        activity: "=activityCreate",
        event: "=event",
        date: "=date",
        onDone: "&onDone"
      },
      link: (scope, element) => {
        element.on("click", ($activity) => {
          $mdDialog.show({
            templateUrl: `admin/activity/form/form.html`,
            controller: "Activity.FormCtrl",
            controllerAs: "$ctrl",
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
              activity: angular.copy(scope.activity),
              event: angular.copy(scope.event),
              date: angular.copy(scope.date)
            },
            targetActivity: $activity,
          }).then(() => {
            const callback = scope.onDone();
            if (callback) {
              callback();
            }
          });

        });
      }
    };
  }

})(angular);
