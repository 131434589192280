(angular => {

  angular.module("Event")

    .filter("statusImport", [statusImport]);

  function statusImport() {

    const types = {
      "finished": "Finalizado",
      "processing": "Processando",
      "waiting": "Em espera"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
