(angular => {

  angular.module("Company", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/company", {
        controller: "Company.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/company/list/list.html`,
        role: ['admin', 'user', 'company_manager', 'organizer', 'coordinator'],
        allowWithoutEvent: true
      })
      .when("/company/:idCompany", {
        controller: "Company.DetailsCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/company/details/details.html`,
        role: ['admin', 'company_manager', 'organizer', 'coordinator'],
        allowWithoutEvent: true
      });
  }

})(angular);
