(angular => {
  "use strict";

  angular.module("Activity")
    .controller("Activity.FormQuestionCtrl", ["$mdDialog", "Toast", "Loader", "SpeakerService", "ActivityService", "activity", FormQuestionCtrl]);

  function FormQuestionCtrl($mdDialog, Toast, Loader, SpeakerService, ActivityService, activity) {
    this.isEditing = !!activity;
    this.question = activity.question || { choices: [], answers: [], description: "" };

    this.canChange = () => this.question.answers.length === 0;

    this.addChoice = description => {
      if (!description) {
        return Toast.show('Resposta não pode estar vazia', 'error');
      }
      this.choice = "";
      this.question.choices.push({ description });
    };

    this.removeChoice = $index => this.question.choices.splice($index, 1);


    this.save = () => {
      if (this.question.choices.length < 2) {
        return Toast.show('Enquete deve conter no minimo 2 respostas', 'error');
      }

      this.question.activity_id = activity.id;
      Loader.add(ActivityService.saveQuestion(this.question)).then(() => {
        Toast.show("Enquete criada com sucesso", "success");
        $mdDialog.hide(this.question);
      }).catch(err => {
        if (err && err.data && err.data.message === 'question-answered') {
          return Toast.show('Enquete já possui resposta', 'error');
        }
        Toast.httpHandler(err);
      });
    };

    this.removeQuestion = () => {
      if (!this.question.id) return this.question = { choices: [], answers: [], description: "" };

      Loader.add(ActivityService.removeQuestion(this.question.id)).then(() => {
        this.question = { choices: [], answers: [], description: "" };
        Toast.show("Enquete removida com sucesso", "success");
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = () => {
      $mdDialog.hide();
    };

    this.keyPressEnter = ($event) => {
      if (Number($event.charCode) === 13) {
        this.addChoice(this.choice);
        $event.preventDefault();
      }
    };
  }
})(angular || {});
