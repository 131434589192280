(angular => {
  "use strict";

  angular.module("EventCost").controller("EventCost.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "$scope",
    "$mdpDatePicker",
    "EventCostService",
    "model",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, loader, toast, $scope, $mdpDatePicker, eventCostService, model) {
    this.model = model || {};
    // this.pagination = { page: 1, limit: 3 };
    this.mountParcelNumber = true;
    this.mountFinalPrice = true;
    this.files = [];

    if (model && model.files.length > 0) {
      model.files.forEach(f => this.files.push(f));
    }

    if (model) {
      if (model.parcels) {
        this.model.parcels = model.parcels;
        if (model.parcels) this.model.isParcel = true;
        this.model.parcelsNumber = model.parcels.length;
      }
    }

    this.save = () => {
      const data = angular.copy(this.model);
      data.files = this.files.filter(f => f.file !== "");
      data.parcels.map(parcel => {
        if (parcel.pay_day === "") parcel.pay_day = null;
      });

      const notDueDay = data.parcels.some(p => !p.due_day);
      if (notDueDay) {
        toast.show("Parcela sem data de vencimento", "error");
        return;
      }
      delete data.parcelsNumber;
      delete data.isParcel;
      return loader.add(eventCostService.save(data)).then(() => {
        $mdDialog.hide(data);
      });
    };

    $scope.$watch("$ctrl.model.isParcel", value => {
      if (!value) {
        this.model.parcelsNumber = 1;
      }
    }, true);

    $scope.$watch("$ctrl.model.parcelsNumber", () => {
      if (this.mountParcelNumber) {
        this.mountParcelNumber = false;
        return;
      }
      this.mountParcels();

    }, true);

    $scope.$watch("$ctrl.model.final_price", () => {
      if (this.mountFinalPrice) {
        this.mountFinalPrice = false;
        return;
      }
      this.mountParcels();

    }, true);


    this.mountParcels = () => {
      const parcelValue = this.model.final_price / this.model.parcelsNumber;

      if (!this.model.parcels) this.model.parcels = [];
      if (this.model.parcelsNumber) {
        this.model.parcels = Array(Number(this.model.parcelsNumber)).fill(this.model.parcelsNumber).map((parcel, index) =>
          ({ parcel_number: index + 1, value: parcelValue }));
      }
    };

    this.setFile = result => {
      let file = {
        filename: result.filename,
        base64: result.base64
      };
      this.files.push({ filename: result.filename, file: file });
    };

    this.removeFile = (archive) => {
      this.files = this.files.filter(f => f.file !== archive.file);
    };

    this.choicePayDate = ($index, $event) => {
      $mdpDatePicker(new Date(), { targetEvent: $event }).then(selectedDate => {
        $index.due_day = selectedDate;
        this.model.parcelsNumber = this.model.parcelsNumber;
        toast.show("Data escolhida com sucesso", "success");
      }).catch(err => toast.httpHandler(err));
    };
    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
