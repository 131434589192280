(angular => {
  "use strict";

  angular.module("Activity").factory("ActivityService", [
    "$rootScope",
    "$q",
    "$mdDialog",
    "$http",
    "API",
    "XLSReader",
    "downloadjs",
    ActivityService
  ]);

  function ActivityService($rootScope, $q, $mdDialog, $http, API, XLSReader, download) {
    this.params = {};

    const listByDay = (params) => {
      return $http({
        method: "GET",
        url: `${API}/activity/`,
        params: params || this.params,
      }).then((res) => res.data);
    };

    const list = () => {
      return $http({
        method: "GET",
        url: `${API}/activity/`,
        params: this.params,
      }).then((res) => {
        const activities = [];

        res.data.forEach(activity => {
          activity.date = new Date(activity.date);
          activities.push(angular.copy(activity));
        });

        return activities;
      });
    };

    const save = (activity) => {
      return $http.post(`${API}/activity/`, activity)
        .then(res => res.data)
        .catch((err) => {
          if (err.data.message === 'import-list-error') {
            return exportList(err.data, activity.title);
          }
        });
    };

    const getById = (activityId) => {
      return $http.get(`${API}/activity/${activityId}`)
        .then(res => res.data);
    };

    const createQuestion = question => {
      return $http.post(`${API}/activity/question`, question)
        .then(res => res.data);
    };

    const publishQuestion = ({ id, published }) => {
      return $http.put(`${API}/activity/question/${id}/publish`, { published });
    };

    const saveQuestion = (question) => {
      if (question.id && question.answers && question.answers.length > 0) {
        return publishQuestion(question);
      }
      return createQuestion(question);
    };

    const getPremiumSpotsUsed = (eventCompanyId) => {
      return $http.get(`${API}/activity/premium-spots/${eventCompanyId}`).then(res => res.data);
    };

    const publishAppointmentBook = (publishAppointmentBook) => {
      return $http.post(`${API}/activity/publishAppointmentBook`, { publishAppointmentBook })
        .then(res => {
          $rootScope.$broadcast("event-changeactivity.type === selectedType", event);
          return res.data;
        });
    };

    const inviteRestrictedList = (activity) => {
      return $http.post(`${API}/activity/inviteRestrictedList`, activity)
        .then()
        .catch(error => {
          throw error;
        });
    };

    const inviteRestrictedListAll = (eventId, cpf) => {
      return $http.post(`${API}/activity/inviteRestrictedListAll`,  { eventId, cpf })
        .then()
        .catch(error => {
          throw error;
        });
    };

    const sendInviteActivity = (eventId, dateEventFind, action, cpf) => {
      return $http.post(`${API}/activity/sendInviteActivity`,  { eventId, dateEventFind, action, cpf })
        .then()
        .catch(error => {
          throw error;
        });
    };

    const sendConfirmationPostActivity = (eventId, dateEventFind, action, cpf) => {
      return $http.post(`https://abrafarma-api-v2.herokuapp.com/admin/activity-send/invite`,  { eventId, dateEventFind, action, cpf })
        .then()
        .catch(error => {
          throw error;
        });
    };

    const confirmPresence = (eventId, dateEventFind) => {
      return $http.post(`${API}/activity/confirmPresence`,  { eventId, dateEventFind})
        .then()
        .catch(error => {
          throw error;
        });
    };

    const addAttachment = (activity, filename, base64) => {
      return $http.post(`${API}/activity/${activity.id}/attachment`, { activity, filename, base64 })
        .then(res => res.data);
    };

    const removeAttachment = (activity, filePath) => {
      return $http.delete(`${API}/activity/${activity.id}/attachment/${filePath}`)
        .then(res => res.data);
    };

    const formImportList = () => {
      return XLSReader.loadFile('participant-restricted-list');
    };

    const importList = (data, eventId, userEmail) => {
      return $http.post(`${API}/activity/import-restricted-list`, { data, eventId, userEmail })
        .then(result => result.data);
    };

    const exportList = (err, title) => {
      return $http.post(`${API}/activity/exportErrorList`, {err, title}, {responseType: 'blob'})
        .then(res => {
          let header = res.headers('Content-Disposition');
          // eslint-disable-next-line no-useless-escape
          let fileName = header.split("=")[1].replace(/\"/gi, '');
          download(res.data, fileName, 'application/vnd.ms-excel');
          return err;
        });
    };

    const formSpeaker = () => {
      return $mdDialog.show({
        templateUrl: `admin/activity/formSpeaker/form-speaker.html`,
        controller: "Activity.FormSpeakerCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true
      });
    };

    const formQuestion = (activity) => {
      return $mdDialog.show({
        templateUrl: `admin/activity/formQuestion/form-question.html`,
        controller: "Activity.FormQuestionCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true,
        locals: {
          activity: angular.copy(activity)
        }
      });
    };

    const formSendInvite = (eventId) => {
      return $mdDialog.show({
        templateUrl: `admin/activity/formSendInvite/form-send-invite-all.html`,
        controller: "Activity.FormSendInviteAllCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true,
        locals: {
          eventId: angular.copy(eventId)
        }
      });
    };

    const formSendInviteActivity = (event) => {
      return $mdDialog.show({
        templateUrl: `admin/activity/formSendInviteActivity/form-send-invite-activity.html`,
        controller: "Activity.FormSendInviteActivityCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true,
        locals: {
          event: angular.copy(event)
        }
      });
    };

    const sendConfirmationActivity = (event) => {
      return $mdDialog.show({
        templateUrl: `admin/activity/send-confirmation/send-confirmation.html`,
        controller: "Activity.SendConfirmationCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true,
        locals: {
          event: angular.copy(event)
        }
      });
    };

    const formConfirmPresenceActivity = (event) => {
      return $mdDialog.show({
        templateUrl: `admin/activity/formConfirmPresence/form-confirm-presence.html`,
        controller: "Activity.FormConfirmPresenceCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        multiple: true,
        locals: {
          event: angular.copy(event)
        }
      });
    };

    const remove = (activity) => {
      return $http.delete(`${API}/activity/${activity.id}`);
    };

    const removeQuestion = (questionId) => {
      return $http.delete(`${API}/activity/question/${questionId}`)
        .then(res => res.data);
    };

    const findActivityByEvent = (eventId, eventCompanyId) => {
      return $http({
        method: "GET",
        url: `${API}/activity/findActivityByEvent`,
        params: (eventId, eventCompanyId),
      }).then((res) => {
        const activities = [];

        res.data.forEach(activity => {
          activity.date = new Date(activity.date);
          activities.push(angular.copy(activity));
        });

        return activities;
      });
    };

    const saveRestrictedList = (activity) => {
      return $http.post(`${API}/activity/saveRestricted`, activity)
        .then(res => res.data)
        .catch((err) => {
          if (err.data.message === 'import-list-error') {
            return exportList(err.data, activity.title);
          }
        });
    };

    const saveRestrictedListSession = (activity) => {
      return $http.post(`${API}/activity/saveRestrictedS`, activity)
        .then(res => res.data)
        .catch((err) => {
          if (err.data.message === 'import-list-error') {
            return exportList(err.data, activity.title);
          }
        });
    };

    const getCompanytypes = () => {
      return $http.get(`${API}/companyType`).then(res => res.data);
    };

    return {
      listByDay,
      list,
      remove,
      save,
      addAttachment,
      removeAttachment,
      publishAppointmentBook,
      getPremiumSpotsUsed,
      formImportList,
      importList,
      exportList,
      formSpeaker,
      formQuestion,
      saveQuestion,
      publishQuestion,
      removeQuestion,
      getById,
      inviteRestrictedList,
      inviteRestrictedListAll,
      formSendInvite,
      findActivityByEvent,
      saveRestrictedList,
      saveRestrictedListSession,
      formSendInviteActivity,
      sendInviteActivity,
      confirmPresence,
      formConfirmPresenceActivity,
      getCompanytypes,
      sendConfirmationActivity,
      sendConfirmationPostActivity
    };
  }
})(angular);
