(angular => {
  "use strict";

  angular.module("Contract").controller("Contract.ImportModalCtrl", [
    "$timeout",
    "$mdDialog",
    "Toast",
    "Loader",
    "$filter",
    "EventService",
    "ContractService",
    "benefits",
    "modelId",
    ImportModalCtrl
  ]);

  function ImportModalCtrl($timeout, $mdDialog, Toast, Loader, $filter, eventService, contractService, benefits, modelId) {
    this.benefits = benefits.map(b => {
      return {
        action: b.acao,
        black: b.black,
        gold: b.gold,
        green: b.green,
        platinum: b.platinum,
        blue: b.blue,
        item_value: b.valorDoItem,
        description: b.descricaoDoEvento,
        item_description: b.descricaoDoItem,
        modelId: modelId
      };
    });
    this.cancel = $mdDialog.cancel;
    this.close = $mdDialog.hide;
    this.save = () => {
      return Loader.add(contractService.importList(this.benefits)).then(() => {
        Toast.show("Benefícios importados com sucesso", "success");
        $mdDialog.hide();
      }).catch(err => Toast.httpHandler(err));
    };

  }
})(angular || {});
