(angular => {
  "use strict";

  angular.module("EventTax").controller("EventTax.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventTaxService",
    "model",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, loader, Toast, eventTaxService, model) {
    this.model = model || { type: 'common' };

    this.save = () => {
      return loader.add(eventTaxService.save(this.model)).then(() => {
        Toast.show(`Taxa ${model ? "editada" : "cadastrada" } com sucesso`, "success");
        $mdDialog.hide(this.model);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

    this.toCredential = () => this.model.tax_required = false;
    this.toRequired = () => this.model.type = 'common';
  }
})(angular || {});
