(angular => {
  "use strict";

  angular.module("Contract").controller("Contract.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "ContractService",
    "benefit",
    "modelId",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, $scope, $timeout, $mdDialog, Loader, Toast, contractService, benefit, modelId) {
    this.inCategory = (value, category) => {
      return !!(value & category);
    };

    this.classifications = [
      "pre-event",
      "post-event",
      "event",
      "registrations",
      "enrolled",
      "sponsored networks",
      "forum-de-alta-gestao",
      "magazine",
      "post-event-material",
      "pharmaceutical-assistance-portal",
      "portal-abrafarma-business-club",
      "today-in-the-press",
      "sales-thermometer",
      "reports",
      "fia-usp",
      "specials-reports",
      "participation-pack",
      "others",
    ];

    if (benefit) {
      const category = benefit.category;
      benefit.category = [];
      if (this.inCategory(1, category)) benefit.category.push(1);
      if (this.inCategory(2, category)) benefit.category.push(2);
      if (this.inCategory(4, category)) benefit.category.push(4);
      if (this.inCategory(8, category)) benefit.category.push(8);
      if (this.inCategory(16, category)) benefit.category.push(16);
    }

    this.model = benefit || {};
    this.model.model_id = modelId;
    this.addBenefit = () => {
      this.form.$setSubmitted(true);
      return Loader.add(contractService.saveBenefit(this.model)).then(() => {
        Toast.show("Benefício adicionado com sucesso", "success");
        this.form.$setSubmitted(false);
        this.form.$setPristine(true);
        this.model = {};
        $mdDialog.hide();

      }).catch(response => {
        Toast.httpHandler(response);
      });
    };

    this.cancel = () => {
      $mdDialog.hide();
    };
  }
})(angular || {});
