(angular => {
  "use strict";

  angular.module("EventGuest").controller("EventGuest.ListCtrl", [
    "$scope",
    "$mdDialog",
    "$timeout",
    "Loader",
    "Toast",
    "XLSReader",
    "EventGuestService",
    "EventService",
    "AuthService",
    ListCtrl
  ]);

  function ListCtrl($scope, $mdDialog, $timeout, Loader, Toast, XLSReader, eventGuestService, eventService, authService) {
    this.promise = null;
    let queryTimeout;
    this.user = authService.getTokenData();
    this.query = { orderBy: "-created_at" };
    this.pagination = { page: 1, limit: 10 };
    this.statuses = ['invited', 'accepted', 'refused', 'confirmed', 'subscription at time'];
    this.selected = [];

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      this.selected = [];
      const query = angular.copy(this.query);
      this.promise = eventGuestService.list(query).then(eventGuests => {
        this.eventGuests = eventGuests;

      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, model) => {
      eventGuestService.form($event, model).then(() => {
        this.load();
      });
    };

    this.approve = ($event, model) => {

      const approveGuests = model ? [model.id] : this.selected.map(g => g.id);
      return Loader.add(eventGuestService.approve(approveGuests)).then(() => {
        this.load();
        Toast.show("Convidado aprovado com sucesso", "success");
      }).catch(err => {
        if (err.data && err.data.messsage === 'no-guest-company') {
          return Toast.show('Nenhuma empresa com o nome Convidado cadastrada', 'error');
        }

        Toast.httpHandler(err);
      });

    };

    this.disapprove = ($event, model) => {
      eventGuestService.disapproveGuestForm(model, $event, this.selected).then(() => {
        this.load();
      });
    };

    this.remove = (guest) => {
      const removeGuests = guest ? [guest.id] : this.selected.map(g => g.id);
      Loader.add(eventGuestService.remove(removeGuests)).then(() => {
        this.load();
        Toast.show("Convidado removido com sucesso", "success");
      });
    };

    this.importList = ($event) => {
      eventGuestService.formImport($event).then(() => {
        this.load();
      });
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    this.resendInvite = (guest) => {
      const resendInviteIds = guest ? [guest.id] : this.selected.map(g => g.id);

      Loader.add(eventGuestService.resendInvite(resendInviteIds, this.event.id)).then(() => {
        this.load();
        Toast.show("Convites Reenviados com sucesso", "success");
      }).catch(err => Toast.httpHandler(err));
    };

    this.exportList = () => {
      eventGuestService.exportList(this.query.status || '');
    };

    this.cancel = $mdDialog.cancel;

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        this.load();
      }, 500);
    }, true);

  }
})(angular || {});
