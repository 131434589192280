(angular => {
  "use strict";

  angular.module("EventTax").controller("EventTax.IndexCtrl", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventService",
    "EventTaxService",
    "AuthService",
    IndexCtrl
  ]);

  function IndexCtrl($scope, $mdDialog, loader, toast, eventService, eventTaxService, authService) {
    eventService.current().then(event => this.event = event);
    this.user = authService.getTokenData();
    this.form = ($event, model) => {
      eventTaxService.form($event, model).then(() => {
        this.refreshTaxList();
      });
    };

    this.generateTaxs = (taxs) => {
      eventTaxService.generateTaxs(taxs).then(() => {
        toast.show('Taxas geradas com sucesso', 'success');
        this.refreshOrderList();
        $scope.$emit('change-header-tab-current', 1);
      }).catch(err => toast.httpHandler(err));
    };

    this.export = () => {
      eventTaxService.exportOrders();
    };

    this.formOrder = $event => {
      eventTaxService.formOrder($event).then(() => {
        this.refreshOrderList();
      });
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
