((angular) => {
  "use strict";

  angular.module("Participant").directive("participantWizardModalButtons", ["$compile", Directive]);

  function Directive($compile) {

    return {
      restrict: "E",
      scope: false,
      priority: 1,
      replace: false,
      terminal: true,
      compile: (tElement) => {
        const html = tElement.html();
        tElement.remove();

        return {
          pre: ($scope) => {
            $scope.$emit("change-participant-wizard-modal-buttons", $compile(`<div class="w-full" layout="row">${html}</div>`)($scope));
          }
        };
      }
    };

  }

})(angular);
