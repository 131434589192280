(angular => {
  "use strict";

  angular.module("Speaker").controller("Speaker.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "SpeakerService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, SpeakerService) {
    let queryTimeout = null;

    this.promise = null;
    this.query = {
      orderBy: "name",
      page: 1,
      limit: 25
    };

    this.speakers = null;

    this.loadData = () => {
      const query = angular.copy(this.query);
      this.promise = SpeakerService.list(query).then(speakers => {
        this.speakers = speakers;
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, speaker) => {
      SpeakerService.form($event, speaker).then(() => {
        this.loadData();
      });
    };

    this.remove = ($event, speaker) => {

      const errors = {
        'speaker-has-activity': 'Palestrante possui atividades'
      };

      Loader.add(SpeakerService.remove(speaker)).then(() => {
        Toast.show("Palestrante excluído com sucesso", "success");
        this.loadData();
        return speaker;
      }).catch(err => {

        if (errors[err.data && err.data.message]) {
          return Toast.show(errors[err.data.message], 'error');
        }
        Toast.httpHandler(err);
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadData(), 500);
    }, true);

  }

})(angular || {});
