(angular => {
  "use strict";

  angular.module("Speaker")
    .controller("Speaker.FormCtrl", ["$mdDialog", "Loader", "Toast", "speaker", "SpeakerService", FormCtrl]);

  function FormCtrl($mdDialog, Loader, Toast, speaker, SpeakerService) {
    this.isEditing = !!speaker;
    this.speaker = speaker || {};

    this.speaker.asset = { filename: this.speaker.avatar } || null;
    this.upload = (result) => {
      this.removeUpload();
      this.speaker.asset = { filename: result.filename, base64: result.base64 };
    };

    this.removeUpload = () => {
      this.speaker.asset = null;
    };

    this.hasFile = () => {
      return this.speaker.asset && this.speaker.asset.filename ? true : false;
    };

    this.getFileUrl = () => {
      const file = this.speaker.asset;

      if (!file) {
        return "/img/sem-imagem.jpg";
      }
      if (!file.filename) {
        return "/img/sem-imagem.jpg";
      }

      return file.base64 || `/content/${file.filename}`;
    };

    this.save = () => {
      this.error = "";

      return Loader.add(SpeakerService.save(this.speaker)).then(() => {
        Toast.show("Palestrante salvo com sucesso", "success");
        $mdDialog.hide(this.speaker);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
