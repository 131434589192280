(angular => {

  angular.module("EventCost", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/costs", {
        controller: "EventCost.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCost/view/index.html`,
        role: ['admin', 'user']
      })
      .when("/costs/:costId/", {
        controller: "EventCost.DetailsCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCost/view/details.html`,
        role: ['admin', 'user']
      });
  }

})(angular);
