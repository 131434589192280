(angular => {
  "use strict";

  angular.module("EventCompanyAction")

    .factory("EventCompanyActionService", ["$q", "$http", "$mdDialog", "API", "AuthService", EventCompanyActionService]);

  function EventCompanyActionService($q, $http, $mdDialog, API) {


    const list = () => {
      return $http.get(`${API}/event/actions`)
        .then(res => res.data);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventCompanyAction/form/form.html`,
        controller: "EventCompanyAction.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        event: $event,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const save = model => {
      return $http.post(`${API}/event/actions`, model).then(res => res.data);
    };

    const remove = (actionsId) => {
      return $http.delete(`${API}/event/actions/${actionsId}`);
    };


    return {
      list,
      save,
      remove,
      form
    };
  }
})(angular);
