(angular => {
  "use strict";

  angular.module("EventAirTravel").controller("EventAirTravel.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventAirTravelService",
    "AuthService",
    "ParticipantService",
    "EventCompanyService",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, $scope, $timeout, Loader, Toast, eventService, eventAirTravelService, authService, participantService, eventCompanyService) {
    let queryTimeout;
    this.eventCompany = null;
    this.tickets = [];
    this.user = authService.getTokenData();
    this.pagination = { page: 1, limit: 5 };

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadParticipants = () => {

      eventCompanyService.get($routeParams.companyId).then(eventCompany => {
        this.eventCompany = eventCompany;
        eventAirTravelService.list(this.eventCompany.id).then(activities => {
          this.tickets = activities;
        });
      }).catch(err => Toast.httpHandler(err));
    };


    this.removeTicket = (ticket) => {
      eventAirTravelService.remove(ticket.id).then(() => {
        Toast.show("Passagem removida com sucesso", "success");
        this.loadParticipants();
      });
    };

    this.save = (ticket) => {
      eventAirTravelService.formTicket(ticket, this.eventCompany).then(() => {
        this.loadParticipants();
      });
    };


    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadParticipants(), 500);
    }, true);

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };
  }
})(angular || {});
