(angular => {
  'use strict';

  angular.module('Event').controller('Event.ExportCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    '$mdDialog',
    'lodash',
    'Loader',
    'Toast',
    'EventService',
    'event',
    'FormRedirectService',
    'API',
    ExportCtrl
  ]);

  function ExportCtrl($scope, $filter, $timeout, $mdDialog, lodash, Loader, Toast, EventService, event, FormRedirectService, API) {
    this.event = event;
    this.eventDays = [];
    this.query = {};
    this.eventDays = EventService.getEventDays(event);
    this.dateEventFind = this.eventDays[0];

    this.exportTransmissionPlatform = () => {
      FormRedirectService
        .submit(`${API}/event/${this.dateEventFind}/exportTransmissionPlatform`, event);

        $mdDialog.hide();
        Toast.show('Planilha exportada com sucesso', 'success');
        this.loadData();
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
