(angular => {
  "use strict";

  angular.module("EventTableReservation").controller("EventTableReservation.FormTable", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventTableReservationService",
    "table",
    FormTable
  ]);

  function FormTable($scope, $mdDialog, loader, toast, EventTableReservationService, table) {
    this.model = table || {};
    this.submit = () => {

      loader.add(EventTableReservationService.saveTable(this.model)).then(result => {
        $mdDialog.hide(result);
      }).catch(err => {
        switch ((err.data || {}).message) {
          case 'less-spots':
            toast.show('Número de pessoas no quarto é maior que o permitido pelo tipo', 'error');
            break;
          default:
            toast.httpHandler(err);
        }
      });
    };

    this.cancel = $mdDialog.cancel;
  }

})(angular || {});
