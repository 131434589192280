(angular => {

  angular.module("EventQuestion")

    .filter("questionRequired", [QuestionRequiredFilter]);

  function QuestionRequiredFilter() {

    const types = {
      true: "Sim",
      false: "Não"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
