(angular => {
  "use strict";

  angular.module("EventAirTravel").factory("EventAirTravelService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "FormRedirectService",
    EventAirTravelService
  ]);

  function EventAirTravelService($q, $http, $mdDialog, API, FormRedirectService) {

    const list = (eventCompanyId) => {
      return $http.get(`${API}/event/air-travel/${eventCompanyId}`).then(res => res.data);
    };

    const save = airTravel => {
      return $http.post(`${API}/event/air-travel/`, airTravel).then(res => res.data);
    };

    const remove = (airTravelId) => {
      return $http.delete(`${API}/event/air-travel/${airTravelId}`);
    };

    const exportList = () => {
      return FormRedirectService.submit(`${API}/event/air-travel/export`);
    };

    const formTicket = (ticket, eventCompany) => {
      return $mdDialog.show({
        templateUrl: `admin/eventAirTravel/form/formTicket/formTicket.html`,
        controller: "EventAirTravel.FormTicketCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          ticket: angular.copy(ticket),
          eventCompany: angular.copy(eventCompany),
        }
      });
    };

    const validateAirport = (stretchs) => {
      return stretchs.every(s => {
        return !s.route_origin || !s.route_destination ? false : true;
      });
    };

    const airports = () => {
      return ([
        { "code": "AAR", "description": "Aarhus, Denmark - Tirstrup" },
        { "code": "ABJ", "description": "Abidjan, Cote D`Ivoire - Port Bouet" },
        { "code": "ABQ", "description": "Albuquerque, NM, USA - Albuquerque Intl" },
        { "code": "ABR", "description": "Aberdeen, SD, USA - Aberdeen Regional Airport" },
        { "code": "ABS", "description": "Abu Simbel, Egypt - Abu Simbel" },
        { "code": "ABY", "description": "Albany, GA, USA - Dougherty County" },
        { "code": "ABZ", "description": "Aberdeen, Scotland, United Kingdom - Dyce" },
        { "code": "ACA", "description": "Acapulco, Guerrero, Mexico - Alvarez International" },
        { "code": "ACC", "description": "Accra, Ghana - Kotoka" },
        { "code": "ACE", "description": "Lanzarote, Canary Islands, Spain - Lanzarote" },
        { "code": "ACY", "description": "Atlantic City /Atlantic Cty, NJ, USA - Atlantic City Intl" },
        { "code": "ADA", "description": "Adana, Turkey - Adana" },
        { "code": "ADB", "description": "Izmir, Turkey - Adnam Menderes" },
        { "code": "ADD", "description": "Addis Ababa, Ethiopia - Bole" },
        { "code": "ADJ", "description": "Amman, Jordan - Civil" },
        { "code": "ADL", "description": "Adelaide, South Australia, Australia - Adelaide" },
        { "code": "ADZ", "description": "San Andres Island, Colombia" },
        { "code": "AEP", "description": "Buenos Aires, Argentina - Jorge Newberry" },
        { "code": "AEY", "description": "Akureyri, Iceland - Akureyri" },
        { "code": "AGA", "description": "Agadir, Morocco - Inezgane" },
        { "code": "AGB", "description": "Augsburg, Germany - Muehlhausen" },
        { "code": "AGP", "description": "Malaga, Spain - Malaga" },
        { "code": "AGR", "description": "Agra, India - Kheria" },
        { "code": "AHO", "description": "Alghero, Sardenha, Itália" },
        { "code": "AJU", "description": "Aracaju, Sergipe, Brasil" },
        { "code": "AKL", "description": "Auckland, New Zealand - Auckland Intl Airport" },
        { "code": "ALA", "description": "Almaty, Kazakhstan - Almaty" },
        { "code": "ALG", "description": "Algiers, Algeria - Houari Boumedienne" },
        { "code": "ALY", "description": "Alexandria, Egypt - Alexandria" },
        { "code": "AMM", "description": "Amman, Jordan - Queen Alia International" },
        { "code": "AMS", "description": "Amsterdam, Netherlands - Schiphol" },
        { "code": "ANC", "description": "Anchorage, AK, USA - Anchorage International" },
        { "code": "ANF", "description": "Antofagasta, Chile - Cerro Moreno" },
        { "code": "ANU", "description": "Saint Johns / Antigua & Barbuda - Vc Bird Intl" },
        { "code": "APW", "description": "Apia, Samoa - Faleolo" },
        { "code": "AQP", "description": "Arequipa, Peru - Rodriguez Ballon" },
        { "code": "ARI", "description": "Arica, Chile - Chacalluta" },
        { "code": "ARN", "description": "Stockholm, Sweden - Arlanda International" },
        { "code": "ASD", "description": "Andros Town, Bahamas" },
        { "code": "ASE", "description": "Aspen, CO, USA - Pitkin County Airport Sardy Field" },
        { "code": "ASU", "description": "Asuncion, Paraguay - Silvio Pettirossi" },
        { "code": "ATH", "description": "Athens, Greece - Hellinikon" },
        { "code": "ATL", "description": "Atlanta, GA, USA - Hartsfield International" },
        { "code": "AUA", "description": "Aruba, Aruba - Reina Beatrix" },
        { "code": "AUH", "description": "Abu Dhabi, United Arab Emirates - Abu Dhabi Intl" },
        { "code": "AUS", "description": "Austin, TX, USA - Robert Mueller Municipal Airport" },
        { "code": "AUX", "description": "Araguaina, São Paulo, Brasil - Araguaina" },
        { "code": "AXA", "description": "Anguilla, Anguilla" },
        { "code": "AYT", "description": "Antalya, Turkey - Antalya" },
        { "code": "AZO", "description": "Kalamazoo, MI, USA - Kalamazoo/Battle Creek Intl" },
        { "code": "BAH", "description": "Bahrain, Bahrain - Bahrain Intl" },
        { "code": "BAK", "description": "Baku, Azerbaijan - Baku" },
        { "code": "BAQ", "description": "Barranquilla, Colombia - E Cortissoz" },
        { "code": "BAU", "description": "Bauru, São Paulo, Brasil - Aeroclube Estadual" },
        { "code": "BBU", "description": "Bucharest, Romania - Baneasa" },
        { "code": "BCN", "description": "Barcelona, Spain - Barcelona" },
        { "code": "BDL", "description": "Hartford, CT, USA - Bradley Intl Airport" },
        { "code": "BEG", "description": "Belgrade, Yugoslavia - Belgrade" },
        { "code": "BEL", "description": "Belem, Para, Brasil - Val De Cans" },
        { "code": "BEO", "description": "Newcastle, NSW, Australia - Belmont" },
        { "code": "BER", "description": "Berlin, Germany - Schoenefeld" },
        { "code": "BEY", "description": "Beirut, Lebanon - International" },
        { "code": "BFS", "description": "Belfast, Ireland, United Kingdom - Belfast Intl" },
        { "code": "BGF", "description": "Bangui, Central African Republic - Bangui" },
        { "code": "BGI", "description": "Bridgetown, Barbados - Grantley Adams Intl" },
        { "code": "BGO", "description": "Bergen, Norway - Flesland" },
        { "code": "BGR", "description": "Bangor, ME, USA - Bangor Intl Airport" },
        { "code": "BGY", "description": "Milan, Italy - Orio Al Serio" },
        { "code": "BHD", "description": "Belfast, Northern Ireland, UK - Belfast Harbor" },
        { "code": "BHI", "description": "Bahia Blanca, Argentina" },
        { "code": "BHX", "description": "Birmingham, England, UK - Intl" },
        { "code": "BIA", "description": "Bastia, Corsica, France - Poretta" },
        { "code": "BIK", "description": "Biak, Indonesia - Mokmer" },
        { "code": "BIM", "description": "Bimini, Bahamas - Bimini Island Intl" },
        { "code": "BIO", "description": "Bilbao, Spain - Sondica" },
        { "code": "BJL", "description": "Banjul, Gambia - Yundum International" },
        { "code": "BJX", "description": "Leon/Guanajuato, Mexico - Del Bajio" },
        { "code": "BKI", "description": "Kota Kinabalu, Sabah, Malaysia - Kota Kinabalu" },
        { "code": "BKK", "description": "Bangkok, Thailand - Bangkok Intl Airport" },
        { "code": "BKO", "description": "Bamako, Mali - Senou" },
        { "code": "BLL", "description": "Billund, Denmark - Billund" },
        { "code": "BLQ", "description": "Bologna, Italy - Guglielmo Marconi" },
        { "code": "BLR", "description": "Bangalore, India - Hindustan" },
        { "code": "BLZ", "description": "Blantyre, Malawi - Chileka" },
        { "code": "BMA", "description": "Stockholm, Sweden - Bromma Arpt" },
        { "code": "BNA", "description": "Nashville, TN, USA - Nashville Metropolitan" },
        { "code": "BNE", "description": "Brisbane, Queensland, Australia - Intl" },
        { "code": "BOG", "description": "Bogota, Colombia - Eldorado" },
        { "code": "BOM", "description": "Bombay, India - Bombay" },
        { "code": "BON", "description": "Bonaire, Netherlands Antilles - Flamingo Field" },
        { "code": "BOS", "description": "Boston, MA, USA - Logan International Airport" },
        { "code": "BPS", "description": "Porto Seguro, Bahia, Brasil" },
        { "code": "BRC", "description": "San Carlos De Bariloche, Argentina - Intl" },
        { "code": "BRE", "description": "Bremen, Germany - Bremen" },
        { "code": "BRI", "description": "Bari Palese, Puglia, Italia - Karol Wojtila" },
        { "code": "BRS", "description": "Bristol, England," },
        { "code": "BRU", "description": "Brussels, Belgium - National" },
        { "code": "BSB", "description": "Brasilia, Distrito Federal, Brasil - Intl" },
        { "code": "BUD", "description": "Budapest, Hungary - Ferihegy" },
        { "code": "BUQ", "description": "Bulawayo, Zimbabwe - Bulawayo" },
        { "code": "BUR", "description": "Burbank, CA, USA - Burbank Glendale Pasadena" },
        { "code": "BVA", "description": "Boa Vista, Cape Verde - Boa Vista" },
        { "code": "BVB", "description": "Boa Vista, Roraima, Brasil" },
        { "code": "BWI", "description": "Baltimore, MD, USA - Baltimore-Washington Intl" },
        { "code": "BWN", "description": "Bandar Seri Begawan, Brunei - Intl" },
        { "code": "BZE", "description": "Belize City, Belize - Belize International" },
        { "code": "CAG", "description": "Cagliari, Sardenha, Itália" },
        { "code": "CAI", "description": "Cairo, Egypt - International" },
        { "code": "CAN", "description": "Guangzhou, China - Baiyun" },
        { "code": "CAS", "description": "Casablanca, Morocco - Anfa" },
        { "code": "CAU", "description": "Caruarú, PE, Brasil - Oscar Laranjeiras" },
        { "code": "CAY", "description": "Cayenne, French Guiana - Rochambeau" },
        { "code": "CBB", "description": "Cochabamba, Bolivia - S. J. De La Banda" },
        { "code": "CBR", "description": "Canberra, Australia" },
        { "code": "CCM", "description": "Criciúma, Santa Catarina, Brasil" },
        { "code": "CCP", "description": "Concepcion, Chile - Carriel Sur" },
        { "code": "CCS", "description": "Caracas, Venezuela - Simon Bolivar Intl" },
        { "code": "CCU", "description": "Calcutta, India - Calcutta" },
        { "code": "CDG", "description": "Paris, France - Charles De Gaulle" },
        { "code": "CEB", "description": "Cebu, Philippines - International" },
        { "code": "CGB", "description": "Cuiabá, Mato Grosso, Brasil" },
        { "code": "CGH", "description": "São Paulo, Brasil - Congonhas" },
        { "code": "CGK", "description": "Jakarta, Indonesia - Soekarno Hatta Intl" },
        { "code": "CGN", "description": "Cologne/Bonn, Germany - Köln/Bonn" },
        { "code": "CGR", "description": "Campo Grande, MS, Brasil" },
        { "code": "CHC", "description": "Christchurch, New Zealand - Intl" },
        { "code": "CHM", "description": "Chimbote, Peru" },
        { "code": "CHS", "description": "Charleston, SC, USA - Charleston Il" },
        { "code": "CIA", "description": "Rome, Italy - Ciampino" },
        { "code": "CJU", "description": "Cheju, South Korea - Cheju" },
        { "code": "CKS", "description": "Carajás, Pará, Brasil" },
        { "code": "CKY", "description": "Conakry, Guinea - Conakry" },
        { "code": "CLE", "description": "Cleveland, OH, USA - Hopkins Intl" },
        { "code": "CLO", "description": "Cali, Colombia - Alfonso Bonilla Aragon" },
        { "code": "CLT", "description": "Charlotte, NC, USA - Charlotte/Douglas Intl" },
        { "code": "CMB", "description": "Colombo, Sri Lanka - Katunayake Intl" },
        { "code": "CMG", "description": "Corumbá, MS, Brasil" },
        { "code": "CMH", "description": "Columbus, OH, USA - Port Columbus Intl" },
        { "code": "CMN", "description": "Casablanca, Morocco - Mohamed V" },
        { "code": "CNF", "description": "Belo Horizonte, Brasil - Tancredo Neves" },
        { "code": "CNS", "description": "Cairns, Queensland, Australia" },
        { "code": "CNX", "description": "Chiang Mai, Thailand - Intl" },
        { "code": "COR", "description": "Cordoba, Argentina - Pajas Blancas" },
        { "code": "COS", "description": "Colorado Springs, CO, USA -Municipal" },
        { "code": "CPC", "description": "San Martin De Los Andes, Neuquen, Argentina" },
        { "code": "CPH", "description": "Copenhagen, Denmark -" },
        { "code": "VCP", "description": "Campinas, São Paulo, Brasil - Viracopos" },
        { "code": "CPT", "description": "Cape Town, South Africa - D F Malan" },
        { "code": "CPV", "description": "Campina Grande, Paraíba, Brasil" },
        { "code": "CRD", "description": "Comodoro Rivadavia, Chubut, Argentina -" },
        { "code": "CRU", "description": "Carriacou Island, Grenada" },
        { "code": "CTG", "description": "Cartagena, Colombia - Rafael Nunez" },
        { "code": "CTS", "description": "Sapporo, Japan - Chitose" },
        { "code": "CTU", "description": "Chengdu, China" },
        { "code": "CUC", "description": "Cucuta, Colombia - Camilo Daza" },
        { "code": "CUE", "description": "Cuenca, Ecuador - Mariscal La Mar" },
        { "code": "CUN", "description": "Cancun, Mexico" },
        { "code": "CUR", "description": "Willemstad / Curacao - Hato" },
        { "code": "CUU", "description": "Chihuahua, Mexico - Gen. Villalobos" },
        { "code": "CUZ", "description": "Cuzco, Peru - Tte Velazco Astete" },
        { "code": "CVG", "description": "Cincinnati, OH, USA - Greater Cincinnati Intl" },
        { "code": "CWB", "description": "Curitiba, Paraná, Brasil - Afonso Pena" },
        { "code": "CWL", "description": "Cardiff, Wales, United Kingdom -" },
        { "code": "CYB", "description": "Cayman Brac Island, Cayman Islands" },
        { "code": "CYR", "description": "Colonia, Uruguay" },
        { "code": "CZM", "description": "Cozumel, Quintana Roo, México" },
        { "code": "CZS", "description": "Cruzeiro do Sul, Acre, Brasil" },
        { "code": "DAB", "description": "Daytona Beach, FL, USA -" },
        { "code": "DAC", "description": "Dhaka, Bangladesh - Zia Inl" },
        { "code": "DAD", "description": "Da Nang, Vietnam - Da Nang" },
        { "code": "DAL", "description": "Dallas, TX, USA - Love Field" },
        { "code": "DAM", "description": "Damascus, Syria - Damascus Intl" },
        { "code": "DAR", "description": "Dar Es Salaam, Tanzania - Intl" },
        { "code": "DAY", "description": "Dayton, OH, USA - James M Cox Intel" },
        { "code": "DBV", "description": "Dubrovnik, Croatia - Hrvatska" },
        { "code": "DCA", "description": "Washington, DC, USA - Reagan / National" },
        { "code": "DEL", "description": "Delhi, India - Delhi Intl" },
        { "code": "DEN", "description": "Denver, CO, USA - Denver Intl" },
        { "code": "DFW", "description": "Dallas/Ft Worth, TX, USA - DFW Intl" },
        { "code": "DHA", "description": "Dhahran, Saudi Arabia - Intl" },
        { "code": "DIJ", "description": "Dijon, France - Longvic" },
        { "code": "DIL", "description": "Dili, Indonesia - Comoro" },
        { "code": "DKR", "description": "Dakar, Senegal - Yoff" },
        { "code": "DLA", "description": "Douala, Cameroon \\" },
        { "code": "DLC", "description": "Dalian, China" },
        { "code": "DME", "description": "Moscou, Russia - Domodedovo" },
        { "code": "DOH", "description": "Doha, Qatar - Doha" },
        { "code": "DOM", "description": "Dominica, Dominica - Melville Hal-Dom" },
        { "code": "DPS", "description": "Denpasar Bali, Indonesia - Ngurah Rai" },
        { "code": "DRS", "description": "Dresden, Germany - Dresden" },
        { "code": "DSM", "description": "Des Moines, IA, USA" },
        { "code": "DTM", "description": "Dortmund, Germany - Wickede" },
        { "code": "DTW", "description": "Detroit, MI, USA - Metropolitan" },
        { "code": "DUB", "description": "Dublin, Ireland - Dublin" },
        { "code": "DUR", "description": "Durban, South Africa - Louis Botha" },
        { "code": "DUS", "description": "Dusseldorf, Germany - Dusseldorf" },
        { "code": "DUT", "description": "Dutch Harbor, AK, USA -" },
        { "code": "DVO", "description": "Davao, Philippines - Mati" },
        { "code": "DXB", "description": "Dubai, United Arab Emirates - Intl" },
        { "code": "EBB", "description": "Entebbe/Kampala, Uganda" },
        { "code": "EDI", "description": "Edinburgh, Scotland, UK - Turnhouse" },
        { "code": "EIN", "description": "Eindhoven, Netherlands - Welschap" },
        { "code": "EIS", "description": "Tortola/Beef Island, UK Virgin Islands" },
        { "code": "ELH", "description": "North Eleuthera, Bahamas" },
        { "code": "ELP", "description": "El Paso, TX, USA - El Paso Intl" },
        { "code": "EMA", "description": "East Midlands, UK" },
        { "code": "EOH", "description": "Medellin, Colombia - Enrique Olaya Herrara" },
        { "code": "ERS", "description": "Windhoek, Namibia - Eros" },
        { "code": "ESB", "description": "Ankara, Turkey - Esenboga" },
        { "code": "ETH", "description": "Elat, Israel -" },
        { "code": "ETZ", "description": "Metz/Nancy, France - Frescaty" },
        { "code": "EVN", "description": "Yerevan, Armenia" },
        { "code": "EWR", "description": "Newark, NJ, USA - International" },
        { "code": "EXT", "description": "Exeter, England, UK" },
        { "code": "EYW", "description": "Key West, FL, USA" },
        { "code": "EZE", "description": "Buenos Aires, Argentina Min. Pistarini" },
        { "code": "FAE", "description": "Faroe Islands," },
        { "code": "FAI", "description": "Fairbanks, AK, USA - International" },
        { "code": "FAO", "description": "Faro, Portugal" },
        { "code": "FAR", "description": "Fargo, ND, USA - Hector" },
        { "code": "FAT", "description": "Fresno, CA, USA" },
        { "code": "FBU", "description": "Oslo, Norway - Fornebu" },
        { "code": "FCO", "description": "Rome, Italy - Leonardo Da Vinci/Fiumicino" },
        { "code": "FDF", "description": "Fort De France, Martinique" },
        { "code": "FDO", "description": "San Fernando, Argentina" },
        { "code": "FEZ", "description": "Fez, Morocco" },
        { "code": "FIH", "description": "Kinshasa, Zaire - Kinshasa" },
        { "code": "FLL", "description": "Ft Lauderdale, FL, USA - F. Laud. Hollywood Intl" },
        { "code": "FLN", "description": "Florianópolis, SC, Brasil" },
        { "code": "FLR", "description": "Florença, Italia" },
        { "code": "FMO", "description": "Münster, Germany" },
        { "code": "FNC", "description": "Funchal, Madeira, Portugal" },
        { "code": "FOR", "description": "Fortaleza, CE, Brasil" },
        { "code": "FPO", "description": "Freeport, Bahamas - Intl" },
        { "code": "FRA", "description": "Frankfurt, Germany - International" },
        { "code": "FUK", "description": "Fukuoka, Japan - Itazuke" },
        { "code": "GAJ", "description": "Yamagata, Japan - Junmachi" },
        { "code": "GBE", "description": "Gaborone, Botswana" },
        { "code": "GCI", "description": "Guernsey, Channel Islands, UK" },
        { "code": "GCM", "description": "Grand Cayman -Owen Roberts" },
        { "code": "GCN", "description": "Grand Canyon, AZ, USA" },
        { "code": "GDL", "description": "Guadalajara, Mexico - Miguel Hidalgo Intl" },
        { "code": "GDN", "description": "Gdansk, Poland - Rebiechowo" },
        { "code": "GDT", "description": "Grand Turk Is, Turks & Caicos" },
        { "code": "GDX", "description": "Magadan, Russia" },
        { "code": "GEN", "description": "Oslo, Norway - Gardermoen" },
        { "code": "GEO", "description": "Georgetown, Guyana - Timehri" },
        { "code": "GGT", "description": "George Town, Bahamas - Exuma" },
        { "code": "GHB", "description": "Governors Harbour, Bahamas" },
        { "code": "GIB", "description": "Gibraltar" },
        { "code": "GIG", "description": "RJ, Brasil - Tom Jobim Intl" },
        { "code": "GLA", "description": "Glasgow, Scotland, UK" },
        { "code": "GNB", "description": "Grenoble, France - St Geoirs" },
        { "code": "GND", "description": "St Georges/Grenada - Pt Saline" },
        { "code": "GOA", "description": "Genoa, Italy - Christoforo Colombo" },
        { "code": "GOT", "description": "Gothenburg, Sweden - Landvetter" },
        { "code": "GPS", "description": "Galapagos Islands, Ecuador - Baltra" },
        { "code": "GRO", "description": "Gerona, Spain - Costa Brava" },
        { "code": "GRQ", "description": "Groningen, Netherlands - Eelde" },
        { "code": "GRU", "description": "SP, Brasil - Guarulhos / F. Montoro" },
        { "code": "GRX", "description": "Granada, Spain" },
        { "code": "GRZ", "description": "Graz, Austria - Thalerhof" },
        { "code": "GUA", "description": "Guatemala City, Guatemala - La Aurora" },
        { "code": "GUM", "description": "Guam - Ab Wonpat Intl" },
        { "code": "GUZ", "description": "Guarapari, Espírito Santo, Brasil" },
        { "code": "GVA", "description": "Geneva, Switzerland" },
        { "code": "GXQ", "description": "Coyhaique, Chile - Teniente Vidal" },
        { "code": "GYE", "description": "Guayaquil, Ecuador - Simon Bolívar" },
        { "code": "GYN", "description": "Goiânia , GO, Brasil - Santa Genoveva" },
        { "code": "HAG", "description": "The Hague, Netherlands" },
        { "code": "HAH", "description": "Moroni (Hahaya), Comoros" },
        { "code": "HAJ", "description": "Hanover, Germany - Langenhagen" },
        { "code": "HAK", "description": "Haikou, China - Haikou" },
        { "code": "HAM", "description": "Hamburg, Germany - Fuhlsbuttel" },
        { "code": "HAN", "description": "Hanoi, Vietnam - Noibai Airport" },
        { "code": "HAV", "description": "Havana, Cuba - Jose Marti" },
        { "code": "HBA", "description": "Hobart, Tasmania, Australia" },
        { "code": "HDB", "description": "Heidelberg, Germany" },
        { "code": "HEL", "description": "Helsinki, Finland - Helsinki" },
        { "code": "HER", "description": "Heraklion, Crete Island, Greece" },
        { "code": "HIJ", "description": "Hiroshima, Japan" },
        { "code": "HIR", "description": "Honiara/Guadalcanal, Solomon" },
        { "code": "HIS", "description": "Hayman Island, Australia" },
        { "code": "HKD", "description": "Hakodate, Japan" },
        { "code": "HKG", "description": "Hong Kong, Hong Kong" },
        { "code": "HKT", "description": "Phuket, Thailand - Phuket" },
        { "code": "HMA", "description": "Malmo, Sweden" },
        { "code": "HNL", "description": "Honolulu, HI, USA - International" },
        { "code": "HOU", "description": "Houston, TX, USA - Hobby" },
        { "code": "HPV", "description": "Kauai Island, HI, USA - Princeville" },
        { "code": "HRB", "description": "Harbin, China" },
        { "code": "HRE", "description": "Harare, Zimbabwe" },
        { "code": "HRG", "description": "Hurghada, Egypt" },
        { "code": "HRK", "description": "Kharkov, Ukraine" },
        { "code": "HUN", "description": "Hualien, Taiwan" },
        { "code": "HUX", "description": "Huatulco, Oaxaca, Mexico" },
        { "code": "HYD", "description": "Hyderabad, India - Begumpet" },
        { "code": "IAD", "description": "Washington, DC, USA - Dulles" },
        { "code": "IAH", "description": "Houston, TX, USA - Intercontinental" },
        { "code": "IBZ", "description": "Ibiza, Spain - Ibiza" },
        { "code": "IEV", "description": "Kiev, Ukraine - Zhulhany" },
        { "code": "IGM", "description": "Kingman, AZ, USA - Mohave County" },
        { "code": "IGR", "description": "Iguazu, Argentina - International" },
        { "code": "IGU", "description": "Foz do Iguaçu, PR, Brasil - Cataratas" },
        { "code": "IKT", "description": "Irkutsk, Russia" },
        { "code": "IMP", "description": "Imperatriz, MA, Brasil" },
        { "code": "INN", "description": "Innsbruck, Austria - Kranebitten" },
        { "code": "INU", "description": "Nauru, Nauru" },
        { "code": "INV", "description": "Inverness, Scotland, UK" },
        { "code": "IOM", "description": "Isle Of Man, UK - Ronaldsway" },
        { "code": "IOS", "description": "Ilhéus, BA, Brasil" },
        { "code": "IPC", "description": "Ilha da Páscoa, Chile - Mataveri" },
        { "code": "IPH", "description": "Ipoh, Malaysia - Ipoh" },
        { "code": "IPI", "description": "Ipiales, Colombia - San Luis" },
        { "code": "IQQ", "description": "Iquique, Chile - Chucumata" },
        { "code": "IQT", "description": "Iquitos, Peru - Cf Secada" },
        { "code": "ISB", "description": "Islamabad, Pakistan - International" },
        { "code": "ISC", "description": "Isles Of Scilly, UK - Tresco" },
        { "code": "IST", "description": "Istanbul, Turkey - Ataturk" },
        { "code": "ITH", "description": "New York, USA - Ithaca Tompkins Regional Airport" },
        { "code": "ITM", "description": "Osaka, Japan - Itami International" },
        { "code": "ITO", "description": "Hilo, HI, USA - Hilo Hawaii" },
        { "code": "IZO", "description": "Izumo, Japan" },
        { "code": "JAI", "description": "Jaipur, India - Sanganeer" },
        { "code": "JAX", "description": "Jacksonville, FL, USA - International" },
        { "code": "JDF", "description": "Juíz de Fora, MG, Brasil" },
        { "code": "JDH", "description": "Jodhpur, India" },
        { "code": "JDO", "description": "Juazeiro do Norte, CE, Brasil - Orlando Bezerra de Menezes" },
        { "code": "JED", "description": "Jeddah, Saudi Arabia - International" },
        { "code": "JER", "description": "Jersey, Channel Islands, UK - States" },
        { "code": "JFK", "description": "New York, USA - John F Kennedy Intl" },
        { "code": "JIB", "description": "Djibouti, Djibouti - Ambouli" },
        { "code": "JKG", "description": "Jonkoping, Sweden - Axamo" },
        { "code": "JMK", "description": "Mikonos, Greece - Mikonos" },
        { "code": "JNB", "description": "Johannesburg, South Africa - Jan Smuts" },
        { "code": "JNX", "description": "Naxos, Greece" },
        { "code": "JOG", "description": "Yogyakarta, Indonesia" },
        { "code": "JOI", "description": "Joinville, SC, Brasil" },
        { "code": "JON", "description": "Johnston Island, US, Outlying Islands" },
        { "code": "JPA", "description": "João Pessoa, Paraíba" },
        { "code": "JRO", "description": "Kilimanjaro, Tanzania" },
        { "code": "JTC", "description": "Bauru-Arealva, SP, Brasil" },
        { "code": "JTR", "description": "Santorini, Greece" },
        { "code": "JUJ", "description": "Jujuy, Argentina - El Cadillal" },
        { "code": "JUL", "description": "Juliaca, Peru - Juliaca" },
        { "code": "KAN", "description": "Kano, Nigeria - Aminu Kano" },
        { "code": "KBP", "description": "Kiev, Ukraine - Borispol" },
        { "code": "KEF", "description": "Reykjavik, Iceland - Intl" },
        { "code": "KEL", "description": "Kiel, Germany - Holtenau" },
        { "code": "KGD", "description": "Kaliningrad, Russia" },
        { "code": "KHI", "description": "Karachi, Pakistan" },
        { "code": "KIN", "description": "Kingston, Jamaica" },
        { "code": "KIX", "description": "Osaka, Japan - Kansai Intl" },
        { "code": "KJA", "description": "Krasnojarsk, Russia" },
        { "code": "KLU", "description": "Klagenfurt, Austria - Klagenfurt" },
        { "code": "KMG", "description": "Kunming, China" },
        { "code": "KMI", "description": "Miyazaki, Japan" },
        { "code": "KMJ", "description": "Kumamoto, Japan" },
        { "code": "KOA", "description": "Kona, HI, USA - Keahole" },
        { "code": "KOJ", "description": "Kagoshima, Japan" },
        { "code": "KRK", "description": "Krakow, Poland - Balice" },
        { "code": "KRN", "description": "Kiruna, Sweden - Kiruna" },
        { "code": "KRP", "description": "Karup, Denmark - Karup" },
        { "code": "KRT", "description": "Khartoum, Sudan - Civil" },
        { "code": "KSA", "description": "Kosrae, Caroline Islands, Micronesia" },
        { "code": "KTM", "description": "Kathmandu, Nepal - Tribhuvan" },
        { "code": "KTW", "description": "Katowice, Poland - Pyrzowice" },
        { "code": "KUL", "description": "Kuala Lumpur, Malaysia - Subang" },
        { "code": "KUN", "description": "Kaunas, Lithuania - Kaunas" },
        { "code": "KUO", "description": "Kuopio, Finland - Kuopio" },
        { "code": "KUV", "description": "Kunsan, South Korea" },
        { "code": "KVA", "description": "Kavala, Greece - Kavala" },
        { "code": "KWA", "description": "Kwajalein, Marshall Islands" },
        { "code": "KWI", "description": "Kuwait, - International" },
        { "code": "KWL", "description": "Guilin, China" },
        { "code": "KZN", "description": "Kazan, Rússia" },
        { "code": "LAD", "description": "Luanda, Angola" },
        { "code": "LAS", "description": "Las Vegas, NV, USA - McCarran" },
        { "code": "LAX", "description": "Los Angeles, CA, USA - International" },
        { "code": "LBA", "description": "Leeds/Bradford, England, UK" },
        { "code": "LBV", "description": "Libreville, Gabon -" },
        { "code": "LCA", "description": "Larnaca, Cyprus - Intl" },
        { "code": "LCE", "description": "La Ceiba, Honduras - International" },
        { "code": "LCG", "description": "La Coruna, Spain" },
        { "code": "LCY", "description": "London, England, UK - London City" },
        { "code": "LDB", "description": "Londrina, PR, Brasil" },
        { "code": "LDE", "description": "Lourdes/Tarbes, France -" },
        { "code": "LEJ", "description": "Leipzig, Germany" },
        { "code": "LET", "description": "Leticia, Colombia - Gen Av Cobu" },
        { "code": "LFW", "description": "Lome, Togo" },
        { "code": "LGA", "description": "New York, NY, USA - La Guardia" },
        { "code": "LGB", "description": "Long Beach, CA, USA - Municipal" },
        { "code": "LGK", "description": "Langkawi, Malaysia" },
        { "code": "LGW", "description": "London, England, UK - Gatwick" },
        { "code": "LHE", "description": "Lahore, Pakistan - Lahore" },
        { "code": "LHR", "description": "London, England, UK - Heathrow" },
        { "code": "LIM", "description": "Lima, Peru - Jorge Chavez" },
        { "code": "LIN", "description": "Milan, Italy - Linate" },
        { "code": "LIS", "description": "Lisboa, Portugal - Portela de Sacavém" },
        { "code": "LJU", "description": "Ljubljana, Slovenia - Brnik" },
        { "code": "LLW", "description": "Lilongwe, Malawi - Kamuzu Intl" },
        { "code": "LNZ", "description": "Linz, Austria" },
        { "code": "LOS", "description": "Lagos, Nigeria - Murtala Muhammed" },
        { "code": "LPB", "description": "La Paz, Bolivia - El Alto" },
        { "code": "LPI", "description": "Linkoping, Sweden - Saab" },
        { "code": "LPL", "description": "Liverpool, England, UK" },
        { "code": "LRM", "description": "Casa De Campo, Dominican Republic" },
        { "code": "LSS", "description": "Terre-De-Haut, Guadeloupe" },
        { "code": "LTN", "description": "London, England, UK - Luton" },
        { "code": "LUG", "description": "Lugano, Switzerland - Agno" },
        { "code": "LUN", "description": "Lusaka, Zambia" },
        { "code": "LUX", "description": "Luxembourg - Findel" },
        { "code": "LVI", "description": "Livingstone, Zambia" },
        { "code": "LWO", "description": "Lvov, Ukraine - Snilow" },
        { "code": "LWY", "description": "Lawas, Sarawak, Malaysia" },
        { "code": "LXR", "description": "Luxor, Egypt" },
        { "code": "LYS", "description": "Lyon, France - Satolas" },
        { "code": "MAA", "description": "Madras, India - Meenambarkkam" },
        { "code": "MAD", "description": "Madrid, Spain - Barajas" },
        { "code": "MAH", "description": "Menorca, Spain" },
        { "code": "MAJ", "description": "Majuro, Marshall Islands - International" },
        { "code": "MAN", "description": "Manchester, England, UK" },
        { "code": "MAO", "description": "Manaus, AM, Brasil - Eduardo Gomes" },
        { "code": "MAR", "description": "Maracaibo, Venezuela - La Chinita" },
        { "code": "MBA", "description": "Mombasa, Kenya - Moi International" },
        { "code": "MBJ", "description": "Montego Bay, Jamaica - Sangster" },
        { "code": "MCI", "description": "Kansas City, MO, USA - International" },
        { "code": "MCM", "description": "Monte Carlo, Monaco - Heliporto" },
        { "code": "MOC", "description": "Montes Claros, MG, Brasil - Montes Claros" },
        { "code": "MCO", "description": "Orlando, FL, USA - International" },
        { "code": "MCP", "description": "Macapa, AP, Brasil" },
        { "code": "MCT", "description": "Muscat, Oman - Seeb" },
        { "code": "MCZ", "description": "Maceió AL, Brasil - Zumbi dos Palmares" },
        { "code": "MDC", "description": "Manado, Indonesia - Samratulang" },
        { "code": "MDE", "description": "Medellin, Colombia - La Playas" },
        { "code": "MDQ", "description": "Mar Del Plata, Argentina" },
        { "code": "MDW", "description": "Chicago, IL, USA" },
        { "code": "MDZ", "description": "Mendoza, Argentina - El Plumerillo" },
        { "code": "MED", "description": "Medinah, Saudi Arabia - Prince Mohammad Bin Abdulaziz" },
        { "code": "MEL", "description": "Melbourne, Australia - Tullamarine" },
        { "code": "MEM", "description": "Memphis, TN, USA -International" },
        { "code": "MES", "description": "Medan, Indonesia" },
        { "code": "MEX", "description": "Mexico City - Mexico -Benito Juarez" },
        { "code": "MGA", "description": "Managua, Nicaragua" },
        { "code": "MGF", "description": "PR, Aeroporto Regional Silvio Name Junior" },
        { "code": "MGQ", "description": "Mogadishu, Somalia" },
        { "code": "MIA", "description": "Miami, FL, USA - International" },
        { "code": "MLA", "description": "Malta - Luqa" },
        { "code": "MLE", "description": "Male, Maldives - Male International" },
        { "code": "MLH", "description": "Mulhouse, France" },
        { "code": "MMX", "description": "Malmo, Sweden - Sturup" },
        { "code": "MNI", "description": "Montserrat - Blackburne" },
        { "code": "MNL", "description": "Manila, Philippines - Ninoy Aquino" },
        { "code": "MPM", "description": "Maputo, Mozambique - International" },
        { "code": "MRS", "description": "Marseille, France - Marseille-Provence" },
        { "code": "MRU", "description": "Mauritius, - Plaisance" },
        { "code": "MSP", "description": "Minneapolis, MN, USA - MSP Intl" },
        { "code": "MSY", "description": "New Orleans, LA, USA - Moisant Intl" },
        { "code": "MTY", "description": "Monterrey, Mexico - Escobedo" },
        { "code": "MUB", "description": "Maun, Botswana" },
        { "code": "MUC", "description": "Munich, Germany - Franz Josef Strauss" },
        { "code": "MUN", "description": "Maturin, Venezuela" },
        { "code": "MVD", "description": "Montevideo, Uruguay - Carrasco" },
        { "code": "MVF", "description": "Mossoró, Rio Grande do Norte, Brasil - Dix-Sept Rosado" },
        { "code": "MXP", "description": "Milan, Italy - Malpensa" },
        { "code": "NAN", "description": "Nadi, Fiji - International" },
        { "code": "NAP", "description": "Naples, Italy - Capodichino" },
        { "code": "NAS", "description": "Nassau, Bahamas - International" },
        { "code": "NAT", "description": "Natal, RN, Brasil - Augusto Severo" },
        { "code": "NBO", "description": "Nairobi, Kenya - Jomo Kenyatta" },
        { "code": "NCA", "description": "North Caicos, Turks &Caicos" },
        { "code": "NCE", "description": "Nice, France - Cote D`Azur" },
        { "code": "NCL", "description": "Newcastle, England, UK" },
        { "code": "NCY", "description": "Annecy, France - Annecy-Meythe" },
        { "code": "NDJ", "description": "Ndjamena, Chad - N`djamena" },
        { "code": "NEV", "description": "Nevis, Saint Kitts And Nevis" },
        { "code": "NGO", "description": "Nagoya, Japan - Komaki" },
        { "code": "NGS", "description": "Nagasaki, Japan - Nagasaki" },
        { "code": "NKC", "description": "Nouakchott, Mauritania" },
        { "code": "NKG", "description": "Nanjing, China" },
        { "code": "NLA", "description": "Ndola, Zambia" },
        { "code": "NLD", "description": "Nuevo Laredo, Tamaulipas, Mexico" },
        { "code": "NLP", "description": "Nelspruit, South Africa" },
        { "code": "NOU", "description": "Noumea, New Caledonia - Tontouta" },
        { "code": "NRT", "description": "Tokyo, Japan - Narita" },
        { "code": "NSI", "description": "Yaounde, Cameroon - Nsimalen" },
        { "code": "NSN", "description": "Nelson, New Zealand - Nelson" },
        { "code": "NTE", "description": "Nantes, France -Chateau Bougon" },
        { "code": "NTL", "description": "Newcastle, Australia - Williamtown" },
        { "code": "NUE", "description": "Nuremberg, Germany - Nuremberg" },
        { "code": "NVT", "description": "Navegantes, SC, Brasil" },
        { "code": "OAK", "description": "Oakland, CA, USA - Metropolitan" },
        { "code": "OAX", "description": "Oaxaca, Oaxaca, Mexico - Xoxocotlan" },
        { "code": "ODE", "description": "Odense, Denmark" },
        { "code": "OKA", "description": "Okinawa, Ryukyu Islands, Japan" },
        { "code": "OKC", "description": "Oklahoma City, OK, USA - Will Rogers" },
        { "code": "OKJ", "description": "Okayama, Japan" },
        { "code": "OLB", "description": "Olbia-Costa Smeralda, Sardenha, Itália" },
        { "code": "ONT", "description": "Ontario, CA, USA - International" },
        { "code": "OPF", "description": "Miami, FL, USA - Opa Locka" },
        { "code": "OPO", "description": "Porto, Portugal" },
        { "code": "ORB", "description": "Orebro, Sweden" },
        { "code": "ORD", "description": "Chicago, IL, USA - O`Hare" },
        { "code": "ORF", "description": "Norfolk, VA, USA" },
        { "code": "ORK", "description": "Cork, Ireland" },
        { "code": "ORL", "description": "Orlando, FL, USA - Herndon" },
        { "code": "ORN", "description": "Oran, Algeria - Es Senia" },
        { "code": "ORY", "description": "Paris, France - Orly" },
        { "code": "OSH", "description": "Oshkosh, WI, USA - Wittman Field" },
        { "code": "OTP", "description": "Bucharest, Romania - Otopeni" },
        { "code": "OUA", "description": "Ouagadougou, Burkina Faso" },
        { "code": "OUL", "description": "Oulu, Finland - Oulu" },
        { "code": "OVB", "description": "Novosibirsk, Russia - Tolmachevo" },
        { "code": "OVD", "description": "Asturias, Spain - Asturias" },
        { "code": "OXB", "description": "Bissau, Guinea-Bissau - Osvaldo Vieira" },
        { "code": "OZZ", "description": "Ouarzazate, Morocco" },
        { "code": "PAD", "description": "Paderborn, Germany" },
        { "code": "PAP", "description": "Port Au Prince, Haiti - Mais Gate" },
        { "code": "PAT", "description": "Patna, India - Patna" },
        { "code": "PAZ", "description": "Poza Rica, Veracruz, Mexico" },
        { "code": "PBC", "description": "Puebla, Puebla, Mexico" },
        { "code": "PBI", "description": "West Palm Beach, FL, USA" },
        { "code": "PBM", "description": "Paramaribo, Suriname - Zanderij" },
        { "code": "PDG", "description": "Padang, Indonesia - Tabing" },
        { "code": "PDL", "description": "Ponta Delgada, Açores, Portugal - Nordela" },
        { "code": "PDX", "description": "Portland, OR, USA - International" },
        { "code": "PEK", "description": "Beijing, China - Capital Airport" },
        { "code": "PEN", "description": "Penang, Malaysia - International" },
        { "code": "PER", "description": "Perth, Australia" },
        { "code": "PET", "description": "Pelotas, Rio Grande do Sul" },
        { "code": "PEW", "description": "Peshawar, Pakistan" },
        { "code": "PFB", "description": "Passo Fundo, Rio Grande do Sul" },
        { "code": "PFO", "description": "Paphos, Cyprus - International" },
        { "code": "PHL", "description": "Philadelphia, PA, USA - International" },
        { "code": "PHX", "description": "Phoenix, AZ, USA - Sky Harbor" },
        { "code": "PID", "description": "Nassau, Bahamas - Paradise Island" },
        { "code": "PIK", "description": "Glasgow, Scotland, UK - Prestwick" },
        { "code": "PIT", "description": "Pittsburgh, PA, USA - Greater Pit Intl" },
        { "code": "PLS", "description": "Providenciales, Turks And Caicos" },
        { "code": "PLU", "description": "Belo Horizonte MG, Brasil - Pampulha" },
        { "code": "PMC", "description": "Puerto Montt, Chile - Tepual" },
        { "code": "PMG", "description": "Ponta Porã, Mato Grosso do Sul, Brasil" },
        { "code": "PMI", "description": "Palma Mallorca, Spain" },
        { "code": "PMO", "description": "Palermo, Sicily, Italy - Punta Raisi" },
        { "code": "PMW", "description": "Palmas, TO, Brasil - Brigadeiro Lysias Rodrigues" },
        { "code": "PNH", "description": "Phnom Penh, Cambodia - Pochentong" },
        { "code": "PNI", "description": "Pohnpei, Caroline Islands, Micronesia" },
        { "code": "PNZ", "description": "Petrolina, PE, Brasil - Senador Nilo Coelho" },
        { "code": "POA", "description": "Porto Alegre, RS, Brasil - Salgado Filho" },
        { "code": "POG", "description": "Port Gentil, Gabon" },
        { "code": "POM", "description": "Port Moresby, Papua New Guinea - Jackson" },
        { "code": "POP", "description": "Puerto Plata, Dominican Republic - La Union" },
        { "code": "POS", "description": "Port Of Spain, Trinidad & Tobago - Piarco" },
        { "code": "PPG", "description": "Pago Pago, American Samoa" },
        { "code": "PPT", "description": "Papeete, French Polynesia - Faaa" },
        { "code": "PRG", "description": "Prague, Czech Republic - Ruzyne" },
        { "code": "PRI", "description": "Praslin Island, Seychelles" },
        { "code": "PSA", "description": "Pisa, Italy - G Galilei" },
        { "code": "PSZ", "description": "Puerto Suarez, Bolivia" },
        { "code": "PTF", "description": "Malololailai, Fiji - Malololailai" },
        { "code": "PTP", "description": "Pointe A Pitre, Guadeloupe - Le Raizet" },
        { "code": "PTY", "description": "Panama City, Panama - Tocumen Intl" },
        { "code": "PUJ", "description": "Punta Cana, Dominican Republic" },
        { "code": "PUQ", "description": "Punta Arenas, Chile - Presidente Ibanez" },
        { "code": "PUS", "description": "Pusan, South Korea - Kimhae" },
        { "code": "PVG", "description": "Shangai, China - Pudong" },
        { "code": "PVH", "description": "Porto Velho RO, Brasil Gov. Jorge Texeira" },
        { "code": "PVR", "description": "Puerto Vallarta, Mexico - Gustavo Diaz Ordaz" },
        { "code": "PXM", "description": "Puerto Escondido, Oaxaca, Mexico" },
        { "code": "PZO", "description": "Puerto Ordaz, Venezuela" },
        { "code": "QBF", "description": "Vail/Eagle, CO, USA" },
        { "code": "QDU", "description": "Duesseldorf, Germany - Train Station" },
        { "code": "QKB", "description": "Breckenridge, CO, USA" },
        { "code": "QKL", "description": "Cologne, Germany - Train Station" },
        { "code": "QNS", "description": "Canoas - ParkShopping" },
        { "code": "QRO", "description": "Queretaro, Queretaro, Mexico" },
        { "code": "QSY", "description": "Sydney, NSW, Austrália" },
        { "code": "RAB", "description": "Rabaul, Papua New Guinea - Lakunai" },
        { "code": "RAI", "description": "Praia, Ilha de Santiago, Cape Verde - Praia" },
        { "code": "RAK", "description": "Marrakech, Morocco - Menara" },
        { "code": "RAO", "description": "Ribeirão Preto, SP, Brasil - Leite Lopes" },
        { "code": "RAR", "description": "Rarotonga, Cook Islands" },
        { "code": "RBA", "description": "Rabat, Morocco - Sale" },
        { "code": "RBR", "description": "Rio Branco AC, Brasil - Plácido de Castro" },
        { "code": "RDU", "description": "Raleigh/Durham, NC, USA - Intl" },
        { "code": "REC", "description": "Recife, Pernambuco, Brasil - Guararapes / Gilberto Freyre" },
        { "code": "REG", "description": "Reggio Calabria, Italy - Tito Menniti" },
        { "code": "REL", "description": "Trelew, Chubut, Argentina" },
        { "code": "RES", "description": "Resistencia, Chaco, Argentina" },
        { "code": "RGA", "description": "Rio Grande, Tierra Del Fuego, Argentina" },
        { "code": "RGL", "description": "Rio Gallegos, Santa Cruz, Argentina" },
        { "code": "RGN", "description": "Yangon, Myanmar - Mingaladon" },
        { "code": "RHO", "description": "Rhodes, Greece - Paradisi" },
        { "code": "RIC", "description": "Richmond, VA, USA" },
        { "code": "RIX", "description": "Riga, Latvia - Riga" },
        { "code": "RKV", "description": "Reykjavik, Iceland - Domestic" },
        { "code": "ROM", "description": "Rome, Italy - Leonardo Da Vinci / Fiumicino" },
        { "code": "ROP", "description": "Rota, Northern Mariana Islands - Rota" },
        { "code": "ROR", "description": "Koror, Palau - Airai" },
        { "code": "ROS", "description": "Rosario, Santa Fe, Argentina - Fisherton" },
        { "code": "ROV", "description": "Rostov, Russia" },
        { "code": "RTB", "description": "Roatan, Honduras - Roatan" },
        { "code": "RTM", "description": "Rotterdam, Netherlands" },
        { "code": "RUH", "description": "Riyadh, Saudi Arabia - King Khaled Intl" },
        { "code": "SAB", "description": "Saba Island, Netherlands Antilles" },
        { "code": "SAF", "description": "Santa Fe, NM, USA -" },
        { "code": "SAH", "description": "Sanaa, Yemen - International" },
        { "code": "SAL", "description": "San Salvador, El Salvador -" },
        { "code": "SAN", "description": "San Diego, CA, USA - Lindbergh Intl" },
        { "code": "SAP", "description": "San Pedro Sula, Honduras - La Mesa" },
        { "code": "SAT", "description": "San Antonio, TX, USA - International" },
        { "code": "SAV", "description": "Savannah, GA, USA - Travis Field" },
        { "code": "SBA", "description": "Santa Barbara, CA, USA" },
        { "code": "SBH", "description": "St Barthelemy, Guadeloupe" },
        { "code": "SCL", "description": "Santiago, Chile - Com. Arturo M. Benitez" },
        { "code": "SCN", "description": "Saarbruecken, Germany - Ensheim" },
        { "code": "SCQ", "description": "Santiago De Compostela, Spain" },
        { "code": "SCU", "description": "Santiago, Cuba - Antonio Maceo" },
        { "code": "SDJ", "description": "Sendai, Japan - Sendai" },
        { "code": "SDQ", "description": "Sto. Domingo, Rep. Dominicana - Las Americas" },
        { "code": "SDR", "description": "Santander, Spain - Santander" },
        { "code": "SDU", "description": "Rio De Janeiro, Brasil - Santos Dumont" },
        { "code": "SEA", "description": "Seattle, WA, USA - Seattle Tacoma Intl" },
        { "code": "SEL", "description": "Seoul, Korea - Kimpo International" },
        { "code": "SEZ", "description": "Mahe Island, Seychelles" },
        { "code": "SFA", "description": "Sfax, Tunisia - Sfax Airport" },
        { "code": "SFG", "description": "St Martin, Netherlands Antilles - Esperance" },
        { "code": "SFN", "description": "Santa Fe, Santa Fe, Argentina" },
        { "code": "SFO", "description": "San Francisco, CA, USA - Intl" },
        { "code": "SGN", "description": "Ho Chi Minh City, Vietnam - Tan Son Nhut" },
        { "code": "SHA", "description": "Shanghai, China - Intl /Hongqiao" },
        { "code": "SHJ", "description": "Sharjah, United Arab Emirates" },
        { "code": "SID", "description": "Sal, Cape Verde - Amilcar Cabral" },
        { "code": "SIN", "description": "Singapore, Singapore - Changi" },
        { "code": "SJC", "description": "San Jose, CA, USA - International" },
        { "code": "SJJ", "description": "Sarajevo, Bosnia Herzegowina - Butmir" },
        { "code": "SJK", "description": "São José dos Campos, São Paulo, Brasil" },
        { "code": "SJO", "description": "San Jose, Costa Rica - Juan Santamaria International" },
        { "code": "SJU", "description": "San Juan, PR, USA - Luis Munoz Marin" },
        { "code": "SJW", "description": "Shijiazhuang, China - Shijiazhuang" },
        { "code": "SKB", "description": "St Kitts, Saint Kitts And Nevis - Golden Rock" },
        { "code": "SKG", "description": "Thessaloniki, Greece" },
        { "code": "SKP", "description": "Skopje, Macedonia" },
        { "code": "SLA", "description": "Salta, Salta, Argentina" },
        { "code": "SLC", "description": "Salt Lake City, UT, USA" },
        { "code": "SLU", "description": "St Lucia, Saint Lucia - Vigie Field" },
        { "code": "SLZ", "description": "São Luiz, Maranhão, Brasil - Tirirical" },
        { "code": "SMF", "description": "Sacramento, CA, USA - Metropolitan" },
        { "code": "SNA", "description": "Santa Ana, CA, USA - John Wayne" },
        { "code": "SNN", "description": "Shannon, Ireland - Shannon" },
        { "code": "SOF", "description": "Sofia, Bulgaria - Sofia Intl" },
        { "code": "SPB", "description": "St Thomas Island, VI, USA - Seaplane Base" },
        { "code": "SPN", "description": "Saipan, Northern Mariana Islands" },
        { "code": "SPR", "description": "San Pedro, Belize" },
        { "code": "SRE", "description": "Sucre, Bolivia" },
        { "code": "SRQ", "description": "Sarasota/Bradenton, FL, USA" },
        { "code": "SSA", "description": "Salvador, Brasil - Dep. L. E. Magalhães" },
        { "code": "SSH", "description": "Sharm El Sheikh, Egypt - Ophira" },
        { "code": "STL", "description": "St Louis, MO, USA - Lambert-St Louis Intl" },
        { "code": "STM", "description": "Santarém, Pará, Brasil" },
        { "code": "STN", "description": "London, England, UK - Stansted" },
        { "code": "STR", "description": "Stuttgart, Germany - Echterdingen" },
        { "code": "STT", "description": "St Thomas Island, VI, USA - Cyril E King" },
        { "code": "STX", "description": "St Croix Island, VI, USA" },
        { "code": "SUB", "description": "Surabaya, Indonesia - Juanda Airport" },
        { "code": "SUV", "description": "Suva, Fiji - Nausori" },
        { "code": "SVD", "description": "St Vincent, St. Vincent And The Grenadines" },
        { "code": "SVG", "description": "Stavanger, Norway - Sola" },
        { "code": "SVO", "description": "Moscou, Russia - Sheremetyevo" },
        { "code": "SVQ", "description": "Sevilla, Spain" },
        { "code": "SVU", "description": "Savusavu, Fiji - Savusavu" },
        { "code": "SWP", "description": "Swakopmund, Namibia" },
        { "code": "SXB", "description": "Strasbourg, France - Entzheim" },
        { "code": "SXF", "description": "Berlin, Germany - Schoenefeld" },
        { "code": "SXM", "description": "St Maarten, Netherlands Antilles - Juliana" },
        { "code": "SYD", "description": "Sydney, Australia - Sydney /Kingsford-Smith" },
        { "code": "SZG", "description": "Salzburg, Austria - Salzburg" },
        { "code": "SZX", "description": "Shenzhen, China - Shenzhen" },
        { "code": "TAB", "description": "Tobago, Trinidad & Tobago - Crown Point" },
        { "code": "TAE", "description": "Taegu, South Korea" },
        { "code": "TAM", "description": "Tampico, Tamaulipas, Mexico" },
        { "code": "TAS", "description": "Tashkent, Uzbekistan" },
        { "code": "TBS", "description": "Tbilisi, Georgia - Novo Alexeyevka" },
        { "code": "TBT", "description": "Tabatinga, Amazonas, Brasil" },
        { "code": "TBU", "description": "Nuku Alofa/Tongatapu, Tonga" },
        { "code": "TFN", "description": "Tenerife, Canarias, Espanha - Los Rodeos" },
        { "code": "TFS", "description": "Tenerife, Canarias, Espanha - Reina Sofia" },
        { "code": "TGU", "description": "Tegucigalpa, Honduras - Toncontin" },
        { "code": "THE", "description": "Teresina, Piaui, Brasil" },
        { "code": "THF", "description": "Berlin, Germany - Tempelhof" },
        { "code": "THR", "description": "Tehran, Iran - Mehrabad" },
        { "code": "TIA", "description": "Tirana, Albania - Rinas" },
        { "code": "TIJ", "description": "Tijuana, Mexico - Gen. Abelardo L Rodriguez" },
        { "code": "TIQ", "description": "Tinian, Northern Mariana Islands" },
        { "code": "TKK", "description": "Truk, Caroline Islands, Micronesia" },
        { "code": "TKS", "description": "Tokushima, Japan" },
        { "code": "TKU", "description": "Turku, Finland" },
        { "code": "TLH", "description": "Tallahassee, FL, USA" },
        { "code": "TLL", "description": "Tallinn, Estonia - Ulemiste" },
        { "code": "TLS", "description": "Toulouse, France - Blagnac" },
        { "code": "TLV", "description": "Tel Aviv Yafo, Israel - Ben-Gurion" },
        { "code": "TNG", "description": "Tangier, Morocco - Boukhalef Souahel" },
        { "code": "TNR", "description": "Antananarivo, Madagascar - Ivato" },
        { "code": "TOL", "description": "Toledo, OH, USA" },
        { "code": "TOS", "description": "Tromso, Norway - Tromso/Langes" },
        { "code": "TOY", "description": "Toyama, Japan - Toyama" },
        { "code": "TPA", "description": "Tampa, FL, USA - Tampa Intl" },
        { "code": "TPE", "description": "Taipei, Taiwan - Chiang Kai Shek" },
        { "code": "TRC", "description": "Torreon, Coahuila, Mexico" },
        { "code": "TRD", "description": "Trondheim, Norway -" },
        { "code": "TRN", "description": "Turin, Italy - Caselle" },
        { "code": "TRS", "description": "Trieste, Italy - Ronchi Dei Legionari" },
        { "code": "TRU", "description": "Trujillo, Peru - Trujillo" },
        { "code": "TRV", "description": "Trivandrum, India - Trivandrum" },
        { "code": "TRZ", "description": "Tiruchirapally, India" },
        { "code": "TSA", "description": "Taipei, Taiwan - Sung Shan" },
        { "code": "TSR", "description": "Timisoara, Romania - Timisoara" },
        { "code": "TSS", "description": "New York, NY, USA - East 34Th Street Heliport" },
        { "code": "TTJ", "description": "Tottori, Japan - Tottori" },
        { "code": "TUC", "description": "Tucuman, Argentina - Benjamin Matienzo" },
        { "code": "TUF", "description": "Tours, France - St Symphorien" },
        { "code": "TUL", "description": "Tulsa, OK, USA" },
        { "code": "TUN", "description": "Tunis, Tunisia - Carthage" },
        { "code": "TUO", "description": "Taupo, New Zealand - Taupo" },
        { "code": "TUS", "description": "Tucson, AZ, USA - International" },
        { "code": "TXL", "description": "Berlin, Germany - Tegel" },
        { "code": "TZA", "description": "Belize City, Belize" },
        { "code": "TZN", "description": "South Andros, Bahamas - Congo Town" },
        { "code": "TZX", "description": "Trabzon, Turquia - Trabzon" },
        { "code": "UAK", "description": "Narsarsuaq, Greenland" },
        { "code": "UAQ", "description": "San Juan, San Juan, Argentina" },
        { "code": "UBJ", "description": "Ube, Japan" },
        { "code": "UDR", "description": "Udaipur, India" },
        { "code": "UIO", "description": "Quito, Ecuador - Mariscal" },
        { "code": "ULN", "description": "Ulan Bator, Mongolia - Ulan Bator" },
        { "code": "ULY", "description": "Ulyanovsk, Russia" },
        { "code": "UME", "description": "Umea, Sweden - Umea" },
        { "code": "URC", "description": "Urumqi, China" },
        { "code": "URT", "description": "Surat Thani, Thailand" },
        { "code": "USH", "description": "Ushuaia, Tierra Del Fuego, Argentina" },
        { "code": "USM", "description": "Koh Samui, Thailand" },
        { "code": "USN", "description": "Ulsan, South Korea" },
        { "code": "UTH", "description": "Udon Thani, Thailand" },
        { "code": "UVF", "description": "St Lucia, Saint Lucia - Hewanorra" },
        { "code": "VAA", "description": "Vaasa, Finland - Vaasa" },
        { "code": "VAR", "description": "Varna, Bulgaria - Varna" },
        { "code": "VAS", "description": "Sivas, Turkey - Sivas" },
        { "code": "VBY", "description": "Visby, Sweden - Visby" },
        { "code": "VCE", "description": "Venice, Italy - Marco Polo" },
        { "code": "VDM", "description": "Viedma Gobernador Castello, Argentina" },
        { "code": "VDZ", "description": "Valdez, AK, USA" },
        { "code": "VFA", "description": "Victoria Falls, Zimbabwe" },
        { "code": "VGO", "description": "Vigo, Spain" },
        { "code": "VIE", "description": "Vienna, Austria - Schwechat" },
        { "code": "VIJ", "description": "Virgin Gorda, Virgin Islands (British)" },
        { "code": "VIT", "description": "Vitoria, Spain" },
        { "code": "VIX", "description": "Vitoria, ES, Brasil - Eurico Sales" },
        { "code": "VKO", "description": "Moscou, Russia - Vnukovo" },
        { "code": "VLC", "description": "Valencia, Spain - Valencia" },
        { "code": "VLG", "description": "Villa Gesell, Buenos Aires, Argentina" },
        { "code": "VLI", "description": "Port Vila, Vanuatu - Bauerfield" },
        { "code": "VLL", "description": "Valladolid, Spain - Valladolid" },
        { "code": "VLN", "description": "Valencia, Venezuela - Valencia" },
        { "code": "VNO", "description": "Vilnius, Lithuania - Vilnius Airport" },
        { "code": "VNS", "description": "Varanasi, India - Babatpur" },
        { "code": "VOG", "description": "Volgograd, Russia - Volgograd" },
        { "code": "VRA", "description": "Varadero, Cuba - Juan Gualberto Gomez" },
        { "code": "VRB", "description": "Vero Beach, FL, USA - Municipal" },
        { "code": "VRN", "description": "Verona, Italy - Verona" },
        { "code": "VSA", "description": "Villahermosa, Mexico - Carlos R Perez" },
        { "code": "VTE", "description": "Vientiane, Laos - Wattay" },
        { "code": "VVI", "description": "Santa Cruz, Bolivia - Viru Viru" },
        { "code": "VVO", "description": "Vladivostok, Russia" },
        { "code": "VXE", "description": "São Vicente, Cape Verde - São Pedro" },
        { "code": "VXO", "description": "Vaxjo, Sweden" },
        { "code": "WAT", "description": "Waterford, Ireland" },
        { "code": "WAW", "description": "Warsaw, Poland - Okecie" },
        { "code": "WDH", "description": "Windhoek, Namibia - Jg Strijdom" },
        { "code": "WIL", "description": "Nairobi, Kenya - Wilson Arpt" },
        { "code": "WLG", "description": "Wellington, New Zealand" },
        { "code": "WRO", "description": "Wroclaw, Poland - Strachowice" },
        { "code": "XAP", "description": "Chapecó, Santa Catarina, Brasil" },
        { "code": "YAP", "description": "Yap, Caroline Islands, Micronesia" },
        { "code": "YBA", "description": "Banff, Alberta, Canada" },
        { "code": "YDF", "description": "Deer Lake, Newfoundland, Canada" },
        { "code": "YEC", "description": "Yechon, South Korea" },
        { "code": "YEG", "description": "Edmonton, Alberta, Canada - Intl" },
        { "code": "YHD", "description": "Dryden, Ontario, Canada" },
        { "code": "YHM", "description": "Hamilton, Ontario, Canada - Civic" },
        { "code": "YHZ", "description": "Halifax, Nova Scotia, Canada -" },
        { "code": "YKS", "description": "Yakutsk, Russia" },
        { "code": "YLW", "description": "Kelowna, British Columbia, Canada -" },
        { "code": "YMX", "description": "Montreal, Quebec, Canada" },
        { "code": "YOW", "description": "Ottawa, Ontario, Canada - Intl" },
        { "code": "YQB", "description": "Quebec, Canada - Sainte Foy Airport" },
        { "code": "YQG", "description": "Windsor, Ontario, Canada - Intl" },
        { "code": "YQR", "description": "Regina, Saskatchewan, Canada" },
        { "code": "YQX", "description": "Gander, Newfoundland, Canada" },
        { "code": "YTZ", "description": "Toronto, Ontario, Canada" },
        { "code": "YUL", "description": "Montreal, Quebec, Canada" },
        { "code": "YUM", "description": "Yuma, AZ, USA - International" },
        { "code": "YVR", "description": "Vancouver, British Columbia, Canada" },
        { "code": "YVZ", "description": "Deer Lake, Ontario, Canada" },
        { "code": "YWG", "description": "Winnipeg, Manitoba, Canada" },
        { "code": "YWH", "description": "Victoria, British Columbia, Canada -" },
        { "code": "YXD", "description": "Edmonton, Alberta, Canada -" },
        { "code": "YYC", "description": "Calgary, Alberta, Canada -" },
        { "code": "YYJ", "description": "Victoria, British Columbia, Canada -" },
        { "code": "YYR", "description": "Goose Bay, Newfoundland, Canada -" },
        { "code": "YYT", "description": "St Johns, Newfoundland, Canada" },
        { "code": "YYU", "description": "Kapuskasing, Ontario, Canada" },
        { "code": "YYY", "description": "Mont Joli, Quebec, Canada" },
        { "code": "YYZ", "description": "Toronto, Ontario, Canada - Pearson Intl" },
        { "code": "YZF", "description": "Yellowknife, NW Territories, Canadá" },
        { "code": "ZAG", "description": "Zagreb, Croatia (Hrvatska) - Zagreb" },
        { "code": "ZAH", "description": "Zahedan, Iran - Zahedan" },
        { "code": "ZAL", "description": "Valdivia, Chile - Pichoy" },
        { "code": "ZAM", "description": "Zamboanga, Philippines" },
        { "code": "ZAZ", "description": "Zaragoza, Spain - Zaragoza" },
        { "code": "ZBV", "description": "Vail/Eagle, CO, USA" },
        { "code": "ZCL", "description": "Zacatecas, Zacatecas, Mexico" },
        { "code": "ZCO", "description": "Temuco, Chile - Manquehue" },
        { "code": "ZHA", "description": "Zhanjiang, China" },
        { "code": "ZIH", "description": "Ixtapa/Zihuatanejo, Guerrero, Mexico" },
        { "code": "ZLO", "description": "Manzanillo, Colima, Mexico" },
        { "code": "ZNZ", "description": "Zanzibar, Tanzania - Kisauni" },
        { "code": "ZRH", "description": "Zurich, Switzerland - Zurich" },
        { "code": "ZSA", "description": "San Salvador, Bahamas" },
        { "code": "ZTH", "description": "Zakinthos, Greece - Zakinthos" }
      ]);

    };

    return {
      list,
      save,
      remove,
      exportList,
      formTicket,
      airports,
      validateAirport
    };
  }
})(angular);
