(angular => {

  angular.module("EventTableReservation", ["Config"])
    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/table-reservation", {
        controller: "EventTableReservation.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventTableReservation/index.html`,
        role: ['admin']
      });

  }

})(angular);
