(angular => {
  "use strict";

  angular.module("Participant")
    .controller("Participant.IndexCtrl", [
      "$scope",
      "$timeout",
      "$routeParams",
      "$mdDialog",
      "$q",
      "XLSReader",
      "Loader",
      "Toast",
      "EventService",
      "ParticipantService",
      "AuthService",
      IndexCtrl
    ]);

  function IndexCtrl($scope, $timeout, $routeParams, $mdDialog, $q, XLSReader, Loader, Toast, EventService, ParticipantService, authService) {
    let queryTimeout;
    this.user = authService.getTokenData();
    this.promise = null;
    this.selected = [];
    this.statuses = ["not invited", "invited", "accepted", "unauthorized", "present", "present at time", "not present"];
    this.query = { orderBy: "name" };
    this.pagination = { page: 1, limit: 10 };

    this.sendInvites = (participant) => {
      let toInvite = this.selected.length === 0 ? this.participants : this.selected;

      if (participant) {
        if (participant.status === "accepted") {
          Toast.show(`Participante já confirmado`, "error");
          return;
        }

        toInvite = [participant];
      }

      Loader.add(ParticipantService.invite(toInvite.map(p => p.id))).then(() => {
        Toast.show(`${participant ? "Convite enviado" : "Convites enviados"} com sucesso`, "success");
        this.loadParticipants();
      }).catch(error => {
        if (error.message === "no-invitation-image") {
          return Toast.show(`Convites não enviados: Faça o upload do convite no evento`, "error");
        }

        Toast.httpHandler(error);
      });
    };

    this.findLinkEvent = (participant, event, $event) => {
        return $mdDialog.show({
          templateUrl: `admin/participant/link-event/link-event.html`,
          controller: "Participant.LinkEventCtrl",
          controllerAs: "$ctrl",
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: true,
          locals: {
            participant: angular.copy(participant),
            event: angular.copy(event)
          },
          targetEvent: $event
        });
    };

    this.confirmParticipants = () => {
      const participants = (this.selected.length === 0 ? this.participants : this.selected).filter(p => {
        return p.status !== "accepted" && p.status !== "unauthorized";
      }).map(p => p.id);

      if (!participants.length) {
        Toast.show("Participantes Confirmados com sucesso", "success");
        return;
      }

      Loader.add(ParticipantService.confirmAll(participants)).then(() => {
        Toast.show("Participantes Confirmados com sucesso", "success");
        this.loadParticipants();
      }).catch(response => {
        const extraData = response.data.data || {};

        let spotMessage = `${extraData.spotsAvailable} disponíveis`;
        if (extraData.dependents) {
          spotMessage += ` com ${extraData.dependents} dependentes`;
        }

        const messages = {
          "company-spot-exceeded": `A empresa não possui mais vagas no evento: ${spotMessage}`,
          "event-spot-exceeded": `O evento não possui mais vagas: ${spotMessage}`
        };

        const message = messages[response.data.message];
        if (message) {
          return Toast.show(message, "error");
        }

        Toast.httpHandler(response);
      });
    };

    this.loadParticipants = () => {
      this.selected = [];
      const query = angular.copy(this.query);
      return this.promise = $q.all([
        EventService.current(),
        ParticipantService.list(query),
        ParticipantService.listStaff(query),
        ParticipantService.listSpeakers(query)
      ]).then(([event, participants, staff, speakers]) => {
        this.event = event;
        this.speakers = speakers;
        this.organizers = staff;
        this.participants = participants;
      }).catch(err => Toast.httpHandler(err));
    };

    this.remove = ($participant, participant) => {
      Loader.add(ParticipantService.remove(participant)).then(() => {
        Toast.show("Participante excluído com sucesso", "success");
        this.loadParticipants();
        return participant;
      }).catch(err => {
        if (err.data.message === 'participant-answer-questions') return Toast.show("Este participante compareceu no evento", "error");
        Toast.httpHandler(err);
      });
    };

    this.removeSelected = () => {
      let selectedParticipants = this.selected.map((value) => {
        return value.id;
      });

      Loader.add(ParticipantService.removeSelected(selectedParticipants)).then(() => {
        Toast.show("Participante excluído com sucesso", "success");
        this.loadParticipants();
        this.selected = [0];
        return;
      }).catch(err => {
        if (err.data.message === 'participant-answer-questions') return Toast.show("Estes participantes compareceram no evento", "error");
        Toast.httpHandler(err);
      });
    };

    this.toVipSelected = (statusVip) => {
      let selectedParticipants = this.selected.map(p => p.id);

      return Loader.add(ParticipantService.toVip(selectedParticipants, statusVip)).then(() => {
        this.loadParticipants();
        this.selected = [];

      }).catch(err => Toast.httpHandler(err));
    };

    this.importList = (participantType) => {
      ParticipantService.formImportList(participantType).then(() => {
        this.loadParticipants();
      });
    };

    this.validateExportLabel = participant => {
      ParticipantService.exportLabel(participant.id);
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    this.readPresenceList = () => {
      XLSReader.loadFile('participant-presence-list').then(data => {
        data.forEach(p => p && p.cpf && (p.cpf = p.cpf.replace(/\D/gi, '')));
        return Loader.add(ParticipantService.readPresenceList(data));
      }).then(() => {
        Toast.show(`Leitura feita com sucesso`, "success");
        this.loadParticipants();
      }).catch(err => {
        if (err.data && err.data.message === 'event-is-not-over-yet') {
          return Toast.show('Esse evento ainda não acabou', 'error');
        }
        if (err.data && err.data.message === 'cpf-empty') {
          return Toast.show('Participant sem CPF', 'error');
        }
        Toast.httpHandler(err);
      });
    };

    this.wizard = ($event, participant, currentStep) => {
      ParticipantService.wizard($event, participant, this.type, currentStep).finally(() => {
        this.loadParticipants();
      });
    };

    this.voucher = (participant) => {
      ParticipantService.downloadVoucher(participant);
    };

    const CONFIRMED_STATUS = ['invited', 'accepted', 'confirmed', 'present', 'present at time', 'subscription at time'];

    this.hasVoucher = participant => {
      return CONFIRMED_STATUS.includes(participant.status) &&
        (participant.airTravel || participant.room);
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        this.pagination.page = 1;
        this.loadParticipants();
      }, 500);
    }, true);

    $scope.$watch('headerTabs.current', value => {
      this.query = null;
      this.currentTab = value;
      this.type = ['common', 'staff', 'speaker'][value] || 'common';
    });
  }
})(angular || {});
