(angular => {

  angular.module("Activity", [
      "Config",
      "App"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/activity", {
        controller: "Activity.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/activity/index/index.html`,
        role: ['admin', 'user', 'activity_speaker', 'organizer', 'coordinator']
      })
      .when("/activity/statistics/:activityId", {
        controller: "Activity.StatisticCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/activity/statistics/statistics.html`,
        allowAnonymous: true
      });
  }

})(angular);
