(angular => {
  "use strict";

  angular.module("EventCompanyEmployee").controller("EventCompanyEmployee.FormUserCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$mdDialog",
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyEmployeeService",
    "ParticipantService",
    "EventCompanyService",
    "model",
    FormUserCtrl
  ]);

  function FormUserCtrl($q, $routeParams, $filter, $mdDialog, $scope, $timeout, Loader, Toast, eventService, eventCompanyEmployeeService, participantService, eventCompanyService, model) {
    this.exhibitor = null;
    this.types = ['standBuilder', 'agency'];
    this.model = model || {};
    this.useOfficial = false;

    eventService.current().then(event => {
      this.event = event;
    });

    this.save = () => {
      eventCompanyService.getExhibitor($routeParams.exhibitorId).then(exhibitor => {
        this.model.event_exhibitor_id = exhibitor.id;
        return Loader.add(eventCompanyEmployeeService.save(this.model));
      }).then(() => {
        Toast.show(`Prestador ${model ? "editado" : "cadastrado" } com sucesso, credencial enviada`, "success");
        $mdDialog.hide(this.model);
      }).catch(response => Toast.httpHandler(response));
    };

    this.cancel = $mdDialog.cancel;

    $scope.$watch(() => this.useOfficial, () => {
      if (this.useOfficial) {
        Loader.add(eventCompanyEmployeeService.standBuilder()).then(s => {
          this.model.phone = s.company.contact;
          this.model.cpf_cnpj = s.company.cnpj;
          this.model.name = s.company.name;
          this.model.email = s.company.email;
          this.model.type = s.type;
        }).catch(response => Toast.httpHandler(response));
      } else {
        this.model = model || {};
      }
    }, true);

  }
})(angular || {});
