(angular => {

  angular.module("Activity")

    .filter("typesActivity", [CompanyTypeFilter]);

  function CompanyTypeFilter() {

    const types = {
      "main": "Corporativa",
      "additional": "Complementar"
    };

    function filter(type) {
      if (!types[type]) {
        return type;
      }
      return types[type];
    }

    return filter;
  }

})(angular);
