(angular => {
  "use strict";


  angular.module("ActivityPremium").controller("ActivityPremium.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyService",
    "ActivityPremiumService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, eventService, eventCompanyService, activityPremiumService) {
    this.promise = null;
    this.totalActivitiesPremium = 0;
    this.pagination = { page: 1, limit: 10 };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      this.promise = eventCompanyService.list().then(companies => {
        return activityPremiumService.listWithParticipants().then(a => [a, companies]);
      }).then(([activities, companies]) => {
        this.eventCompanies = companies
          .filter(c => c.spots_activity_premium > 0)
          .map(e => {
            e.activities = activities.filter(a => a.participants.some(p => p.company_id === e.company_id));
            this.totalActivitiesPremium += e.spots_activity_premium || 0;
            return e;
          });
      }).catch(err => Toast.httpHandler(err));
    };

    this.export = () => {
      activityPremiumService.exportList();
    };

    this.load();

  }
})(angular || {});
