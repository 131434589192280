(angular => {

  angular.module("Common")

    .filter("eventAirTravelPeriod", [EventAirTravelPeriod]);

  function EventAirTravelPeriod() {

    const types = {
      "morning": "Manhã",
      "afternoon": "Tarde",
      "night": "Noite",
      "dawn": "Madrugada"
    };

    function filter(type) {
      return types[type] || type;
    }

    return filter;
  }

})(angular);
