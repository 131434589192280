(angular => {
  "use strict";

  angular.module("EventTableReservation").controller("EventTableReservation.IndexCtrl", [
    "$scope",
    "Loader",
    "EventService",
    "AuthService",
    "EventTableReservationService",
    IndexCtrl
  ]);

  function IndexCtrl($scope, loader, eventService, authService, EventTableReservationService) {
    this.user = authService.getTokenData();
    loader.add(eventService.current(true).then(event => {
      this.event = event;

      this.add = $event => {
        $scope.$emit('change-header-tab-current', 0);
        $scope.$broadcast('add-hotel', $event);
      };

      this.export = () => {
        EventTableReservationService.exportList();
      };

    }));


  }
})(angular || {});
