(angular => {
  "use strict";

  angular.module("Company").controller("Company.FormCtrl", [
    "$q",
    "$mdDialog",
    "Loader",
    "Toast",
    "CompanyService",
    "SponsorshipTypeService",
    "StateService",
    "AuthService",
    "company",
    FormCtrl
  ]);

  function FormCtrl($q, $mdDialog, Loader, Toast, CompanyService, SponsorshipTypeService, StateService, AuthService, company) {
    this.company = company || {
      status: 'active',
      type: AuthService.hasRole('organizer') ? 'commercial partner' : undefined
    };
    if (company) {
      this.company.domains = this.company.domains ? this.company.domains.split(',') : [];
    } else {
      this.company.domains = [];
    }

    this.states = StateService.states;

    this.types = CompanyService.listTypes();

    Loader.add($q.all([
      SponsorshipTypeService.list(),
    ])).then(([sponsorshipTypes]) => {
        this.sponsorship_types = sponsorshipTypes;
    }).catch(err => Toast.httpHandler(err));

    this.status = ['active', 'inactive'];
    this.readonly = false;

    this.save = () => {
      return Loader.add(CompanyService.save(this.company)).then(company => {
        Toast.show("Empresa salva com sucesso", "success");
        $mdDialog.hide(company);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
