(angular => {

  angular.module("Common").filter("age", [filter]);

  function filter() {
    return value => {
      if (!value) return;
      return moment().diff(value, 'year') + ' anos';
    };
  }

})(angular);
