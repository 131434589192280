(angular => {

  angular.module("User", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/user", {
        controller: "User.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/user/list/list.html`,
        role: "admin",
        allowWithoutEvent: true
      });
  }

})(angular);
