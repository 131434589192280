(angular => {

  angular.module("Common").filter("iconFile", [filter]);

  function filter() {
    return (filename) => {
      switch ((filename || "").split(".").pop().toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          return "file-image";
        case "xls":
        case "xlsx":
        case "csv":
          return "file-excel";
        case "zip":
        case "rar":
          return "zip-box";
        case "ppt":
        case "pptx":
          return "file-powerpoint";
        case "doc":
        case "docx":
          return "file-word";
        case "mp4":
        case "mkv":
        case "avi":
          return "file-video";
        case "mp3":
          return "file-music";
        default:
          return "file";
      }
    };
  }

})(angular);
