((angular) => {
  angular.module('Common').filter('guestStatus', [CompanyTypeFilter]);

  function CompanyTypeFilter() {
    const types = {
      invited: 'Solicitado',
      accepted: 'Aprovado',
      refused: 'Recusado',
      confirmed: 'Inscrição Confirmada',
      'subscription at time': 'Inscrição na Hora',
    };

    function filter(type) {
      if (!types[type]) {
        return type;
      }

      return types[type];
    }

    return filter;
  }
})(angular);
