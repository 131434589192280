(angular => {
  "use strict";


  angular.module("EventOfficialProvider").controller("EventOfficialProvider.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "$routeParams",
    "EventService",
    "CompanyService",
    "EventOfficialProviderService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, $routeParams, eventService, companyService, eventOfficialProviderService) {
    this.form = ($event, model) => {
      eventOfficialProviderService.form($event, model).then(() => {
        this.loadOfficialServices();
      });
    };

    this.loadOfficialServices = () => {
      eventOfficialProviderService.list().then(officialProvider => {
        this.officialProvider = officialProvider;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadOfficialServices();

    this.remove = ($event, model) => {
      Loader.add(eventOfficialProviderService.remove(model.id)).then(() => {
        Toast.show("Serviço removido com sucesso", "success");
        this.loadOfficialServices();
      }).catch(err => Toast.httpHandler(err));
    };

  }
})(angular || {});
