(angular => {
  "use strict";

  angular.module("Activity").controller("Activity.FormCtrl", [
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "ActivityService",
    "EventService",
    "CompanyService",
    "activity",
    "date",
    "$sce",
    FormCtrl
  ]);

  function FormCtrl($scope, $timeout, $mdDialog, Loader, Toast, ActivityService, EventService, companyService, activity, date) {

    this.totalDisplayed = 50;
    let queryTimeout;
    this.filterRestricted = activity.restricted_list;

    EventService.current().then(event => {
        this.event = event;
        this.types = ['main', 'additional'];
        this.marketingActivity = false;

        this.isEditing = !!activity;
        this.activity = activity || { date: date[0], attendance_list: true };
        this.activity.speakers = activity.speakers || [];

        this.marketingActivity = this.activity.type === 'marketing_activity' ? true : false;
        this.activity.type = this.activity.type === 'premium' ? this.event.name_activity_premium : this.activity.type;
        this.activity.type = this.activity.type === 'marketing_activity' ? this.event.marketing_activity_name : this.activity.type;

        if (this.event.activity_premium) {
          this.types.push(this.event.name_activity_premium);
        }

        if (this.event.marketing_activity) {
          this.types.push(this.event.marketing_activity_name);
        }

        if (this.activity.spots === 0) {
          delete this.activity.spots;
        }

        companyService.list().then(companies => {
          this.companies = companies;
        });

        if (!activity) {
          switch (date[1]) {
            case 0:
              this.activity.type = "main";
              break;
            case 1:
              this.activity.type = "additional";
              break;
            case 2:
              this.activity.type = !this.event.name_activity_premium ? this.event.marketing_activity_name : this.event.name_activity_premium;
              break;
            case 3:
              this.activity.type = this.event.marketing_activity_name;
              break;
          }
        }

        this.orderActivityByName();
      });

      this.orderActivityByName = () => {
        if(this.activity.restricted_list){
          return this.activity.restricted_list.sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
        }
      };

      this.getImage = (image) => {
        if (!image) return "/img/sem-imagem.jpg";
        return image.base64 || `/content/${image}`;
      };

      this.setLogo = result => {
        this.activity.logo = {
          filename: result.filename,
          base64: result.base64
        };
      };

      this.removeLogo = () => {
        this.activity.logo = null;
      };

      this.addSpeaker = () => {
        ActivityService.formSpeaker().then(speaker => {
          if (!speaker) {
            return;
          }
          if (this.activity.speakers.some(({ id }) => speaker.id === id)) {
            return Toast.show('Palestrante já adicionado', 'error');
          }
          return this.activity.speakers.push(speaker);
        });
      };

      this.removeSpeaker = (index) => {
        this.activity.speakers.splice(index, 1);
      };

      this.addParticipant = () => {
        if (!this.activity.restricted_list) this.activity.restricted_list = [];
        let company = this.companies.filter(c => c.id === this.model.company_id)[0];
        this.activity.restricted_list.push({
          name: this.model.name,
          cpf: this.model.cpf,
          company_id: this.model.company_id,
          company_name: company.name
        });
      };

      this.addImportedParticipant = (participant) => {
        participant.cpf = participant.cpf.toString().trim();
        participant.cpf = participant.cpf.replace(/\D/g, "");

        const s = "00000000000" + participant.cpf;
        participant.cpf = s.substr(s.length - 11);

        if (!this.activity.restricted_list) this.activity.restricted_list = [];
        this.activity.restricted_list.push({
          name: participant.nome,
          cpf: participant.cpf,
          company_id: null,
          company_name: participant.empresa
        });
      };

      this.removeParticipant = (participantCpf) => {
        this.activity.restricted_list = this.activity.restricted_list.filter(participant => {
          if (participant.cpf !== participantCpf) return participant;
        });
        this.filterRestricted = this.filterRestricted.filter(participant => {
          if (participant.cpf !== participantCpf) return participant;
        });
      };

      this.importList = () => {
        if (!this.event) return Toast.show("Escolha um evento", "error");
        ActivityService.formImportList().then((participants) => {
          participants.forEach(participant => {
            this.addImportedParticipant(participant);
          });
        });
      };

      this.save = () => {
        const model = angular.copy(this.activity);

        if (model.restricted_list) {
          model.restricted_list = model.restricted_list;
        }

        if (model.type === this.event.name_activity_premium) {
          model.type = 'premium';
        }

        if (model.type === this.event.marketing_activity_name) {
          model.type = 'marketing_activity';
        }

        if (model.description && typeof model.description !== 'string') {
          model.description = activity.description.toString();
        }

        return Loader.add(ActivityService.save(model)).then(result => {
          $mdDialog.hide(result);

          if (!result.importationResult || !result.importationResult.errors.length) {
            Toast.show("Atividade salvo com sucesso", "success");
            return;
          }

          const participants = result.importationResult.errors.map(data => {
            const errors = {
              'participant-not-found': 'O participante não está inscrito no evento',
              'participant-associated': 'O participante não é um associado dessa rede',
              'participant-duplicated': 'O cpf já foi inserido',
              'Nonexistent-company': 'Essa Empresa não está cadastrada'
            };

            data.participant.error = errors[data.error] || data.error;
            return data.participant;
          });

          $mdDialog.show({
            templateUrl: `admin/activity/import/import.html`,
            controller: "Activity.ImportCtrl",
            controllerAs: "$ctrl",
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: { participants }
          });
        }).catch((err) => {
          if (err.data && err.data.message === 'activity_speaker_pkey') {
            return Toast.show('Este palestrante já foi cadastrado', "error");
          }

          if (err.data && err.data.message === 'import-list-error') {
            return Toast.show('Nem todos os dados foram salvos, verifique o arquivo de erros!', "error");
          }

          return Toast.httpHandler(err);
        });
      };

      this.loadMore = () => {
        this.totalDisplayed += 50;
      };

      $scope.$watch(() => this.search, () => {
        this.totalDisplayed = 50;
        const query = angular.copy(this.search);
        this.filterRestricted = [];
        if (query) {
          $timeout.cancel(queryTimeout);
          queryTimeout = $timeout(() => {
            this.filterRestricted = this.activity.restricted_list.filter(rl => {
              return rl.cpf.indexOf(this.search) > -1;
            });
          }, 500);
        }else {
          this.filterRestricted = this.activity.restricted_list;
        }
      }, true);

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
