(angular => {
  "use strict";

  angular.module("App")
    .factory("LoginService", ["$http", "$q", "$location", "$timeout", "$mdDialog", "API", "AuthService", LoginService]);

  function LoginService($http, $q, $location, $timeout, $mdDialog, API, AuthService) {
    let loginPromise = null;

    const logout = () => {
      AuthService.removeToken();
      $location.path("/");
    };

    const login = ($event) => {
      if (AuthService.isLoggedIn()) {
        return $q.resolve();
      }

      if (loginPromise) {
        return loginPromise;
      }

      const deferred = $q.defer();

       
      
        $timeout(() => {
          return $mdDialog.show({
            templateUrl: `admin/app/login-modal/login-modal.html`,
            controller: "App.LoginModalCtrl",
            controllerAs: "$ctrl",
            clickOutsideToClose: false,
            escapeToClose: false,
            targetEvent: $event
          });
        }, 1000).finally(() => {
          loginPromise = null;
  
          if (AuthService.isLoggedIn()) {
            return deferred.resolve();
          }
  
          deferred.reject("Login cancelado");
        });
      
      return loginPromise = deferred.promise;
    };

    const resetPassword = (token) => {
      return $mdDialog.show({
        templateUrl: `admin/app/password-reset/password-reset.html`,
        controller: "App.PasswordResetCtrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          token
        }
      });
    };

    const recover = (email) => {
      return $http.post(`${API}/recover`, { email }).then(res => res.data);
    };

    const doLogin = (credentials) => {

      const promise = $http.post(`${API}/login`, credentials);
      promise.then((result) => {
        if (result.status === 200) {
          AuthService.setToken(result.data.token);
          $mdDialog.cancel();
        }
      });
      return promise;
    };

    const doReset = (data) => {
      return $http.post(`${API}/reset-password`, data);
    };

    const changePasswordModal = (data) => {
      return $http.post(`${API}/reset-password-modal`, data);
    };

    const previewToken = () => {
      return $http.get(`${API}/preview-token`).then(res => res.data.token);
    };

    const changeEvent = (eventId) => {
      return $http.get(`${API}/change-event-token/${eventId}`).then(res => res.data.token);
    };

    return {
      login,
      logout,
      recover,
      resetPassword,
      cancel: () => $mdDialog.cancel(),
      doLogin,
      doReset,
      previewToken,
      changeEvent,
      changePasswordModal
    };

  }

})(angular);
