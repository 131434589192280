((angular) => {
  'use strict';

  angular
    .module('Participant')
    .factory('ParticipantService', [
      '$q',
      '$http',
      'API',
      'AuthService',
      'FormRedirectService',
      '$mdDialog',
      'XLSReader',
      'Toast',
      ParticipantService,
    ]);

  function ParticipantService(
    $q,
    $http,
    API,
    AuthService,
    FormRedirectService,
    $mdDialog,
    XLSReader,
    Toast
  ) {
    this.params = {};

    const confirmAll = (participants) => {
      return $http.post(`${API}/participant/confirmAll`, participants);
    };

    const list = (params) => {
      if (params) {
        params.direction = 'asc';
      }

      if (params && params.orderBy && params.orderBy[0] === '-') {
        params.orderBy = params.orderBy.replace('-', '');
        params.direction = 'desc';
      }

      return $http({
        method: 'GET',
        url: `${API}/participant/`,
        params: params || this.params,
      }).then((res) => {
        res.data.forEach((participant) => {
          if (participant.birth_date)
            participant.birth_date = new Date(participant.birth_date);
          return participant;
        });
        return res.data;
      });
    };

    const query = (term, companyId, notHasTicket, status) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/query`,
        params: { term, companyId, notHasTicket, status },
      }).then((res) => {
        return res.data;
      });
    };

    const save = (participant, send_email, forCompany) => {
      return $http
        .post(`${API}/participant`, { participant, send_email, forCompany })
        .then((res) => res.data);
    };

    const listWithDependents = () => {
      return $http
        .get(`${API}/participant/participants-dependents`)
        .then((res) => res.data);
    };

    const listForCompany = (params) => {
      if (params) {
        params.direction = 'asc';
      }

      if (params && params.orderBy && params.orderBy[0] === '-') {
        params.orderBy = params.orderBy.replace('-', '');
        params.direction = 'desc';
      }

      return $http({
        method: 'GET',
        url: `${API}/participant/listForCompany/`,
        params: params || this.params,
      }).then((res) => {
        res.data.forEach((participant) => {
          if (participant.birth_date)
            participant.birth_date = new Date(participant.birth_date);
          return participant;
        });
        return res.data;
      });
    };

    const saveActivities = (participantId, activities, isPremium) => {
      return $http
        .post(`${API}/participant/activities`, {
          participantId,
          activities,
          isPremium,
        })
        .then((res) => res.data);
    };

    const removeActivities = (participantId, activities) => {
      return $http
        .post(`${API}/participant/activities/remove`, {
          participantId,
          activities,
        })
        .then((res) => res.data);
    };

    const remove = (participant) => {
      return $http.delete(`${API}/participant/${participant.id}`);
    };

    const removeSelected = (selectedParticipants) => {
      return $http.post(
        `${API}/participant/deleteSelectedParticipants`,
        selectedParticipants
      );
    };

    const formImportList = (participantType) => {
      return XLSReader.loadFile('participant-import').then((participants) => {
        return $mdDialog.show({
          templateUrl: `admin/participant/import/import.html`,
          controller: 'Participant.ImportModalCtrl',
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          escapeToClose: true,
          fullscreen: true,
          locals: { participants, participantType },
          targetEvent: participantType,
        });
      });
    };

    const findLinkEvent = (participant, event) => {
      return $mdDialog.show({
        templateUrl: `admin/participant/link-event/link-event.html`,
        controller: 'Participant.LinkEventCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          event: angular.copy(event),
          participant: angular.copy(participant),
        },
      });
    };

    const getEventDays = (event) => {
      let eventDays = [];
      let currentDate = moment(event.start_date);
      let endDate = moment(event.end_date);

      eventDays.push(angular.copy(currentDate));

      while (currentDate.isBefore(endDate, 'day')) {
        currentDate.add(1, 'days');
        eventDays.push(angular.copy(currentDate));
      }

      eventDays = eventDays.map((d) => {
        return d.toDate();
      });

      return eventDays;
    };

    const importList = (data) => {
      return $http
        .post(`${API}/participant/import`, data)
        .then((result) => result.data);
    };

    const readPresenceList = (data) => {
      return $http
        .post(`${API}/participant/read`, data)
        .then((result) => result.data);
    };

    const exportList = (fields, participants, type) => {
      const fieldsString = fields.map((x) => x.key).join(',');
      const participantsString = participants.map((x) => x.id).join(',');

      return FormRedirectService.submit(`${API}/participant/export`, {
        fields: fieldsString,
        participants: participantsString,
        type: type,
      });
    };

    const findByCpf = (cpf) => {
      return $http
        .get(`${API}/participant/find-by-cpf/${cpf}`)
        .then((r) => r.data);
    };

    const invite = (participantIds) => {
      return $http
        .post(`${API}/participant/invite`, participantIds)
        .then()
        .catch((error) => {
          if (error.status === 400) {
            throw new Error('no-invitation-image');
          }
          throw error;
        });
    };

    const wizard = ($event, participant, type, currentStep, company) => {
      return $mdDialog.show({
        templateUrl: `admin/participant/wizard/wizard.html`,
        controller: 'Participant.WizardCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          participant: angular.copy(participant),
          currentStep: angular.copy(currentStep),
          company: angular.copy(company),
          participantType: angular.copy(type),
        },
        targetEvent: $event,
      });
    };

    const listDependents = (id) => {
      return $http
        .get(`${API}/participant/dependents/${id}`)
        .then((r) => r.data);
    };

    const listStaff = (params) => {
      if (params) {
        params.direction = 'asc';
      }

      if (params && params.orderBy && params.orderBy[0] === '-') {
        params.orderBy = params.orderBy.replace('-', '');
        params.direction = 'desc';
      }

      return $http({
        method: 'GET',
        url: `${API}/participant/staff`,
        params: params || this.params,
      }).then((res) => res.data);
    };

    const listSpeakers = (params) => {
      if (params) {
        params.direction = 'asc';
      }

      if (params && params.orderBy && params.orderBy[0] === '-') {
        params.orderBy = params.orderBy.replace('-', '');
        params.direction = 'desc';
      }

      return $http({
        method: 'GET',
        url: `${API}/participant/speaker`,
        params: params || this.params,
      }).then((res) => res.data);
    };

    const saveDependents = (participantId, dependents) => {
      return $http
        .post(`${API}/participant/dependents`, { participantId, dependents })
        .then((res) => res.data)
        .catch((error) => {
          if (error.status === 400) {
            throw new Error('duplicates');
          }
          throw error;
        });
    };

    const removeCompany = (userId, companyId) => {
      return $http.delete(
        `${API}/participant/remove-company/${userId}/${companyId}`
      );
    };

    const statuses = () => {
      return [
        'not invited',
        'invited',
        'accepted',
        'unauthorized',
        'present',
        'present at time',
        'not present',
        'subscription at time',
      ];
    };

    const downloadVoucher = ({ id, name }) => {
      return FormRedirectService.submit(`${API}/participant/voucher/${id}`, {
        name,
      });
    };

    const suggestionClassification = (participantId) => {
      if (!participantId) return;
      return $http
        .get(`${API}/participant/suggestionclassification/${participantId}`)
        .then((r) => r.data);
    };

    const toVip = (participantIds, status) => {
      return $http
        .post(`${API}/participant/vip/${status}`, participantIds)
        .then((result) => result.data);
    };

    const findParticipantsByEvent = (eventId, companyId, query) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/findParticipantsByEvent`,
        params: { eventId, companyId, query },
      }).then((res) => {
        return res.data;
      });
    };

    const findParticipantsByEventSession = (eventId, companyId, query) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/findParticipantsByEventSession`,
        params: { eventId, companyId, query },
      }).then((res) => {
        return res.data;
      });
    };

    const findLinkEventByDate = (eventId, eventDate, cpf) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/findLinkEventByDate/${eventId}/${eventDate}/${cpf}`,
        params: { eventId, eventDate, cpf },
      })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.status === 400) {
            if (error.data.message === 'Link não encontrado') {
              Toast.show(error.data.message, 'error');
            }
          }
          throw error;
        });
    };

    const findTokenEventByDate = (eventId, eventDate, cpf) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/findTokenEventByDate/${eventId}/${eventDate}/${cpf}`,
        params: { eventId, eventDate, cpf },
      })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.status === 400) {
            if (error.data.message === 'Link não encontrado') {
              Toast.show(error.data.message, 'error');
            }
          }
          throw error;
        });
    };

    const findCompanyByEvent = (eventId, query) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/event/company`,
        params: { eventId, query },
      }).then((res) => {
        return res.data;
      });
    };

    return {
      confirmAll,
      downloadVoucher,
      exportList,
      findByCpf,
      formImportList,
      importList,
      invite,
      list,
      listDependents,
      listForCompany,
      listSpeakers,
      listStaff,
      listWithDependents,
      query,
      readPresenceList,
      remove,
      removeActivities,
      removeCompany,
      removeSelected,
      save,
      saveActivities,
      saveDependents,
      statuses,
      suggestionClassification,
      toVip,
      wizard,
      findParticipantsByEvent,
      findParticipantsByEventSession,
      findLinkEvent,
      getEventDays,
      findLinkEventByDate,
      findTokenEventByDate,
      findCompanyByEvent,
    };
  }
})(angular);
