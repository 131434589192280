(angular => {
  "use strict";

  angular.module("ActivityPremium").controller("ActivityPremium.FormSpotCtrl", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "ActivityService",
    "ParticipantService",
    "ActivityPremiumService",
    "person",
    "activities",
    "hasSpotsActivities",
    FormSpotCtrl
  ]);

  function FormSpotCtrl($scope, $mdDialog, loader, toast, activityService, participantService, ActivityPremiumService, person, activities, hasSpotsActivities) {
    this.selected = activities.filter(a => person.activities.some(p => p.id === a.id));
    this.activities = activities;
    this.save = () => {
      if (this.selected) {
        activities = this.selected.map(a => a.id);
      }

      loader.add(participantService.saveActivities(person.id, activities, true)).then(() => {
        toast.show("Atividades cadastradas com sucesso", "success");
        $mdDialog.hide();
      }).catch(err => toast.httpHandler(err));
    };

    this.hasSpotsActivities = (activityId) => {
      return hasSpotsActivities.includes(activityId);
    };

    this.cancel = $mdDialog.cancel;
  }

})(angular || {});
