(angular => {
  "use strict";

  angular.module("EventHotel").component("eventHotelRooms", {
    templateUrl: 'admin/eventHotel/index/rooms/rooms.html',
    controller: [
      "Loader",
      "Toast",
      "EventService",
      "EventHotelService",
      "AccommodationService",
      "AuthService",
      ListCtrl
    ]
  });

  function ListCtrl(Loader, Toast, eventService, eventHotelService, accommodationService, authService) {
    this.totals = { persons: 0, children: 0, teenagers: 0, adults: 0, staff: 0 };
    this.totalsRooms = { single: 0, double: 0, triple: 0, quadriple: 0 };
    this.teenager = 0;
    this.adult = 0;

    const typeSpots = eventHotelService.getTypes().reduce((acc, type) => {
      acc[type.type] = type.spots;
      return acc;
    }, {});
    this.user = authService.getTokenData();
    eventService.current().then(event => {
      this.event = event;
    });

    Loader.add(accommodationService.list()).then(eventCompanies => {
      if (!this.event.id) return;
      eventHotelService.listPriceRooms().then(totalPriceRooms => {
        this.totalPriceRooms = totalPriceRooms;
        this.eventCompanies = eventCompanies;
        this.eventCompanies.forEach(eventCompany => {
          eventCompany.rooms.forEach(room => {
            this.totalsRooms[room.type]++;

            room.persons.forEach(person => {
              this.totals.persons++;
              if (person.age >= this.event.relatives_min_age && person.participant &&
                person.participant.type === 'staff') {
                this.totals.staff++;
              }
              if (!person.age || person.age > 18) return this.totals.adults++;
              if (person.age <= 12) return this.totals.children++;
              this.totals.teenagers++;
            });
          });
        });
      });
    });

    this.isRoomFull = room => {
      const spots = typeSpots[room.type];
      const personCount = room.persons.filter(p => !p.age || p.age >= this.event.relatives_min_age).length;

      return spots <= personCount;
    };

  }
})(angular || {});
