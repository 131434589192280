(angular => {
  "use strict";

  angular.module("Company").controller("Company.DetailsCtrl", [
    "$route",
    "$routeParams",
    "$location",
    "Loader",
    "Toast",
    "CompanyService",
    "AuthService",
    DetailsCtrl
  ]);

  function DetailsCtrl($route, $routeParams, $location, Loader, Toast, CompanyService, authService) {
    this.user = authService.getTokenData();

    CompanyService.get($routeParams.idCompany).then(company => {
      this.company = company;

      if (this.company.domains !== null) {
        this.company.domains = this.company.domains.replace(',', ', ');
      }
    }).catch(err => Toast.httpHandler(err));

    this.form = ($event) => {
      CompanyService.form($event, this.company).then(() => {
        $route.reload();
      });
    };

    this.remove = () => {
      CompanyService.remove(this.company.id).then(() => {
        $location.path("/company");
      }).catch(err => Toast.httpHandler(err));
    };
  }
})(angular || {});
