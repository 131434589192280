(angular => {
  "use strict";

  angular.module("PushNotification")
    .controller("PushNotification.FormCtrl", [
      "$mdDialog",
      "Loader",
      "Toast",
      "PushNotificationService",
      FormCtrl
    ]);

  function FormCtrl($mdDialog, Loader, Toast, PushNotificationService) {

    this.notification = { title: "Abrafarma" };

    this.push = ({ title, body }) => {
      Loader.add(PushNotificationService.push(title, body))
        .then(() => $mdDialog.hide({ title, body }))
        .then(() => Toast.show('Notificação Enviada', 'success'))
        .catch(err => {
          if (err && err.data && err.data.message === 'no-participant-notificated') {
            return Toast.show('Nenhum participante notificado para este evento', 'error');
          }
          throw err;
        })
        .catch(Toast.httpHandler);
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
