(angular => {
  "use strict";

  angular.module("Company").component("companyDetailsParticipant", {
    templateUrl: 'admin/company/details/components/details-participant.html',
    controller: [
      "Loader",
      "Toast",
      "ParticipantService",
      DetailsParticipant
    ],
    bindings: {
      company: "="
    }
  });

  function DetailsParticipant(Loader, Toast, ParticipantService) {
    this.$onInit = () => {
      this.query = {
        orderBy: "name",
        companyId: this.company.id
      };
      this.loadParticipants();
    };

    this.loadParticipants = () => {
      ParticipantService.listForCompany(this.query).then(participants => {
        this.participants = participants;
      });
    };

    this.form = ($event, participant) => {
      ParticipantService.wizard($event, participant, 'participant', this.company.id).then(() => {
        this.loadParticipants();
      });
    };

    this.removeCompany = ($event, participant) => {
      Loader.add(ParticipantService.removeCompany(participant.id, this.company.id)).then(() => {
        Toast.show("Participante removido da Empresa com sucesso", "success");
        this.loadParticipants();
        return this.company;
      }).catch(err => Toast.httpHandler(err));
    };
  }

})(angular);
