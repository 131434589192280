(angular => {
  "use strict";

  angular.module("EventGuest").factory("PositionService", [
    "$http",
    "API",
    PositionService
  ]);

  function PositionService($http, API) {

    const list = (params) => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/position`,
        params: params,
      }).then((res) => res.data);
    };

    return {
      list
    };

  }
})(angular);
