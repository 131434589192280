(angular => {
  "use strict";

  angular.module("EventGuest").controller("EventGuest.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventGuestService",
    "CompanyService",
    "AuthService",
    "PositionService",
    "model",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, loader, toast, eventGuestService, companyService, AuthService, PositionService, model) {
    if (model) {
      model.company_id ? model.company_id : model.company_id = 0;
    }

    this.eventGuest = model || {};
    this.user = AuthService.getTokenData();
    this.newPosition = false;

    loader.add(companyService.list())
      .then((companies) => {
        this.companies = companies;
      }).then(PositionService.list())
      .catch(err => toast.httpHandler(err));

    loader.add(PositionService.list()).then((positions) => {
      this.positions = positions;
    }).catch(err => toast.httpHandler(err));

    this.save = () => {
      const data = angular.copy(this.eventGuest);

      if (!data.company_id || data.company_id === '0') {
        delete data.company_id;
      } else {
        delete data.company_description;
      }

      return loader.add(eventGuestService.save(data)).then(eventGuest => {
        const message = this.eventGuest.company_description ?
          "Convidado salvo com sucesso, mas a empresa utilizada ainda não está cadastrada" :
          "Convidado salvo com sucesso";

        toast.show(message, "success");
        $mdDialog.hide(eventGuest);
      }).catch(err => {
        const messages = {
          'company-manager-another-company': 'Não é permitido enviar convites para outras empresas',
          'email-already-registered': 'Email já cadastrado',
          'cpf-invalid': 'cpf inválido',
          'cpf-already-registered': 'cpf já cadastrado',
          'invalid-enterprise-email': 'E-mail não comercial',
          'guest-in-event': 'Convite já enviado',
          'comercial-partner': 'Não é permitido enviar convite para parceiros comerciais',
          'company-email-invalid-domain': 'Email inválido para a empresa selecionada'
        };

        if (err.data && messages[err.data.message]) {
          return toast.show(messages[err.data.message], 'error');
        }

        toast.httpHandler(err);
      });
    };

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
