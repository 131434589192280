(angular => {
  "use strict";

  angular.module("Common")
    .factory("Toast", ["$mdToast", service]);

  function service($mdToast) {
    const show = (message, theme) => {
      const toast = $mdToast
        .simple().toastClass("md-toast-" + theme)
        .textContent(message)
        .position("top right")
        .hideDelay(theme === 'error' ? 0 : 5000)
        .action("OK");

      return $mdToast.show(toast);
    };

    const genericError = (err) => {
      if (err) console.error(err);
      return show("Aconteceu um erro inesperado...", "error");
    };

    const notFound = () => {
      return show("Não encontrado", "error");
    };

    const httpHandler = (res) => {
      console.error(res);

      res = res || {};
      const status = res.status || '';
      let message = null;

      if (res.data && res.data.message) {
        message = res.data.message;
      }

      if (typeof res.data === "string") {
        message = res.data;
      }

      if (typeof res === "string") {
        message = res;
      }

      switch (status) {
        case 400:
          return show(message || "Dados inválidos", "error");
        case 409:
          return show(message || "Conflito", "error");
        case 403:
          return show("Você não tem permissão de acesso", "error");
        case 404:
          return notFound();
        default:
          if (message) {
            return show(message, "error");
          }

          return genericError();
      }
    };

    return {
      show,
      httpHandler,
      notFound,
      genericError
    };

  }

})(angular);
