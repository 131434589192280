(angular => {
  "use strict";

  angular.module("Speaker").factory("SpeakerService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    SpeakerService
  ]);

  function SpeakerService($q, $http, $mdDialog, API) {

    this.params = {};

    const list = (params) => {
      params = angular.copy(params);
      params.direction = "asc";

      if (params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/speaker`,
        params: params || this.params
      }).then((res) => {
        return res.data;
      });
    };

    const form = ($event, speaker) => {
      return $mdDialog.show({
        templateUrl: `admin/speaker/form/form.html`,
        controller: "Speaker.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          speaker: angular.copy(speaker)
        },
        targetEvent: $event
      });
    };

    const save = (speaker) => {
      return $http.post(`${API}/speaker`, speaker).then(res => res.data);
    };

    const remove = (speaker) => {
      return $http.delete(`${API}/speaker/${speaker.id}`);
    };

    const query = (term) => {
      return $http({
        method: "GET",
        url: `${API}/speaker/query`,
        params: { term },
      }).then((res) => {
        return res.data;
      });
    };

    return {
      list,
      remove,
      form,
      save,
      query
    };
  }
})(angular);
