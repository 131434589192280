(angular => {
  "use strict";

  angular.module("Participant")
    .directive("participantCreate", ["$mdDialog", "EventService", ParticipantCreate]);

  function ParticipantCreate($mdDialog, eventService) {
    return {
      restrict: "A",
      scope: {
        participant: "=participantCreate",
        onDone: "&onDone"
      },
      link: (scope, element) => {
        element.on("click", ($participant) => {

          eventService.current().then(event => {
            if (!scope.participant && moment() > moment(event.limit_date) && !event.online) {
              $mdDialog.show(
                $mdDialog.alert()
                .title("A data para o cadastro de novos participantes expirou ")
                .ok("Ok")
              );

              return;
            }

            $mdDialog.show({
              templateUrl: `admin/participant/form/form.html`,
              controller: "Participant.CreateCtrl",
              controllerAs: "$ctrl",
              clickOutsideToClose: true,
              escapeToClose: true,
              fullscreen: true,
              locals: {
                participant: angular.copy(scope.participant)
              },
              targetParticipant: $participant,
            }).then(() => {
              const callback = scope.onDone();
              if (callback) {
                callback();
              }
            });
          });
        });

      }
    };
  }

})(angular);
