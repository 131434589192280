(angular => {
  "use strict";

  angular.module("Participant").component("participantWizardForm", {
    templateUrl: 'admin/participant/wizard/components/form/form.html',
    controller: [
      "$q",
      "$routeParams",
      "$scope",
      "$mdDialog",
      "Loader",
      "Toast",
      "ParticipantService",
      "AuthService",
      "CompanyService",
      "EventService",
      "PositionService",
      "PositionTypeService",
      "ClassificationService",
      CreateCtrl
    ],
    bindings: {
      participant: "=",
      participantType: "=",
      company: "=",
      onCompleted: "&",
      onCancel: "&"
    }
  });

  function CreateCtrl($q,
    $routeParams,
    $scope,
    $mdDialog,
    Loader,
    Toast,
    ParticipantService,
    AuthService,
    CompanyService,
    EventService,
    PositionService,
    PositionTypeService,
    ClassificationService) {
    this.statuses = ParticipantService.statuses();
    this.companies;
    this.$onInit = () => {
      this.newPosition = false;
      this.isGuestCompany = false;
      this.typeSelectedIsAvaliableToBeGuest = false;
      this.user = AuthService.getTokenData();
      this.isEditing = !!this.participant;
      this.step = this.participant ? 1 : 0;
      this.participant = this.participant || { status: "not invited", gender: "not informed", type: this.participantType };
      this.event = null;
      this.selectedActivities = [];
      this.executive = $routeParams.idCompany;
      this.types = this.user.role === 'organizer' ? ['staff'] : ['common', 'staff'];

      Loader.add($q.all([
        EventService.current(),
        CompanyService.list(),
        PositionService.list(),
        PositionTypeService.list(),
        ClassificationService.list(),
        ParticipantService.suggestionClassification(this.participant.id)
      ])).then(([event, companies, positions, positionsType, classifications, classificationSuggestion]) => {
        this.event = event;
        this.eventDays = EventService.getEventDays(this.event);

        if (this.company) {
          this.participant.company_id = this.company;
        }

        this.companies = companies.filter(c => c.type !== 'organizer');
        this.companiesOrganizer = companies.filter(c => ['organizer', 'stand_builder'].includes(c.type));
        this.positions = positions;
        this.positionsType = positionsType;
        this.classifications = classifications;
        if(classificationSuggestion) this.classificationSuggestion = classificationSuggestion;

        let participantCompany = this.companies.find(c => c.id === this.participant.company_id);
        if (participantCompany) {
          this.companyType = participantCompany.type;
        }

        this.disableIfGuestCompany();
      }).catch(err => Toast.httpHandler(err));
    };

    this.save = () => {
      this.form.$setSubmitted(true);
      if (this.form.$invalid) return;

      if (this.step === 0) {
        return Loader.add(ParticipantService.findByCpf(this.participant.cpf)).then(participant => {
          let participantCompany = this.companies.find(c => c.id === participant.company_id);
          if (participantCompany) {
            this.companyType = participantCompany.type;
          }

          this.form.$setSubmitted(false);
          this.form.$setPristine(true);

          if (participant) {
            delete participant.id;
            this.participant = participant;
            this.participant.status = 'not invited';
            if (this.company) {
              this.participant.company_id = this.company;
            }
          }
          this.step = 1;
          this.disableIfGuestCompany();
        });
      }

      this.participant.birth_date = this.participant.birth_date || null;
      this.participant.status = this.participant.type === 'staff' ? 'accepted' : this.participant.status;

      return Loader.add(ParticipantService.save(this.participant, this.send_email, this.company ? true : null)).then(result => {
        if (result.errorInvitation) {
          Toast.show("Participante criado com sucesso, mas o convite não foi enviado porque o evento ainda não possui um convite.", "success");
        } else if (result.errorSpotsUnavailable) {
          Toast.show("Participante criado com sucesso, mas não confirmado no evento pois não há vagas no evento.", "success");
        } else {
          Toast.show("Participante criado com sucesso", "success");
        }

        this.onCompleted({ '$status': 'form', $state: result.participant });
      }).catch(response => {
        const messages = {
          "cpf-registered": "CPF já cadastrado",
          "event-is-over-already": "Este evento já foi encerrado",
          "company-spot-exceeded": "A empresa não possui mais vagas no evento",
          "event-spot-exceeded": "O evento não possui mais vagas"
        };

        const message = messages[response.data.message];
        if (message) {
          return Toast.show(message, "error");
        }

        Toast.httpHandler(response);
      });
    };

    this.changeSelect = () => {
      let participantCompany = this.companies.find(c => c.id === this.participant.company_id);
      const invitedTypes = ['invited', 'government', 'entity', 'midia'];
      this.typeSelectedIsAvaliableToBeGuest = invitedTypes.includes(participantCompany.type);
      this.isGuestCompany = this.typeSelectedIsAvaliableToBeGuest;
      if (participantCompany) {
        this.companyType = participantCompany.type;
        this.companyType === 'associated' || this.companyType === 'commercial partner' ?
          this.participant.allows_meeting = true :
          this.participant.allows_meeting = false;
      }

      this.disableIfGuestCompany();
    };

    this.cancel = () => {
      this.onCancel();
    };

    this.editPosition = () => {
      this.participant.position = '';
      this.newPosition = true;
    };

    this.cancelEditPosition = () => {
      this.newPosition = false;
    };

    this.disableIfGuestCompany = () => {
      const companieValue = this.companies.find(c => c.id === this.participant.company_id);
      if (this.step === 1) {
        if (this.participant.type === 'staff') {
          if (!!(this.companiesOrganizer.find(c => c.id === this.participant.company_id)).name.toLowerCase() === 'convidado') {
            this.isGuestCompany = true;
          }
        } else if(this.typeSelectedIsAvaliableToBeGuest){
          this.isGuestCompany = true;
        } else if ( !!companieValue && companieValue.name.toLowerCase() === 'convidado') {
          this.isGuestCompany = true;
        } else {
          this.participant.guest_company = '';
          this.isGuestCompany = false;
        }
      }
    };
  }
})(angular || {});
