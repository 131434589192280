(angular => {
  "use strict";

  angular.module("EventCompanyEmployeeCredential").controller("EventCompanyEmployeeCredential.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventCompanyEmployeeCredentialService",
    "model",
    "employees",
    "event_id",
    FormCtrl
  ]);

  function FormCtrl($timeout, $mdDialog, loader, Toast, eventCompanyEmployeeCredentialService, model, employees, event_id) {
    this.model = model || {};
    this.employees = employees;
    this.event_id = event_id;

    this.save = () => {
      this.model.event_id = this.event_id;
      return loader.add(eventCompanyEmployeeCredentialService.save(this.model)).then(() => {
        Toast.show(`Credencial ${model ? "editada" : "cadastrada" } com sucesso`, "success");
        $mdDialog.hide(this.model);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
