(angular => {
  "use strict";

  angular.module("EventTableReservation").component("eventTableReservationForm", {
    templateUrl: 'admin/eventTableReservation/form/form.html',
    controller: [
      "$q",
      "$routeParams",
      "$filter",
      "Loader",
      "Toast",
      "$scope",
      "EventService",
      "EventHotelService",
      "EventTableReservationService",
      "ParticipantService",
      FormCtrl
    ]
  });

  function FormCtrl($q, $routeParams, $filter, Loader, Toast, $scope, eventService, eventHotelService, EventTableReservationService, participantService) {
    let lastSelectedTable;

    this.persons = [];

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      Loader.add(EventTableReservationService.list()).then(tables => {
        this.tables = tables;
      }).catch(err => Toast.httpHandler(err));
    };
    this.load();

    this.queryParticipant = term => {
      return participantService.query(term, null, null, 'accepted');
    };

    this.changeParticipant = participant => {
      if (!participant) return;

      this.persons = [{
        participant_id: participant.id,
        participant: participant,
        name: participant.name,
        document: participant.cpf,
        age: participant.birth_date ? moment().diff(participant.birth_date, 'year') : null,
        added: this.tables.some(r => r.persons.some(p => p.participant_id === participant.id && !p.dependent_id)),
        recreations: []
      }];

      if (!this.event.allow_relatives) return;

      participant.dependents.forEach(dependent => {
        this.persons.push({
          participant_id: participant.id,
          participant: participant,
          dependent_id: dependent.id,
          name: dependent.name,
          document: dependent.document,
          age: dependent.birth_date ? moment().diff(dependent.birth_date, 'year') : null,
          added: this.tables.some(r => r.persons.some(p => p.dependent_id === dependent.id)),
          recreations: []
        });
      });
    };

    this.formTable = ($event, table) => {
      return EventTableReservationService.formTable($event, table).then(() => {
        this.load();
      });
    };

    this.removeTable = table => {
      Loader.add(EventTableReservationService.removeTable(table.id)).then(() => {
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    this.formPerson = ($event, person, currentTable) => {
      currentTable = currentTable || lastSelectedTable;

      return EventTableReservationService.formPerson($event, this.tables, person, currentTable).then(() => {
        person.added = true;
        this.load();
      });
    };

    this.removePerson = (table, person) => {
      Loader.add(EventTableReservationService.remove(person.participant_id, person.dependent_id, table.id)).then(() => {
        this.load();
      }).catch(err => Toast.httpHandler(err));
    };

    this.isTableFull = table => {
      const spots = table.spots;
      const personCount = table.persons.filter(p => !p.age || p.age >= this.event.relatives_min_age).length;

      return spots <= personCount;
    };
  }
})(angular || {});
