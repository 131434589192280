(angular => {
  "use strict";

  angular.module("EventTableReservation").factory("EventTableReservationService", [
    "$http",
    "API",
    "$mdDialog",
    "FormRedirectService",
    EventTableReservationService
  ]);

  function EventTableReservationService($http, API, $mdDialog, FormRedirectService) {
    const list = () => {
      return $http.get(`${API}/event/table-reservation/`).then(res => res.data);
    };

    const loadCompanyTables = () => {
      return $http.get(`${API}/event/table-reservation/`).then(res => res.data);
    };

    const formTable = ($event, table) => {
      return $mdDialog.show({
        templateUrl: `admin/eventTableReservation/form-table/form-table.html`,
        controller: "EventTableReservation.FormTable",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          table: angular.copy(table),
        },
        targetEvent: $event
      });
    };

    const formPerson = ($event, tables, person, table) => {
      return $mdDialog.show({
        templateUrl: `admin/eventTableReservation/form-person/form-person.html`,
        controller: "EventTableReservation.FormPersonCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          tables: angular.copy(tables),
          person: angular.copy(person),
          table: angular.copy(table)
        },
        targetEvent: $event
      });
    };

    const save = (tableId, participantId, dependentId) => {
      return $http.post(`${API}/event/table-reservation`, { tableId, participantId, dependentId }).then(res => res.data);
    };

    const saveTable = model => {
      return $http.post(`${API}/event/table-reservation/table`, model).then(res => res.data);
    };

    const remove = (participantId, dependentId, tableId) => {
      return $http.delete(`${API}/event/table-reservation/${tableId}/${participantId}/${dependentId || ''}`).then(res => res.data);
    };

    const removeTable = tableId => {
      return $http.delete(`${API}/event/table-reservation/table/${tableId}`).then(res => res.data);
    };

    const exportList = () => {
      return FormRedirectService.submit(`${API}/event/table-reservation/export`);
    };

    return {
      list,
      loadCompanyTables,
      formTable,
      formPerson,
      save,
      saveTable,
      remove,
      removeTable,
      exportList
    };
  }
})(angular);
