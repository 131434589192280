(angular => {
  "use strict";

  angular.module("Common").component("paymentStatusIcon", {
    template: `
      <div ng-switch="$ctrl.status">
        <div ng-switch-when="pending">
          <md-icon md-svg-icon="clock"></md-icon>
        </div>
        <div ng-switch-when="analyzing">
          <md-icon md-svg-icon="dots-horizontal"></md-icon>
        </div>
        <div ng-switch-when="approved">
          <md-icon md-svg-icon="check" class="text-success"></md-icon>
        </div>
        <div ng-switch-when="available">
          <md-icon md-svg-icon="check-all" class="text-success"></md-icon>
        </div>
        <div ng-switch-when="contested">
          <md-icon md-svg-icon="dots-horizontal"  class="text-danger"></md-icon>
          <md-icon md-svg-icon="undo-variant"  class="text-danger"></md-icon>
        </div>
        <div ng-switch-when="refunded">
          <md-icon md-svg-icon="cash"></md-icon>
          <md-icon md-svg-icon="undo-variant"></md-icon>
        </div>
        <div ng-switch-when="cancelled">
          <md-icon md-svg-icon="close" class="text-danger"></md-icon>
        </div>
        <div ng-switch-when="exempted">
          <md-icon md-svg-icon="checkbox-blank-circle-outline" class="text-success"></md-icon>
        </div>
        <div ng-switch-when="manual-discharge">
          <md-icon md-svg-icon="check-all" class="text-success"></md-icon>
        </div>
        <div ng-switch-default>
          <md-icon md-svg-icon="help"></md-icon>
        </div>
      </div>
    `,
    controller: [
      Controller
    ],
    bindings: {
      status: '='
    }
  });

  function Controller() {

  }

})(angular);
