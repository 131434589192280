 (angular => {
   "use strict";

   angular.module("EventQuestion").controller("EventQuestion.FormQuestionCtrl", [
     "$mdDialog",
     "Loader",
     "Toast",
     "UserService",
     "EventQuestionService",
     "section",
     "question",
     FormQuestionCtrl
   ]);

   function FormQuestionCtrl($mdDialog, Loader, Toast, UserService, EventQuestionService, section, question) {
     this.question = question || { required: true };
     this.section = section;

     this.submitQuestion = () => {
       this.question.section_id = this.section.id;

       EventQuestionService.saveQuestion(this.question).then(() => {
         Toast.show("Pergunta salvo com sucesso", "success");
         $mdDialog.hide();
       }).catch(err => Toast.httpHandler(err));
     };

     this.cancel = $mdDialog.cancel;
   }
 })(angular || {});