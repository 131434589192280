(angular => {

  angular.module("EventAirTravel", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/passagens-aereas", {
        controller: "EventAirTravel.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventAirTravel/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'company_manager']
      })
      .when("/passagens-aereas/:companyId", {
        controller: "EventAirTravel.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventAirTravel/form/form.html`
      });
  }

})(angular);
