(function (angular, $, XLS) {
  'use strict';

  angular
    .module('Common')
    .factory('XLSReader', ['$q', 'Toast', 'Loader', service]);

  function service($q, Toast, Loader) {
    const normalizeNames = (data) => {
      const result = {};

      Object.keys(data).forEach((key) => {
        result[key] = (data[key] || '')
          .toLowerCase()
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
      });

      return result;
    };

    const schemas = {
      'participant-import': {
        header: [
          'company',
          'name',
          'credentialName',
          'cpf',
          'register',
          'uf',
          'email',
          'position',
          'enterprise_phone',
          'home_phone',
          'personal_phone',
          'birth_date',
          'guest_company',
          'position_type',
          'classification',
        ],
        isValid(data) {
          data[0] = normalizeNames(data[0]);

          const isValidated =
            data[0].company === 'rede' &&
            data[0].name === 'nome' &&
            data[0].credentialName === 'nome da credencial' &&
            data[0].cpf === 'cpf' &&
            data[0].register === 'matricula' &&
            data[0].uf === 'uf' &&
            data[0].email === 'email' &&
            data[0].position === 'cargo' &&
            data[0].enterprise_phone === 'telefone comercial' &&
            data[0].home_phone === 'telefone residencial' &&
            data[0].personal_phone === 'telefone celular' &&
            data[0].birth_date === 'data de nascimento' &&
            data[0].guest_company === 'empresa convidada' &&
            data[0].position_type === 'tipo de cargo' &&
            data[0].classification === 'classificacao';
          return isValidated;
        },
        invalidMessage() {
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  rede, nome, nome da credencial, cpf, matricula, uf, email, cargo, telefone comercial, telefone residencial, telefone celular, data de nascimento, empresa convidada, tipo de cargo e classificação`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
      'participant-presence-list': {
        header: [
          'nome',
          'nomeCredencial',
          'empresa',
          'cargo',
          'emailCorporativo',
          'assinatura',
          'cpf',
          'grau',
          'age',
          'participant',
        ],
        isValid(data) {
          data[2] = normalizeNames(data[2]);

          return (
            data[2].nome === 'nome' &&
            data[2].nomeCredencial === 'nome credencial' &&
            data[2].empresa === 'empresa' &&
            data[2].cargo === 'cargo' &&
            data[2].emailCorporativo === 'email corporativo' &&
            data[2].assinatura === 'assinatura' &&
            data[2].cpf === 'cpf'
          );
        },
        invalidMessage() {
          return `A TERCEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  Nome, Nome Credencial, Empresa, Cargo, Email Corporativo, Assinatura e CPF`;
        },
        getData(data) {
          const result = data.splice(3, data.length);

          if (data[2].participant === 'executivo') {
            return result.filter((d) => !d.participant);
          }

          return result;
        },
      },
      'guest-list': {
        header: ['nome', 'cargo', 'empresa', 'email', 'telefone', 'cpf'],
        isValid(data) {
          data[0] = normalizeNames(data[0]);

          return (
            data[0].nome === 'nome' &&
            data[0].cargo === 'cargo' &&
            data[0].empresa === 'empresa' &&
            data[0].email === 'email' &&
            data[0].telefone === 'telefone' &&
            data[0].cpf === 'cpf'
          );
          // data[0].email === "email" &&
          // data[0].emailGestor === "email do gestor";
        },
        invalidMessage() {
          // return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
          //         Nome, Cargo, Empresa, Email e Email do Gestor`;
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  Nome, Cargo, Empresa, Email, Telefone e CPF`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
      'guest-list-public': {
        header: ['nome', 'cargo', 'empresa', 'email', 'telefone', 'cpf'],
        isValid(data) {
          data[0] = normalizeNames(data[0]);

          return (
            data[0].nome === 'nome' &&
            data[0].cargo === 'cargo' &&
            data[0].empresa === 'empresa' &&
            data[0].email === 'email' &&
            data[0].telefone === 'telefone' &&
            data[0].cpf === 'cpf'
          );
        },
        invalidMessage() {
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  Nome, Cargo, Empresa, Email, Telefone e CPF`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
      'participant-restricted-list': {
        header: ['nome', 'cpf', 'empresa'],
        isValid(data) {
          data[0] = normalizeNames(data[0]);

          return (
            data[0].nome === 'nome' &&
            data[0].cpf === 'cpf' &&
            data[0].empresa === 'empresa'
          );
        },
        invalidMessage() {
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  Nome, CPF, Empresa`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
      'benefits-import': {
        header: [
          'descricaoDoEvento',
          'descricaoDoItem',
          'acao',
          'classificacao',
          'valorDoItem',
          'green',
          'blue',
          'gold',
          'platinum',
          'black',
        ],
        isValid(data) {
          data[0] = normalizeNames(data[0]);
          const isValidated =
            data[0].descricaoDoEvento === 'descricao do evento' &&
            data[0].descricaoDoItem === 'descricao do item' &&
            data[0].acao === 'acao' &&
            data[0].classificacao === 'classificacao' &&
            data[0].valorDoItem === 'valor do item' &&
            data[0].green === 'green' &&
            data[0].blue === 'blue' &&
            data[0].gold === 'gold' &&
            data[0].platinum === 'platinum' &&
            data[0].black === 'black';
          return isValidated;
        },
        invalidMessage() {
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                  Descrição do evento, Descrição do item, ação, classificação,  valor do item, green, blue, gold, platinum e black`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
      'participant-list': {
        header: [
          'nomeSala',
          'dataAcesso',
          'nome',
          'empresa',
          'empresaConvidada',
          'cargo',
          'token',
          'dataEvento',
          'cadastroNovo',
          'cpf',
        ],
        isValid(data) {
          data[0] = normalizeNames(data[0]);
          const isValidated =
            data[0].nomeSala === 'nome da sala' &&
            data[0].dataAcesso === 'data de acesso' &&
            data[0].nome === 'nome' &&
            data[0].empresa === 'empresa' &&
            data[0].empresaConvidada === 'empresa convidada' &&
            data[0].cargo === 'cargo' &&
            data[0].token === 'token' &&
            data[0].dataEvento === 'data evento' &&
            data[0].cadastroNovo === 'cadastro novo' &&
            data[0].cpf === 'cpf';
          return isValidated;
        },
        invalidMessage() {
          return `A PRIMEIRA linha do arquivo deve conter o cabeçalho com a sequência:
                 nome da sala, data de acesso, nome, empresa, empresa convidada, cargo, token, data evento, cadastro novo, cpf`;
        },
        getData(data) {
          return data.splice(1, data.length);
        },
      },
    };

    const readFile = (defered, type, event) => {
      const workbook = XLS.read(event.target.result, {
        type: 'binary',
        cellDates: true,
      });

      const sheetName = workbook.SheetNames[0];
      const schema = schemas[type];

      if (!schema) {
        return defered.reject('Tipo de formato não encontrado');
      }

      const data = XLS.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: schema.header,
        raw: true,
      });

      if (schema.isValid(data)) {
        return defered.resolve(schema.getData(data));
      }

      Toast.show(schema.invalidMessage(), 'error');
      return defered.reject(schema.invalidMessage());
    };

    const loadFile = (type) => {
      const defered = $q.defer();

      $(`<input type="file" accept=".xls,.xlsx" />`)
        .on('change', ($e) => {
          const file = $e.target.files[0];
          const reader = new FileReader();

          reader.onloadend = (event) => {
            try {
              Loader(readFile(defered, type, event));
            } catch (err) {
              defered.reject('Arquivo não suportado');
            }
          };

          reader.readAsBinaryString(file);
        })
        .trigger('click');

      return defered.promise;
    };

    return {
      loadFile,
    };
  }
})(angular, jQuery, XLS);
