(angular => {

  angular.module("EventQuestion")

    .filter("questionType", [QuestionTypeFilter]);

  function QuestionTypeFilter() {

    const types = {
      "text": "Texto",
      "multiple-choice": "Múltipla Escolha"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);