(angular => {
  "use strict";

  angular.module("EventAccess").factory("EventAccessService", [
    "$http",
    "$mdDialog",
    "API",
    "FormRedirectService",
    EventAccessService
  ]);

  function EventAccessService($http, $mdDialog, API, formRedirectService) {

    const list = () => $http
      .get(`${API}/event-access`)
      .then(({ data }) => data);

    const listAllowed = (activityId) => $http
      .get(`${API}/event-access/allowed/${activityId}`)
      .then(({ data }) => data);

    const allowedModal = (access, $event) => {
      return $mdDialog.show({
        templateUrl: `admin/eventAccess/allowed/allowed.html`,
        controller: "EventAccess.AllowedCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          access: angular.copy(access)
        },
        targetEvent: $event
      });
    };

    const exportAccess = ({ activityId, date }) => {
      const [day, month] = date.split('/');
      return formRedirectService.submit(`${API}/event-access/export`, {
        activityId,
        day: Number(day),
        month: Number(month)
      });
    };

    return {
      list,
      listAllowed,
      allowedModal,
      exportAccess
    };
  }
})(angular);
