 (angular => {
   "use strict";

   angular.module("EventQuestion").controller("EventQuestion.FormSectionCtrl", [
     "$mdDialog",
     "Loader",
     "Toast",
     "EventQuestionService",
     "section",
     FormSectionCtrl
   ]);

   function FormSectionCtrl($mdDialog, Loader, Toast, EventQuestionService, section) {
     this.section = section;

     this.submitSection = () => {
       EventQuestionService.saveSection(this.section).then(() => {
         Toast.show("Seção salvo com sucesso", "success");
         $mdDialog.hide();
       }).catch(err => Toast.httpHandler(err));
     };

     this.cancel = $mdDialog.cancel;
   }
 })(angular || {});
