(angular => {

  angular.module("EventCompanyEmployee")
    .filter("typesProvider", [CompanyTypeFilter]);

  function CompanyTypeFilter() {

    const types = {
      "standBuilder": "Montadora",
      "agency": "Agência"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
