((angular) => {
  'use strict';

  angular.module('Common').directive('hideRoles', [
    '$rootScope',
    'AuthService',
    Directive
  ]);

  function Directive($rootScope, authService) {

    return {
      restrict: 'A',
      scope: false,
      link: ($scope, elem, attrs) => {
        const roles = _.flattenDeep([attrs.hideRoles.split(','), ['admin']]);
        const change = () => {
          const user = authService.getTokenData();

          if (!user) return elem.hide();
          if (!roles.length || roles.some(r => r === user.role)) return elem.hide();

          elem.show();
        };

        $rootScope.$on("login", change);
        change();
      }
    };

  }

})(angular);
