(angular => {

  angular.module("Common").filter("cpfcnpj", [
    filter
  ]);

  function filter() {
    return value => {
      if (!value) return;

      if (value.length <= 11) {
        value = _.padStart(value, 11, '0');
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (value.length === 14) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }

      return value;
    };
  }

})(angular);
