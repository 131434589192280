(angular => {
  "use strict";

  angular.module("User").controller("User.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "UserService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, UserService) {
    let queryTimeout = null;

    this.promise = null;
    this.query = {
      orderBy: "name",
      page: 1,
      limit: 25
    };

    this.users = null;

    this.loadData = () => {
      const query = angular.copy(this.query);
      this.promise = UserService.list(query).then(users => {
        this.users = users;
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, user) => {
      UserService.form($event, user).then(() => {
        this.loadData();
      });
    };

    this.remove = ($event, user) => {
      Loader.add(UserService.remove(user)).then(() => {
        Toast.show("Usuário excluído com sucesso", "success");
        this.loadData();
        return user;
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadData(), 500);
    }, true);

  }

})(angular || {});
