((angular) => {
  'use strict';

  angular
    .module('App')
    .controller('AppCtrl', [
      '$rootScope',
      '$scope',
      '$mdSidenav',
      '$mdMedia',
      '$mdDialog',
      'AuthService',
      'EventService',
      'EventCompanyService',
      '$q',
      'CompanyService',
      AppCtrl,
    ]);

  function AppCtrl(
    $rootScope,
    $scope,
    $mdSidenav,
    $mdMedia,
    $mdDialog,
    AuthService,
    eventService,
    eventCompanyService,
    $q,
    CompanyService
  ) {
    this.airTravelVisible = true;
    this.accommodationVisible = true;
    this.show = false;
    this.showPartnerNotBussinesClub = false;
    this.showPartnerBussinesClub = false;
    this.notBussinesClub = false;
    this.token = !!window.location.hash.split('?token=')[1];

    const updateLoginStatus = () => {
      this.isAuthenticated = AuthService.isLoggedIn();
      this.user = AuthService.getTokenData();

      if (this.user) {
        this.role = this.user.role;
        this.associated =
          this.user.companyTypes.find((p) => p === 'associated') !== undefined;
        this.comercialPartner =
          this.user.companyTypes.find((p) => p === 'commercial partner') !==
          undefined;
        this.verifySponsor();
      }

      if (!this.isAuthenticated) return;
      this.verifySpotsHideItens();
      this.loadEvent(this.user.eventId);
    };

    this.verifySponsor = () => {
      // console.log(this.user);
      return (this.promise = $q
        .all([CompanyService.get(this.user.companyIds[0])])
        .then((company) => {
          if (
            company[0].sponsorship_type !== null &&
            company[0].sponsorship_type.name !== null
          ) {
            this.notBussinesClub =
              company[0].sponsorship_type.name !== 'BUSINESS CLUB';
            this.bussinesClub =
              company[0].sponsorship_type.name === 'BUSINESS CLUB';
          } else {
            this.notBussinesClub =
              company[0].sponsorship_type === null ||
              company[0].sponsorship_type.name !== 'BUSINESS CLUB';
          }
        })
        .catch((err) => console.error(err)));
    };

    this.verifySpotsHideItens = () => {
      eventCompanyService.list().then((eventCompanies) => {
        if (this.user.companyIds && this.user.companyIds.length) {
          this.eventCompanies = eventCompanies.filter((e) =>
            this.user.companyIds.includes(e.company_id)
          );
          this.airTravelVisible = this.eventCompanies.some(
            (e) => e.air_travel_spots > 0
          );
          this.accommodationVisible = this.eventCompanies.some(
            (e) => e.hotel_rooms > 0
          );
        }
      });
    };

    this.loadEvent = (eventId) => {
      eventService
        .get(eventId)
        .then((resp) => {
          this.event = resp;

          const formattedToday = this.formatTodayDate();
          const endDate = resp.end_date.substring(0, 10).split('-').join('');

          if (
            this.event.show_marketing_activity === true &&
            endDate >= formattedToday &&
            this.associated
          ) {
            this.show = true;
          } else {
            this.show = false;
          }

          if (
            this.event.show_marketing_activity === true &&
            endDate >= formattedToday &&
            this.comercialPartner &&
            this.notBussinesClub
          ) {
            this.showPartnerNotBussinesClub = true;
          } else {
            this.showPartnerNotBussinesClub = false;
          }

          if (
            this.event.show_marketing_activity === true &&
            endDate >= formattedToday &&
            this.comercialPartner &&
            this.bussinesClub
          ) {
            this.showPartnerBussinesClub = true;
          } else {
            this.showPartnerBussinesClub = false;
          }
        })
        .catch((err) => console.error(err));
    };

    this.formatTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = (today.getDate() < 10 ? '0' : '') + today.getDate();

      return year + (month < 10 ? '0' : '') + month + day;
    };

    $scope.$on('login', updateLoginStatus);
    $scope.$on('logout', updateLoginStatus);
    $scope.$on('event-change', (info, event) => (this.event = event));

    updateLoginStatus();

    this.toggleSidenav = (menuId) => $mdSidenav(menuId).toggle();
    this.hideSidebar = () => $mdMedia('min-width: 991px');

    eventService.current().then((event) => (this.event = event));

    this.passwordReset = ($event) => {
      $mdDialog.show({
        templateUrl: `admin/app/change-password/change-password.html`,
        controller: 'App.PasswordChangeCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: false,
        escapeToClose: false,
        targetEvent: $event,
      });
    };
  }
})(angular);
