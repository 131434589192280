(angular => {
  "use strict";

  angular.module("Activity")
    .controller("Activity.FormConfirmPresenceCtrl", ["$mdDialog", "Toast", "Loader", "ActivityService", "event", FormConfirmPresenceCtrl]);

  function FormConfirmPresenceCtrl($mdDialog, Toast, Loader, ActivityService, event) {
    this.eventId = event.id;
    this.activity = {};
    this.eventDays = getEventDays(event);
    this.dateEventFind = this.eventDays[0];

    this.confirmPresence = () => {
      Loader.add(ActivityService.confirmPresence(this.eventId, this.dateEventFind)).then(() => {

        Toast.show("Presença confirmada com sucesso", "success");
        $mdDialog.hide();

      }).catch(error => {
        if (error.message === 'error-service-confirm-presence') {
          return Toast.show('Erro ao confirmar presença', "error");
        }
        Toast.httpHandler(error);
        $mdDialog.hide();
      });

    };

    this.cancel = () => {
      $mdDialog.hide();
    };

    function getEventDays(event) {
      let eventDays = [];
      let currentDate = moment(event.start_date);
      let endDate = moment(event.end_date);

      eventDays.push(angular.copy(currentDate));

      while (currentDate.isBefore(endDate, 'day')) {
        currentDate.add(1, "days");
        eventDays.push(angular.copy(currentDate));
      }

      eventDays = eventDays.map(d => {
        return d.toDate();
      });

      return eventDays;
    }

  }
})(angular || {});
