(angular => {

  angular.module("EventCompanyEmployee", ["Config"]).config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/prestadores", {
        controller: "EventCompanyEmployee.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCompanyEmployee/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'company_manager', 'organizer', 'stand_builder', 'coordinator']
      })
      .when("/prestadores/:exhibitorId", {
        controller: "EventCompanyEmployee.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCompanyEmployee/form/form.html`
      });
  }

})(angular);
