(angular => {
  "use strict";

  angular.module("EmailMarketing").controller("EmailMarketing.HistoricCtrl", [
    "$timeout",
    "$mdDialog",
    "$scope",
    "$sce",
    "Loader",
    "Toast",
    "lodash",
    "dateHelper",
    "EmailMarketingService",
    "CompanyService",
    "id",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, $scope, $sce, loader, Toast, lodash, dateHelper, emailMarketingService, companyService, id) {
    let queryTimeout = null;
    this.pagination = { page: 1, limit: 10 };

    this.loadEmails = () => {
      loader.add(emailMarketingService.findByIdHistoric(id)).then(emailHistoric => {
        this.emailHistoric = emailHistoric;
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

    $scope.$watch(() => this.emails, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadEmails(), 500);
    }, true);
  }
})(angular || {});
