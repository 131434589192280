(angular => {
  "use strict";

  angular.module("EventGuest").controller("EventGuest.DisapproveCtrl", [
    "$mdDialog",
    "Loader",
    "Toast",
    "EventGuestService",
    "guest",
    "selected",
    DisapproveCtrl
  ]);

  function DisapproveCtrl($mdDialog, loader, toast, eventGuestService, guest, selected) {
    this.model = { reason: '' };
    this.reasons = ['VAGAS EXCEDIDAS', 'EXCEDE QUANTIDADE DE CATEGORIA', 'EMAIL INVÁLIDO', 'PARCEIRO COMERCIAL', 'INSCRIÇÃO JÁ CONFIRMADA'];
    this.submit = () => {

      if (guest) {
        loader.add(eventGuestService.disapprove([guest.id], this.model.reason)).then(() => {
          $mdDialog.hide();
          toast.show("Convite rejeitado com sucesso", "success");
        }).catch(err => toast.httpHandler(err));
        return;
      }

      selected = selected.map(g => g.id);
      loader.add(eventGuestService.disapprove(selected, this.model.reason)).then(() => {
        $mdDialog.hide();
        toast.show("Convites rejeitados com sucesso", "success");
      }).catch(err => toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }

})(angular);
