(angular => {
  "use strict";


  angular.module("EventHotel").component("eventHotelList", {
    templateUrl: 'admin/eventHotel/index/list/list.html',
    controller: [
      '$scope',
      "Loader",
      "Toast",
      "EventService",
      "EventHotelService",
      ListCtrl
    ]
  });

  function ListCtrl($scope, Loader, Toast, eventService, eventHotelService) {

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadHotel = () => {
      return Loader.add(eventHotelService.list()).then(hotels => {
        this.hotels = hotels;
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadHotel();

    this.form = ($event, hotel) => {
      eventHotelService.form($event, hotel).then(() => {
        this.loadHotel();
      });
    };

    this.formRecreation = ($event, hotel, recreation) => {
      eventHotelService.formRecreation($event, hotel, recreation).then(() => {
        this.loadHotel();
      });
    };

    this.remove = ($event, hotel) => {
      Loader.add(eventHotelService.remove(hotel.id)).then(() => {
        Toast.show("Hotel excluído com sucesso", "success");
        this.loadHotel();
        return hotel;
      }).catch(err => Toast.httpHandler(err));
    };

    this.removeRecreation = ($event, recreation) => {
      Loader.add(eventHotelService.removeRecreation(recreation.id)).then(() => {
        Toast.show("Atividade excluída com sucesso", "success");
        this.loadHotel();
      }).catch(err => Toast.httpHandler(err));
    };


    $scope.$on('add-hotel', (info, $event) => {
      this.form($event);
    });

  }
})(angular || {});
