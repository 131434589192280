(angular => {

  angular.module("EventHotel").filter("hotelRoomType", [
    "EventHotelService",
    HotelRoomTypeFilter
  ]);

  function HotelRoomTypeFilter(eventHotelService) {
    const types = eventHotelService.getTypes(true).reduce((acc, type) => {
      acc[type.type] = type.display;
      return acc;
    }, {});

    return type => {
      return types[type] || type;
    };
  }

})(angular);
