(angular => {
  "use strict";

  angular.module("Classification").controller("Classification.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "ClassificationService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, ClassificationService) {
    let queryTimeout = null;

    this.promise = null;
    this.classificationsToView = null;
    this.classifications = null;

    this.query = {
      orderBy: "name",
      page: 1,
      limit: 25
    };

    this.loadData = () => {
      const query = angular.copy(this.query);
      this.promise = ClassificationService.list(query).then(classif => {
        this.classificationsToView = classif.map((classification) => ({
            id: classification.id,
            name: classification.name,
            position_types: classification.position_types,
            company_types: classification.company_types,
            sponsorship_types: classification.sponsorship_types,
            position_types_string: parseObjetctoString(classification.position_types),
            company_types_string: parseObjetctoString(classification.company_types),
            sponsorship_types_string: parseObjetctoString(classification.sponsorship_types),
        }));

        this.classifications = classif;
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = (classification) => {
      ClassificationService.form(classification).then(() => {
        this.loadData();
      });
    };

    this.remove = (classification) => {
      const errors = {};
      Loader.add(ClassificationService.remove(classification)).then(() => {
        Toast.show("Classificação excluída com sucesso", "success");
        this.loadData();
        return classification;
      }).catch(err => {
        if (errors[err.data && err.data.message]) {
          return Toast.show(errors[err.data.message], 'error');
        }
        Toast.httpHandler(err);
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadData(), 500);
    }, true);

  }

  const parseObjetctoString = (array) => {
    return array.map((element) => element.name).sort().toString().replace(/,/g,', ');
  };

})(angular || {});
