(angular => {
  "use strict";

  angular.module("EventCost")

    .factory("EventCostService", ["$q", "$http", "$mdDialog", "API", "AuthService", EventCostService]);

  function EventCostService($q, $http, $mdDialog, API) {


    const list = () => {
      return $http.get(`${API}/event/costs`)
        .then(res => res.data);
    };

    const get = (costId) => {
      return $http.get(`${API}/event/costs/${costId}`)
        .then(res => res.data);
    };

    const pay = (parcel_id, date) => {
      return $http.post(`${API}/event/costs/pay`, { parcel_id, date }).then(res => res.data);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventCost/view/form.html`,
        controller: "EventCost.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const save = cost => {
      return $http.post(`${API}/event/costs`, cost)
        .then(res => res.data);
    };

    const remove = (costId) => {
      return $http.delete(`${API}/event/costs/${costId}`);
    };

    const totalCosts = (costs) => {
      const totalBudget = costs.reduce((prev, cost) => prev + cost.budget_price, 0);
      const totalCosts = costs.reduce((prev, cost) => prev + cost.final_price, 0);
      const total = { totalBudget, totalCosts };
      return Promise.resolve(total);
    };


    return {
      list,
      pay,
      save,
      get,
      remove,
      form,
      totalCosts
    };
  }
})(angular);
