(angular => {
  "use strict";

  angular.module("App")
    .controller("App.LoginModalCtrl", ["$mdDialog", "$location", "Toast", "Loader", "LoginService", "AuthService", LoginModalCtrl]);

  function LoginModalCtrl($mdDialog, $location, Toast, Loader, LoginService) {
    this.user = {};

    this.login = () => {
      Loader.add(LoginService.doLogin(this.user)).then(() => {
        $mdDialog.hide();
      }).catch((err) => {
        this.error = err.data.message || "Falha ao efetuar login";
      });
    };

    this.cancel = LoginService.cancel;
  }

})(angular);
