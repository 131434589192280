(angular => {

  angular.module("Company")

    .filter("companyStatus", [CompanyTypeFilter]);

  function CompanyTypeFilter() {

    const types = {
      "active": "Ativa",
      "inactive": "Inativa"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
