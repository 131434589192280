(angular => {
  "use strict";


  angular.module("Contract").controller("Contract.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "ContractService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, eventService, contractService) {
    let queryTimeout = null;
    this.promise = null;

    eventService.current().then(event => {
      this.event = event;
    });

    this.modelForm = ($event, model) => {
      contractService.formModel(model).then(() => this.load());
    };

    this.load = () => {
      this.promise = contractService.list().then(contracts => {
        this.models = contracts;
      }).catch(err => Toast.httpHandler(err));
    };

    this.remove = ($event, model) => {
      Loader.add(contractService.remove(model.id)).then(() => {
        Toast.show("Modelo excluído com sucesso", "success");
        this.load();
      }).catch(err => {
        Toast.show(err.data.message, "error");
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.load(), 500);
    }, true);

  }
})(angular || {});
