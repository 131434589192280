 (angular => {
   "use strict";

   angular.module("EventHotel").controller("EventHotel.FormHotelCtrl", [
     "$mdDialog",
     "Loader",
     "Toast",
     "EventHotelService",
     "hotel",
     FormHotelCtrl
   ]);

   function FormHotelCtrl($mdDialog, Loader, Toast, eventHotelService, hotel) {
     this.hotel = hotel || {};
     this.hotel.municipal_tax = hotel ? Number(hotel.municipal_tax) : null;

     this.submit = () => {
       eventHotelService.save(this.hotel).then(() => {
         Toast.show("Hotel salvo com sucesso", "success");
         $mdDialog.hide();
       }).catch(err => Toast.httpHandler(err));
     };

     this.cancel = $mdDialog.cancel;
   }

 })(angular || {});
