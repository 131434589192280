(angular => {
  "use strict";

  angular.module("EventChecklist").controller("EventChecklist.ListCtrl", [
    "$mdDialog",
    "$mdpDatePicker",
    "$scope",
    "Loader",
    "Toast",
    "AuthService",
    "EventService",
    "EventChecklistExhibitorService",
    "EventChecklistAssociateService",
    ListCtrl
  ]);

  function ListCtrl($mdDialog, $mdpDatePicker, $scope, Loader, Toast, authService, eventService, eventChecklistExhibitorService, eventChecklistAssociateService) {
    const user = authService.getTokenData();
    this.showFirstTab = ['admin', 'user', 'organizer', 'coordinator'].includes(user.role);
    const validRoles = ['company_manager'];
    const validServices = [eventChecklistAssociateService];

    this.services = [
      { implementation: eventChecklistAssociateService, label: 'Associados', allow: 'associated' },
      { implementation: eventChecklistExhibitorService, label: 'Expositores', allow: 'exhibitor' },
      { implementation: eventChecklistExhibitorService, label: 'Expositores Parceiros Comerciais', allow: 'commercial partner'}
    ];

    if (user.role === 'stand_builder') {
      this.services = [
        { implementation: eventChecklistExhibitorService, label: 'Expositores Parceiros Comerciais', allow: 'exhibitor' },
        { implementation: eventChecklistExhibitorService, label: 'Expositores', allow: 'exhibitor' }
      ];
    }

    if (!['admin', 'organizer', 'coordinator'].includes(user.role)) {
      this.services = this.services.filter(service => {     
        return user.companyTypes.includes(service.allow);
      });
    }

    this.service = this.services[0] || { implementation: eventChecklistAssociateService };
    this.service = this.service.implementation;

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadChecklists = () => {

      this.promise = this.service.list().then(checklist => {
        this.eventChecklists = checklist;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadChecklists();

    this.loadCompanyChecklist = () => {
      this.promise = this.service.listCompanyChecklist().then(companyChecklists => {       
        this.companyChecklists = companyChecklists;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadCompanyChecklist();

    const canChange = () => {

      this.canChange = validServices.indexOf(this.service) !== -1 &&
        validRoles.indexOf(user.role) !== -1;
      return this.canChange;
    };
    canChange();

    this.changeChecklistFor = () => {
      this.canChange = canChange();
      this.loadChecklists();
      this.loadCompanyChecklist();
    };

    this.form = ($event, model) => {
      this.service.form($event, model, this.service).then(() => {
        this.loadChecklists();
        this.loadCompanyChecklist();
      });
    };

    this.remove = (checklist) => {
      Loader.add(this.service.remove(checklist.id)).then(() => {
        Toast.show("Checklist excluído com sucesso", "success");
        this.loadChecklists();
        this.loadCompanyChecklist();
      }).catch(err => Toast.httpHandler(err));
    };

    this.changeStatus = ($event, userChecklist) => {

      if (!this.canChange) {
        return;
      }

      let datePicker = $mdpDatePicker;
      if (userChecklist.status) {
        datePicker = (value) => Promise.resolve(value);
      }

      datePicker(new Date(), { targetEvent: $event })
        .then(date => Loader.add(this.service.changeStatus(userChecklist.id, date, !userChecklist.status)))
        .then(() => {
          Toast.show('Checklist atualizado com sucesso', 'success');
          this.loadCompanyChecklist();
        });
    };

    $scope.$watch('headerTabs.current', value => {
      this.currentTab = value;
    });

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
