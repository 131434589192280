(angular => {
  "use strict";

  angular.module("EventQuestion").component("eventQuestionListQuestion", {
    templateUrl: 'admin/eventQuestion/list/components/questions.html',
    controller: [
      "$scope",
      "$filter",
      "$timeout",
      "Loader",
      "Toast",
      "EventService",
      "EventQuestionService",
      Controller
    ]
  });

  function Controller($scope, $filter, $timeout, Loader, Toast, eventService, eventQuestionService) {
    $scope.$on('add-section', (info, $event) => {
      $scope.$emit('change-header-tab-current', 1);
      this.formSection($event, null);
    });

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadSections = () => {
      return Loader.add(eventQuestionService.list()).then(sections => {
        this.sections = sections;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadSections();

    this.formSection = ($event, section) => {
      eventQuestionService.formSection($event, section).then(() => {
        this.loadSections();
      });
    };

    this.moveSection = (direction, $index) => {
      const amount = direction === 'up' ? -1 : 1;

      $timeout(() => {
        const sections = $filter('orderBy')(this.sections, 'order');
        sections[$index].order += amount;
        sections[$index + amount].order += amount * -1;

        const data = sections.map(q => ({ id: q.id, order: q.order }));
        Loader.add(eventQuestionService.saveSectionOrder(data)).catch(err => Toast.httpHandler(err));
      }, 500);
    };

    this.removeSection = ($event, section) => {
      Loader.add(eventQuestionService.removeSection(section.id)).then(() => {
        Toast.show("Seção excluída com sucesso", "success");
        this.loadSections();
        return section;
      }).catch(err => Toast.httpHandler(err));
    };

    this.formQuestion = ($event, section, question) => {
      eventQuestionService.formQuestion($event, section, question).then(() => {
        this.loadSections();
      });
    };

    this.moveQuestion = (direction, questions, $index) => {
      const amount = direction === 'up' ? -1 : 1;

      $timeout(() => {
        questions = $filter('orderBy')(questions, 'order');
        questions[$index].order += amount;
        questions[$index + amount].order += amount * -1;

        const data = questions.map(q => ({ id: q.id, order: q.order }));
        Loader.add(eventQuestionService.saveQuestionOrder(data)).catch(err => Toast.httpHandler(err));
      }, 500);
    };

    this.removeQuestion = ($event, question) => {
      Loader.add(eventQuestionService.removeQuestion(question.id)).then(() => {
        Toast.show("Pergunta excluída com sucesso", "success");
        this.loadSections();
        return question;
      }).catch(err => Toast.httpHandler(err));
    };

    this.publish = () => {
      Loader.add(eventQuestionService.publish(!this.event.publish_question)).then(() => {
        this.event.publish_question = !this.event.publish_question;
      }).catch(err => Toast.httpHandler(err));
    };

  }

})(angular);
