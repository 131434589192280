((angular) => {
  'use strict';

  angular
    .module('Event')
    .controller('Event.SelectCtrl', [
      '$mdDialog',
      '$scope',
      '$timeout',
      'Loader',
      'Toast',
      'EventService',
      'AuthService',
      SelectCtrl,
    ]);

  function SelectCtrl(
    $mdDialog,
    $scope,
    $timeout,
    loader,
    toast,
    eventService,
    authService
  ) {
    let queryTimeout = null;
    this.events = [];
    this.originalEvents = [];

    this.user = authService.getTokenData();
    this.currentTab = 0;
    this.query = {
      orderBy: '-start_date',
      page: 1,
      limit: 25,
      year: new Date().getFullYear(),
    };

    this.tabs = [
      { type: 'open', label: 'Abertos' },
      { type: 'closed', label: 'Encerrados' },
      { type: 'canceled', label: 'Cancelados' },
    ];

    this.loadData = () => {
      this.promise = eventService
        .list(this.query)
        .then((events) => {
          // TODO: remove
          if (this.user.role === 'company_manager') {
            events = events.filter((x) =>
              [
                'Future Trends 2017',
                'FUTURE TRENDS 2018',
                'FUTURE TRENDS 2019',
                'FUTURE TRENDS 2020 - Digital',
                'FUTURE TRENDS 2020 - online',
                'FUTURE TRENDS 2020 - Edição Online',
                'MASTER CLASS 2020 - Edição Online',
                'FUTURE TRENDS 2021 - Edição Online',
                'FUTURE TRENDS 2022',
                'FUTURE TRENDS 2023',
              ].includes(x.title)
            );
          }

          this.originalEvents = !this.query.year
            ? events
            : eventService.getEventsByYear(this.query.year, events);
          this.filterByStatus();

          this.eventYears = eventService.getYears(events);
        })
        .catch((err) => toast.httpHandler(err));
    };

    this.select = (event) => {
      $mdDialog.hide(event);
    };

    this.filterByStatus = () => {
      this.events = this.originalEvents.filter(
        (e) => e.status === this.tabs[this.currentTab].type
      );
    };

    $scope.$watch(
      () => this.query,
      () => {
        $timeout.cancel(queryTimeout);
        queryTimeout = $timeout(() => this.loadData(), 500);
      },
      true
    );

    $scope.$watch('$ctrl.currentTab', () => {
      this.filterByStatus();
    });

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
