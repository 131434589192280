(angular => {
  "use strict";

  angular.module("EventTax").factory("EventTaxService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "FormRedirectService",
    EventTaxService
  ]);

  function EventTaxService($q, $http, $mdDialog, API, FormRedirectService) {

    const list = () => {
      return $http.get(`${API}/event/taxs`).then(res => res.data);
    };

    const listOrders = () => {
      return $http.get(`${API}/event/taxs/company-order`).then(res => res.data);
    };

    const updateOrderStatus = (orderId, status) => {
      return $http.post(`${API}/event/taxs/company-order/change-status`, { orderId, status }).then(res => res.data);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventTax/form/form.html`,
        controller: "EventTax.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const orderHistory = ($event, order) => {
      return $mdDialog.show({
        templateUrl: `admin/eventTax/order-history/order-history.html`,
        controller: "EventTax.OrderHistoryCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          order: angular.copy(order)
        }
      });
    };

    const formOrder = $event => {
      return $mdDialog.show({
        templateUrl: `admin/eventTax/index/order/form/form.html`,
        controller: "EventTax.OrderFormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscereen: true,
        targetEvent: $event
      });
    };

    const taxes = () => {
      return $http.get(`${API}/event/taxs/company-order/taxes`).then(res => res.data);
    };

    const save = tax => {
      return $http.post(`${API}/event/taxs`, tax).then(res => res.data);
    };

    const saveOrders = (order) => {
      return $http.post(`${API}/event/taxs/company-order`, order).then(res => res.data);
    };

    const generateTaxs = taxs => {
      return $http.post(`${API}/event/taxs/generate-taxs`, taxs).then(res => res.data);
    };

    const remove = (taxId) => {
      return $http.delete(`${API}/event/taxs/${taxId}`);
    };

    const exportOrders = () => {
      return FormRedirectService.submit(`${API}/event/taxs/export-company-order`);
    };

    const payOrder = (orderId) => {
      return $http.get(`${API}/event/taxs/pay/${orderId}/${window.PagSeguroDirectPayment.getSenderHash()}`).then(res => res.data);
    };

    return {
      list,
      listOrders,
      updateOrderStatus,
      save,
      saveOrders,
      remove,
      form,
      orderHistory,
      generateTaxs,
      exportOrders,
      formOrder,
      taxes,
      payOrder
    };
  }
})(angular);
