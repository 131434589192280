(angular => {
  "use strict";

  angular.module("EffectiveRevenues").component("revenuesCostsChart", {
    template: `<div id="revenues-costs-chart" ng-style="{width: $ctrl.width, height: $ctrl.height}">`,
    controller: [
      'EffectiveRevenuesService',
      'c3',
      '$filter',
      "$scope",
      RevenueCostChart
    ],
    controllerAs: '$ctrl',
    bindings: {
      data: '=',
      width: '@',
      height: '@'
    }
  });

  function RevenueCostChart(EffectiveRevenuesService, c3, $filter, $scope) {

    let chart;
    let years;
    let grouped;

    const getColumnNames = () => {
      return grouped ?
        years : ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
    };

    const getColumnSize = () => getColumnNames().length + 1;

    const create = title => {
      const column = Array.from({ length: getColumnSize() }, () => 0);
      column[0] = title;
      return column;
    };

    const getYearPosition = year => years.indexOf(year) + 1;
    const getMonthPosition = month => month + 1;

    const getPosition = ({ year, month }) => {
      return grouped ?
        getYearPosition(year) :
        getMonthPosition(month);
    };

    const getColumn = (columns, { title }) => {
      let column = columns.find(([column]) => column === title);
      if (!column) {
        columns.push(column = create(title));
      }
      return column;
    };

    const setColumn = (columns, revenueCost) => {
      let { value, type } = revenueCost;
      getColumn(columns, revenueCost)[getPosition(revenueCost)] = type === 'cost' ? -value : value;
      return columns;
    };

    const setGroup = (groups, { type, title }) => {
      if (!groups[type].includes(title)) {
        groups[type].push(title);
      }
      return groups;
    };

    const toChart = revenuesCosts => {

      const groups = { revenue: [], cost: [] };
      const columns = [];

      for (let revenueCost of revenuesCosts) {
        setColumn(columns, revenueCost);
        setGroup(groups, revenueCost);
      }
      return { groups, columns };
    };

    const generateChart = ({ columns, groups: { cost, revenue } }) => {

      if (chart) {
        chart.destroy();
      }
      return chart = c3.generate({
        bindto: '#revenues-costs-chart',
        data: {
          columns,
          type: 'bar',
          groups: [cost, revenue]
        },
        axis: {
          x: {
            type: 'category',
            categories: getColumnNames(),
          },
          y: {
            tick: {
              format: $filter('currency')
            }
          }
        },
        grid: {
          y: {
            show: true
          }
        }
      });
    };

    const setYears = revenuesCosts => {
      years = [];
      revenuesCosts.forEach(({ year }) => !years.includes(year) && years.push(year));
      return years.sort();
    };

    const setGrouped = revenuesCosts => {

      const revenueCost = Object.values(revenuesCosts)[0];
      return grouped = !revenueCost || !revenueCost.month;
    };

    $scope.$watch(() => this.data, revenuesCosts => {

      if (!revenuesCosts || !revenuesCosts.length) {
        return chart && chart.destroy();
      }

      setYears(revenuesCosts);
      setGrouped(revenuesCosts);

      generateChart(toChart(revenuesCosts));
    });
  }
})(angular);
