(angular => {

  angular.module("EventGuest", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/guests", {
        controller: "EventGuest.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventGuest/list/list.html`
      });
  }

})(angular);
