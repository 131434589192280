((angular) => {
  "use strict";

  angular.module("App", [
    "Accommodation",
    "Activity",
    "ActivityPremium",
    "Classification",
    "Company",
    "Config",
    "Contract",
    "EffectiveRevenues",
    "EmailMarketing",
    "Event",
    "EventAccess",
    "EventAirTravel",
    "EventChecklist",
    "EventCompany",
    "EventCompanyAction",
    "EventCompanyEmployee",
    "EventCompanyEmployeeCredential",
    "EventCost",
    "EventGuest",
    "EventHotel",
    "EventManualUpload",
    "EventOfficialProvider",
    "EventQuestion",
    "EventTableReservation",
    "EventTax",
    "Participant",
    "PushNotification",
    "Speaker",
    "User",
    "FindRetailer",
    "FindRetailerBussinesClub",
    "FindRetailerNotBussinesClub",
    "FindRetailerSession",
  ]);

})(angular);
