(angular => {
  "use strict";

  angular.module("App").component("accessComponent", {
    templateUrl: 'admin/app/dashboard/components/access/access.html',
    controllerAs: '$ctrl',
    controller: [
      'Loader',
      "moment",
      "AuthService",
      "EventService",
      'EventAccessService',
      Controller
    ]
  });

  function Controller(loader, moment, authService, eventService, eventAccessService) {

    const { eventId } = authService.getTokenData();

    const access = {};
    const total = {};

    let currentDate = '';

    this.eventDates = [];
    this.sections = ['event', 'premium', 'marketing_activity', 'main', 'additional'];

    eventService.current()
      .then(eventService.getEventDays)
      .then(days => days.map(day => moment(day).format('DD/MM')))
      .then(dates => this.eventDates = dates)
      .then(dates => currentDate = dates[0]);

    eventAccessService.list(eventId)
      .then(accesses => {
        accesses.forEach(access => {

          access.participant = (access.present || 0) + (access.presentAtTime || 0);
          access.geral = (access.participant || 0) + (access.staff || 0) + (access.speaker || 0);

          generateTotal(access);
          generateAccessList(access).push(access);
        });
        return total;
      });

    this.changeDate = date => currentDate = date;

    this.getTotal = section => {
      return total &&
        total[currentDate] &&
        total[currentDate][section];
    };

    this.getAccess = section => {
      return access &&
        access[currentDate] &&
        access[currentDate][section];
    };

    this.showAllowedParticipants = (access, $event) => {
      return eventAccessService.allowedModal(access, $event);
    };

    const generateAccessList = ({ date, type }) => {
      const current = access[date] = access[date] || {};
      return current[type] = current[type] || [];
    };

    const generateTotal = ({ date, type, present = 0, presentAtTime = 0, staff = 0, speaker = 0 }) => {
      const totalDate = total[date] = total[date] || {};

      const current = totalDate[type] = totalDate[type] || {
        staff: 0,
        speaker: 0,
        present: 0,
        presentAtTime: 0,
        get participant() {
          return (current.present || 0) + (current.presentAtTime || 0);
        },
        get geral() {
          return current.participant + (current.staff || 0) + (current.speaker || 0);
        }
      };
      current.staff += staff;
      current.speaker += speaker;
      current.present += present;
      current.presentAtTime += presentAtTime;

      return current;
    };

    this.exportAccess = access => eventAccessService.exportAccess(access);
  }

})(angular);
