(angular => {
  "use strict";


  angular.module("EventTableReservation").component("eventTableReservationList", {
    templateUrl: 'admin/eventTableReservation/list/list.html',
    controller: [
      '$scope',
      "Loader",
      "Toast",
      "EventService",
      "EventTableReservationService",
      "CompanyService",
      ListCtrl
    ]
  });

  function ListCtrl($scope, Loader, Toast, eventService, EventTableReservationService, CompanyService) {

    eventService.current().then(event => {
      this.event = event;
    });

    this.loadCompanyTables = () => {
      return Loader.add(CompanyService.loadCompanyTables()).then(companyTables => {
        this.companyTables = companyTables;
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadCompanyTables();

    this.form = ($event, table) => {
      EventTableReservationService.form($event, table).then(() => {
        this.loadCompanyTables();
      });
    };

    this.formRecreation = ($event, table, recreation) => {
      EventTableReservationService.formRecreation($event, table, recreation).then(() => {
        this.loadCompanyTables();
      });
    };

    this.remove = ($event, table) => {
      Loader.add(EventTableReservationService.remove(table.id)).then(() => {
        Toast.show("Mesa excluída com sucesso", "success");
        this.loadCompanyTables();
        return table;
      }).catch(err => Toast.httpHandler(err));
    };

    this.exportTickets = () => {
      EventTableReservationService.exportList();
    };


    $scope.$on('add-table', (info, $event) => {
      this.form($event);
    });

  }
})(angular || {});
