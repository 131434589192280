(angular => {
  "use strict";

  angular.module("EventQuestion").controller("EventQuestion.ListCtrl", [
    '$scope',
    "Loader",
    "Toast",
    "LoginService",
    "EventService",
    "EventQuestionService",
    "AuthService",
    ListCtrl
  ]);

  function ListCtrl($scope, Loader, Toast, loginService, eventService, eventQuestionService, authService) {
    this.user = authService.getTokenData();
    eventService.current().then(event => {
      this.event = event;
    });

    this.preview = () => {
      Loader.add(loginService.previewToken()).then(token => {
        window.open(`/participante#?t=${token}&p=/certificado`);
      }).catch(err => Toast.httpHandler(err));
    };

    this.publish = () => {
      Loader.add(eventQuestionService.publish(!this.event.publish_question)).then(() => {
        this.event.publish_question = !this.event.publish_question;
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadedTab = (tab, state) => {
      if (tab === 'answers' && (!state || !state.length)) {
        $scope.$emit('change-header-tab-current', 1);
      }
    };
  }
})(angular || {});
