(angular => {
  "use strict";

  angular.module("Participant").component("participantWizardDependents", {
    templateUrl: 'admin/participant/wizard/components/dependents/dependents.html',
    controller: [
      "Loader",
      "Toast",
      "ParticipantService",
      "EventService",
      WizardDependentsCtrl
    ],
    bindings: {
      participant: "=",
      onCompleted: "&",
      onCancel: "&"
    }
  });

  function WizardDependentsCtrl(Loader, Toast, ParticipantService, EventService) {

    this.status = [
      'present',
      'present at time',
      'not present',
    ];

    if (!this.status.includes(this.participant.status)) {
      this.status.push(this.participant.status);
    }

    this.$onInit = () => {
      this.dependent = {};

      ParticipantService.listDependents(this.participant.id).then(dependents => {
        this.dependents = dependents;
      });

      EventService.current().then(event => {
        if (!event.allow_relatives) {
          this.onCompleted({ $state: this.participant });
        }
      });
    };

    this.cancel = () => {
      this.onCancel();
    };

    this.add = () => {
      this.dependents.push({ participant_id: this.participant.id, status: this.participant.status });
    };

    this.remove = (dependent) => {
      const index = this.dependents.indexOf(dependent);
      if (index > -1) {
        this.dependents.splice(index, 1);
      }
    };

    this.save = () => {
      this.form.$setSubmitted(true);
      if (this.form.$invalid) return;

      return Loader.add(ParticipantService.saveDependents(this.participant.id, this.dependents))
        .then(() => this.onCompleted({ $state: this.participant }))
        .catch(error => {
          if (error.message === "duplicates") {
            return Toast.show(`Documento já cadastrado`, "error");
          }
          Toast.httpHandler(error);
        });

    };

  }
})(angular || {});
