(angular => {
  "use strict";

  angular.module("EventCompany").controller("EventCompany.FormCtrl", [
    "$scope",
    "$q",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "CompanyService",
    "EventService",
    "EventCompanyService",
    "EventHotelService",
    "model",
    FormCtrl
  ]);

  function FormCtrl($scope, $q, $timeout, $mdDialog, Loader, Toast, companyService, eventService, eventCompanyService, eventHotelService, model) {
    this.model = model || {};

    const eventCompanyId = model ? model.id : '';
    const companyId = model ? model.company_id : null;
    eventService.current().then(event => {
      this.event = event;
    });

    Loader.add($q.all([
      companyService.list(),
      eventHotelService.list()
    ])).then(([companies, hotels]) => {
      this.company = companies.filter(c => c.id === companyId)[0];
      this.companies = companies;
      this.hotels = hotels;
    }).catch(err => Toast.httpHandler(err));

    this.save = () => {
      this.promise = eventCompanyService.get(eventCompanyId).then(eventCompany => {

        if (companyId && companyId !== this.model.company_id) {
          const validated = eventCompanyService.validations(eventCompany, this.event);
          if (validated) return;
        }

        return Loader.add(eventCompanyService.save(this.model)).then(() => {
          Toast.show("Empresa salva com sucesso", "success");
          $mdDialog.hide();
        }).catch(response => {
          const messages = {
            "less-hotel-rooms": `Quantidade de quartos menor que a utilizada: ${(response.data.data || {}).rooms} quartos`,
            "company-already-added": "Empresa já cadastrada",
            "event-spot-exceeded": "Limite do evento de vagas excedido",
            "company-spot-exceeded": `Numero de vagas menor que as vagas em uso: ${(response.data.data || {}).spots_used}`,
            "spots-used-air-travel": "Quantidade de passagens menor que a utilizada no momento",
            "spots-used-activity-premium": "Quantidade de vagas premium menor que a utilizada no momento",
            "spots-used-event-table-reservation": "Quantidade de vagas para jantar menor que a utilizada no momento"
          };

          const message = messages[response.data.message];
          if (message) {
            return Toast.show(message, "error");
          }

          Toast.httpHandler(response);
        });
      });
    };

    this.cancel = $mdDialog.cancel;

    $scope.$watch(() => {
      $timeout(() => {
        this.company = this.companies.filter(c => c.id === this.model.company_id)[0];
      }, 500);
    });

  }
})(angular || {});
