(angular => {

  angular.module("FindRetailerBussinesClub", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/findRetailerBussinesClub", {
        controller: "FindRetailerBussinesClub.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/findRetailerBussinesClub/list/list.html`,
        role: ['admin', 'company_manager']
      });
  }

})(angular);
