(angular => {
  "use strict";

  angular.module("EffectiveRevenues").controller("EffectiveRevenues.FormCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$mdpDatePicker",
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "model",
    "CompanyService",
    "BankService",
    "EffectiveRevenuesService",
    FormCtrl
  ]);

  function FormCtrl($q, $routeParams, $filter, $mdpDatePicker, $scope, $timeout, $mdDialog, Loader, toast, model, CompanyService, BankService, EffectiveRevenuesService) {
    this.model = model || {};
    let queryTimeout = null;

    this.model.accounting_year_id = $routeParams.accountingYearId;
    this.model.parcelsNumber = 1;

    if (model) {
      if (model.parcels) {
        this.model.parcels = model.parcels;
        if (model.parcels) this.model.isParcel = true;
        this.model.parcelsNumber = model.parcels.length;
        this.mountParcelNumber = true;
        this.mountContractValue = true;
      }
    }

    this.loadCompanies = () => {
      const query = angular.copy(this.query);
      this.promise = CompanyService.list(query).then(companies => {
        this.companies = companies;
      }).catch(err => toast.httpHandler(err));
    };

    this.loadBanks = () => {
      const query = angular.copy(this.query);
      this.promise = BankService.list(query).then(banks => {
        this.banks = banks;
      }).catch(err => toast.httpHandler(err));
    };

    this.loadCompanies();
    this.loadBanks();

    this.save = () => {
      delete this.model.file;
      if (this.model.form_of_payment === 'credit_in_account') {
        this.model.credit_card_number = null;
      }

      if (this.model.form_of_payment === 'credit_card') {
        this.model.bank_id = null;
        this.model.agency = null;
        this.model.account = null;
      }

      this.model.parcels.map(parcel => {
        if (parcel.pay_day === "") {
          parcel.pay_day = null;
          this.model.situation = 'pending';
        }
      });
      const notDueDay = this.model.parcels.some(p => !p.due_day);

      if (notDueDay) {
        toast.show("Parcela sem data de vencimento", "error");
        return;
      }

      delete this.model.parcelsNumber;
      delete this.model.isParcel;
      if (!this.model.contract_value) {
        toast.show("Valor do Contrato em Branco", "error");
        return;
      }
      return Loader.add(EffectiveRevenuesService.save(this.model)).then(() => {
        toast.show("Receita salva com sucesso", "success");
        $mdDialog.hide(this.model);
      });
    };

    $scope.$watch("$ctrl.model.parcelsNumber", () => {
      if (this.mountParcelNumber) {
        this.mountParcelNumber = false;
        return;
      }

      this.mountParcels();
    }, true);

    $scope.$watch("$ctrl.model.contract_value", () => {
      if (this.mountContractValue) {
        this.mountContractValue = false;
        return;
      }
      this.mountParcels();

    }, true);

    this.mountParcels = () => {
      this.model.situation = 'pending';
      const parcelValue = this.model.contract_value / this.model.parcelsNumber;

      if (!this.model.parcels) this.model.parcels = [];
      this.model.parcels = Array(Number(this.model.parcelsNumber)).fill(this.model.parcelsNumber).map((parcel, index) =>
        ({ parcel_number: index + 1, value: parcelValue }));
    };

    this.setFile = result => {
      this.model.file = result;
      this.model.filename = result.filename;
      this.model.base64 = result.base64;
    };

    this.removeFile = () => {
      this.model.file = null;
    };

    this.cancel = $mdDialog.hide;

    $scope.$watch(() => this.model, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => {
        if (this.model.form_of_payment === 'credit_in_account' && !this.model.id) {
          EffectiveRevenuesService.findDefaultBank().then(bank => {
            this.model.bank_id = bank.id;
            this.model.agency = bank.agency;
            this.model.account = bank.account;
            this.model.address = bank.address;
          }).catch(err => {

            toast.httpHandler(err);
          });
        }
      }, 500);
    }, true);

  }
})(angular || {});
