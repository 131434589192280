(angular => {

  angular.module("EventQuestion", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/questions", {
        controller: "EventQuestion.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventQuestion/list/list.html`,
        role: ['admin', 'user']
      });
  }

})(angular);
