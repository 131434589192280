(angular => {

  angular.module("Classification", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/classification", {
        controller: "Classification.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/classification/list/list.html`,
        role: ['admin', 'organizer', 'coordinator'],
        allowWithoutEvent: true
      });
  }

})(angular);
