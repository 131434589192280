(angular => {
  "use strict";

  angular.module("EventAirTravel").component("detailsAirportsAirTravel", {
    templateUrl: 'admin/eventAirTravel/components/details-airports.html',
    controller: [
      "Loader",
      "Toast",
      "EventAirTravelService",
      DetailsAirports
    ],
    bindings: {
      ngModel: "=",
      label: "@"
    }
  });

  function DetailsAirports(Loader, Toast, EventAirTravelService) {
    this.search = this.ngModel ? this.ngModel : '';
    this.$onInit = () => {
      this.loadAirports();
    };

    this.loadAirports = () => {
      this.airports = EventAirTravelService.airports();
    };

    this.queryAirports = term => {
      term = _.kebabCase(term);
      return this.airports.filter(a => {
        const airportName = _.kebabCase(a.code) + _.kebabCase(a.description);
        return airportName.includes(term);
      });
    };

    this.changeAirport = airport => {
      if (!airport) return;
      this.ngModel = airport.code;
    };
  }

})(angular);
