(angular => {
  "use strict";


  angular.module("EventAirTravel").controller("EventAirTravel.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "EventCompanyService",
    "EventAirTravelService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, eventService, eventCompanyService, eventAirTravelService) {
    let queryTimeout = null;
    this.promise = null;
    this.query = { orderBy: "name" };
    this.pagination = { page: 1, limit: 10 };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      this.promise = eventCompanyService.list().then(eventCompanies => {
        this.eventCompanies = eventCompanies.map(e => {
          e.totalTickets = e.air_travel.length ?
            e.air_travel.reduce((acc, ticket) => ticket.total_ticket ? acc += Number(ticket.total_ticket) : 0, 0) : 0;
          return e;
        });
      }).catch(err => Toast.httpHandler(err));
    };

    this.exportTickets = () => {
      eventAirTravelService.exportList();
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.pagination.page = 1, 500);
    }, true);

    this.load();

  }
})(angular || {});
