(angular => {
  "use strict";

  angular.module("Participant").component("participantWizardActivities", {
    templateUrl: 'admin/participant/wizard/components/activities/activities.html',
    controller: [
      "$q",
      "$scope",
      "$mdDialog",
      "Loader",
      "Toast",
      "ParticipantService",
      "CompanyService",
      "EventService",
      "ActivityService",
      WizardActivityCtrl
    ],
    bindings: {
      participant: "=",
      onCompleted: "&",
      onCancel: "&"
    }
  });

  function WizardActivityCtrl($q, $scope, $mdDialog, Loader, Toast, ParticipantService, CompanyService, EventService, ActivityService) {

    this.$onInit = () => {
      this.selected = (this.participant.activities || []);

      Loader.add($q.all([
        EventService.current(),
        ActivityService.list()
      ])).then(([event, activities]) => {
        this.activities = activities.filter(a => a.type === 'main');

        if (!event.activity_confirmation && !event.activity_admin_confirmation) {
          this.onCompleted({ $state: this.participant });
          return;
        }
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = () => {
      this.onCancel();
    };

    this.save = () => {
      let activities;

      if (!this.activities.length) {
        this.onCompleted({ $state: this.participant });
        return;
      }

      if (this.selected) {
        activities = this.selected.map(a => a.id);
      }

      return Loader.add(ParticipantService.saveActivities(this.participant.id, activities, null)).then(() => {
        this.onCompleted({ $state: this.participant });
      }).catch((err) => Toast.httpHandler(err));
    };


  }
})(angular || {});
