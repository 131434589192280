(angular => {

  angular.module("Speaker", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/speaker", {
        controller: "Speaker.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/speaker/list/list.html`,
        role: "admin",
        allowWithoutEvent: true
      });
  }

})(angular);
