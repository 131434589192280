(angular => {

  angular.module("Common")

    .filter("contractCategory", [ContractCategoryFilter]);

  function ContractCategoryFilter() {

    const types = {
      1: "Business Green",
      2: "Business Blue",
      4: "Business Gold",
      8: "Business Platinum",
      16: "Business Black"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
