(angular => {

  angular.module("Common").filter("paymentType", [Filter]);

  function Filter() {

    const types = {
      'bankDeposit': 'Depósito Bancário',
      'bankSlip': 'Boleto'
    };

    function filter(type) {
      if (!type) return "Boleto";
      return types[type] || type;
    }

    return filter;
  }

})(angular);
