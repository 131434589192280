(angular => {
  "use strict";

  angular.module("Accommodation").factory("AccommodationService", [
    "$http",
    "API",
    "$mdDialog",
    AccommodationService
  ]);

  function AccommodationService($http, API, $mdDialog) {
    const list = () => {
      return $http.get(`${API}/event/accommodation/`).then(res => res.data);
    };

    const get = companyId => {
      return $http.get(`${API}/event/accommodation/${companyId}`).then(res => res.data);
    };

    const formRoom = ($event, eventCompanyId, room) => {
      return $mdDialog.show({
        templateUrl: `admin/accommodation/form-room/form-room.html`,
        controller: "Accommodation.FormRoomCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          eventCompanyId: eventCompanyId,
          room: angular.copy(room),
        },
        targetEvent: $event
      });
    };

    const formPerson = ($event, rooms, person, room, recreations) => {
      return $mdDialog.show({
        templateUrl: `admin/accommodation/form-person/form-person.html`,
        controller: "Accommodation.FormPersonCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          rooms: angular.copy(rooms),
          person: angular.copy(person),
          room: angular.copy(room),
          recreations: angular.copy(recreations)
        },
        targetEvent: $event
      });
    };

    const save = (roomId, participantId, dependentId, checkin, checkout, recreations) => {
      return $http.post(`${API}/event/accommodation`, { roomId, participantId, dependentId, checkin, checkout, recreations }).then(res => res.data);
    };

    const saveRoom = model => {
      return $http.post(`${API}/event/accommodation/room`, model).then(res => res.data);
    };

    const remove = (participantId, dependentId) => {
      return $http.delete(`${API}/event/accommodation/${participantId}/${dependentId || ''}`).then(res => res.data);
    };

    const removeRoom = roomId => {
      return $http.delete(`${API}/event/accommodation/room/${roomId}`).then(res => res.data);
    };

    return {
      list,
      get,
      formRoom,
      formPerson,
      save,
      saveRoom,
      remove,
      removeRoom
    };
  }
})(angular);
