(angular => {
  "use strict";

  angular.module("Activity")
    .controller("Activity.SendConfirmationCtrl", ["$mdDialog", "Toast", "Loader", "ActivityService", "event", SendConfirmationCtrl]);

  function SendConfirmationCtrl($mdDialog, Toast, Loader, ActivityService, event) {
    this.eventId = event.id;
    this.activity = {};
    this.eventDays = getEventDays(event);
    this.dateEventFind = this.eventDays[0];
    this.cpf = "";

    
    this.sendInviteActivity = (action) => {
      Loader.add(ActivityService.sendConfirmationPostActivity(this.eventId, this.dateEventFind, action, this.cpf)).then(() => {
        if(this.cpf) {
          Toast.show("Convite enviado com sucesso", "success");
          $mdDialog.hide();
        } else {
          Toast.show("Convites enviados com sucesso", "success");
          $mdDialog.hide();
        }
      }).catch(error => {
        if (error.message === 'participant-not-found') {
          return Toast.show('Participante não encontrado na lista restrita', "error");
        }
        if (error.message === 'empty-restricted-list') {
          $mdDialog.hide();
          return Toast.show('Não há participantes cadastrados na lista restrita', "error");
        }
        if (error.message === 'error-service-email') {
          $mdDialog.hide();
          return Toast.show('Erro ao enviar e-mail.', "error");
        }
        Toast.httpHandler(error);
        $mdDialog.hide();
      });

    };

    this.cancel = () => {
      $mdDialog.hide();
    };

    function getEventDays(event) {
      let eventDays = [];
      let currentDate = moment(event.start_date);
      let endDate = moment(event.end_date);

      eventDays.push(angular.copy(currentDate));

      while (currentDate.isBefore(endDate, 'day')) {
        currentDate.add(1, "days");
        eventDays.push(angular.copy(currentDate));
      }

      eventDays = eventDays.map(d => {
        return d.toDate();
      });

      return eventDays;
    }

  }
})(angular || {});
