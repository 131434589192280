(angular => {
  "use strict";

  angular.module("App")
    .controller("App.PasswordChangeCtrl", ["$mdDialog", "$scope", "$location", "Loader", "Toast", "LoginService", PasswordChangeCtrl]);

  function PasswordChangeCtrl($mdDialog, $scope, $location, Loader, Toast, LoginService) {
    $scope.data = {};
    $scope.reset = () => {
      Loader.add(LoginService.changePasswordModal($scope.data)).then(() => {
        Toast.show("Senha alterada com sucesso", "success");
        $mdDialog.hide();
      }).catch(err => {
        if (err.data.message === "Senha inválida") {
          Toast.show(err.data.message, "error");
        }
      });
    };

    $scope.cancel = $mdDialog.cancel;
  }

})(angular);
