(angular => {

  angular.module("EventCompany", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/event-company", {
        controller: "EventCompany.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCompany/list/list.html`,
        role: ['admin', 'user', 'stand_builder']
      });

  }

})(angular);
