(angular => {
  "use strict";

  angular.module("Classification").
    controller("Classification.FormCtrl", [
      "$q",
      "$mdDialog",
      "Loader",
      "Toast",
      "classification",
      "ClassificationService",
      "PositionTypeService",
      "SponsorshipTypeService",
      "CompanyTypeService",
      FormCtrl
    ]);

  function FormCtrl($q, $mdDialog, Loader, Toast, classification, ClassificationService, PositionTypeService, SponsorshipTypeService, CompanyTypeService) {
    this.classification = classification ? {
      id: classification.id,
      name: classification.name,
      position_types: classification.position_types,
      company_types: classification.company_types,
      sponsorship_types: classification.sponsorship_types
    } : {};

    Loader.add($q.all([
      PositionTypeService.list(),
      CompanyTypeService.list(),
      SponsorshipTypeService.list(),
    ])).then(([positionsTypes, companyTypes, sponsorshipTypes]) => {
        this.positionsTypesToView = joinArrayEdit(positionsTypes, this.classification.position_types);
        this.companyTypesToView = joinArrayEdit(companyTypes, this.classification.company_types);
        this.sponsorshipTypesToView = joinArrayEdit(sponsorshipTypes, this.classification.sponsorship_types);
    }).catch(err => Toast.httpHandler(err));

    this.save = () => {
      this.error = "";
      this.classification.position_types = joinArray(this.positionsTypesToView);
      this.classification.company_types = joinArray(this.companyTypesToView);
      this.classification.sponsorship_types = joinArray(this.sponsorshipTypesToView);
      const classification = this.classification;

      if(!classification.position_types.length && !classification.company_types.length && !classification.sponsorship_types.length){
        Toast.show('Selecione pelo menos uma opção', 'error');
        return;
      }

      return Loader.add(ClassificationService.save(this.classification)).then(() => {
         Toast.show("Classificação salva com sucesso", "success");
         $mdDialog.hide(this.classification);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
  }

  const separateArray = (base, max) => {
    let res = [];

    for (let i = 0; i < base.length; i = i+max) {
      res.push(base.slice(i,(i+max)));
    }

    return res;
  };

  const joinArrayEdit = (base, arrayObject) => {
    let res = [];

    if(arrayObject) {
      res = base.map((element) => ({
        id: element.id,
        name: element.name,
        selected: arrayObject? arrayObject.some(i => i.id === element.id) : false,
      }));

      return separateArray(res, 3);
    }
    return separateArray(base, 3);
  };

  const joinArray = (base) => {
    let res = [];

    for (const j of base)
      for (const i of j)
        res.push(i);

    res = res.filter((element) => {
      if(element.selected) return element;
    });

    res = res.map((element) => ({
      id: element.id,
      name: element.name
    }));

    return res;
  };

})(angular || {});
