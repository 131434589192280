(angular => {

  angular.module("Common")

    .filter("contractAction", [ContractActionFilter]);

  function ContractActionFilter() {

    const types = {
      "contract": "Contrato",
      "extra": "Extra",
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
