((angular) => {
  "use strict";

  angular.module("Common").directive("pageToolbar", ["$compile", Directive]);

  function Directive($compile) {

    return {
      restrict: "E",
      scope: false,
      priority: 1,
      replace: false,
      terminal: true,
      compile: (tElement) => {
        const html = tElement.html();
        tElement.remove();

        return {
          pre: ($scope) => {
            $scope.$emit("change-page-title", $compile(`<div class="md-toolbar-tools">${html}</div>`)($scope));
          }
        };
      }
    };

  }

})(angular);
