((angular) => {
  "use strict";

  angular.module("Common").directive("outputPageToolbar", ["$rootScope", Directive]);

  function Directive($rootScope) {

    return {
      scope: false,
      link: ($scope, elem) => {
        $rootScope.$on("change-page-title", (info, data) => {
          elem.html(data);
        });
      }
    };

  }

})(angular);
