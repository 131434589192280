(angular => {

  angular.module("Common")

    .filter("eventStatus", [EventStatusFilter]);

  function EventStatusFilter() {

    const types = {
      "open": "Aberto",
      "closed": "Encerrado",
      "canceled": "Cancelado"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
