(angular => {

  angular.module("App")

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    const token = window.location.hash.split('?token=')[1];
    console.log(token,window);
    window.sessionStorage.setItem('token',token);
    $routeProvider
      .when("/", {
        controller: "App.LoginCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/app/login/login.html`,
        allowAnonymous: true
      })
      .when("/dashboard", {
        controller: "App.DashboardCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/app/dashboard/dashboard.html`,
        role: ['admin', 'user', 'coordinator']
      })
      .when("/recover/:token", {
        controller: "App.LoginCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/app/login/login.html`
      });
  }

})(angular);
