((angular) => {
  'use strict';

  angular.module('Common').directive('showRoles', [
    '$rootScope',
    'AuthService',
    Directive
  ]);

  function Directive($rootScope, authService) {

    return {
      restrict: 'A',
      scope: false,
      link: ($scope, elem, attrs) => {

        const roles = _.flattenDeep([attrs.showRoles.split(','), ['admin']]);

        const hideTypes = (attrs.hideCompanyTypes || '').split(/\s*,\s*/g).filter(type => type);

        const showTypes = (attrs.showCompanyTypes || '').split(/\s*,\s*/g).filter(type => type);

        const showType = ({ role, companyTypes }) => {

          if (role !== 'company_manager') {
            return true;
          }

          if (showTypes.length) {
            return companyTypes.some(type => showTypes.includes(type));
          }

          if (hideTypes.length) {
            return companyTypes.some(type => !hideTypes.includes(type));
          }
          return true;
        };

        const showRole = (user) => !roles.length || roles.some(role => role === user.role);

        const change = () => {

          const user = authService.getTokenData();

          if (!user) {
            return elem.hide();
          }

          if (showRole(user) && showType(user)) {
            return elem.show();
          }

          return elem.hide();
        };

        $rootScope.$on("login", change);
        change();
      }
    };

  }

})(angular);
