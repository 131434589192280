(angular => {
  "use strict";

  angular.module("EmailMarketing").controller("EmailMarketing.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "$sce",
    "$scope",
    "Loader",
    "Toast",
    "lodash",
    "dateHelper",
    "EmailMarketingService",
    "CompanyService",
    "model",
    "typeForm",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, $sce, $scope, loader, Toast, lodash, dateHelper, emailMarketingService, companyService, model, typeForm) {

    this.model = model || { config: { emails: [], companies: [], types: [] } };
    this.typeForm = typeForm || '';
    this.companies = [];
    this.model.date = this.model.send_date ? model.send_date : '';
    this.model.time = this.model.send_date ? dateHelper.getTime(model.send_date) : '';

    this.model.config.isEmails = !!(this.model.config.emails && this.model.config.emails.length);
    this.model.config.isCompany = !!(this.model.config.companies && this.model.config.companies.length);
    this.model.config.isType = !!(this.model.config.types && this.model.config.types.length);

    companyService.list().then(companies => {
      this.companies = companies;
      this.companyTypes = companyService.listTypes();
    });

    this.verifyStatus = (sendType) => {
      this.model.config[sendType] = [];
    };

    this.filesTypes = [
      { type: "logo", name: "Logo", size: '300 x 300' },
    ];

    this.save = () => {
      this.model.send_date = dateHelper.merge(new Date(this.model.date), this.model.time);
      if (typeof this.model.message !== 'string') {
        this.model.message = $sce.parseAsHtml(this.model.message);
      }
      return loader.add(emailMarketingService.save(this.model)).then(() => {
        Toast.show(`Email ${model ? "editado" : "cadastrado" } com sucesso`, "success");
        $mdDialog.hide(this.model);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
    this.getImage = (image) => {
      if (!image) return "/img/sem-imagem.jpg";
      return image.base64 || `/content/${image}`;
    };

    this.setImage = result => {
      this.model.image = {
        filename: result.filename,
        base64: result.base64
      };
    };

    this.removeImage = () => {
      this.model.image = null;
    };
  }
})(angular || {});
