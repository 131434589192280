(angular => {
  "use strict";

  angular.module("Accommodation").controller("Accommodation.ListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EventService",
    "AccommodationService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, Loader, Toast, eventService, accommodationService) {
    let queryTimeout = null;
    this.promise = null;
    this.orderBy = 'company.name';
    this.query = { orderBy: "name" };
    this.pagination = { page: 1, limit: 10 };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    eventService.current().then(event => {
      this.event = event;
    });

    this.load = () => {
      this.promise = accommodationService.list().then(eventCompanies => {
        this.eventCompanies = eventCompanies;
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.pagination.page = 1, 500);
    }, true);

    this.load();

  }
})(angular || {});
