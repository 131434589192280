(angular => {
  "use strict";

  angular.module("Classification").factory("ClassificationService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    ClassificationService
  ]);

  function ClassificationService($q, $http, $mdDialog, API) {
    const list = (params) => {
      params = angular.copy(params);

      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/classification`,
        params: params || this.params,
      }).then((res) => res.data);
    };

    const form = (classification) => {
      return $mdDialog.show({
        templateUrl: `admin/classification/form/form.html`,
        controller: "Classification.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          classification: angular.copy(classification)
        }
      });
    };


    const save = (classification) => {
      return $http.post(`${API}/classification`, classification).then(res => res.data);
    };

    const remove = (classification) => {
      return $http.delete(`${API}/classification/${classification.id}`);
    };

    return {
      list,
      form,
      save,
      remove
    };
  }
})(angular);
