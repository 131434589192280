(angular => {
  "use strict";

  angular.module("EventAirTravel").controller("EventAirTravel.FormTicketCtrl", [
    "$q",
    "$routeParams",
    "$filter",
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventService",
    "EventAirTravelService",
    "AuthService",
    "ParticipantService",
    "ticket",
    "eventCompany",
    FormTicketCtrl
  ]);

  function FormTicketCtrl($q, $routeParams, $filter, $scope, $timeout, $mdDialog, Loader, Toast, eventService, eventAirTravelService, authService, participantService, ticket, eventCompany) {
    this.user = authService.getTokenData();
    this.eventCompany = eventCompany;
    this.person = ticket || {};
    this.airportsLimit = {};
    this.search = ticket ? ticket.participant.name : '';
    this.stretchs = ticket ? ticket.stretchs : [];
    this.airports = eventAirTravelService.airports();

    eventService.current().then(event => {
      this.event = event;
    });
    this.loadMore = key => {
      this.airportsLimit[key] = this.airportsLimit[key] || 10;
      if (this.airportsLimit[key] > this.airports.length) return;
      this.airportsLimit[key] += 10;
    };

    this.queryParticipant = term => {
      return participantService.query(term, this.eventCompany.company_id, true);
    };

    this.changeParticipant = participant => {
      if (!participant) return;
      this.person.participant = participant;
    };

    this.remove = (stretch) => {
      const index = this.stretchs.indexOf(stretch);
      if (index > -1) {
        this.stretchs.splice(index, 1);
      }
    };


    this.addPerson = () => {
      this.form.$setSubmitted(true);

      if (!this.person.participant) {
        Toast.show("Selecione um participante", "error");
        return;
      }

      if (this.stretchs.length && !eventAirTravelService.validateAirport(this.stretchs)) {
        Toast.show("Aeroporto inválido", "error");
        return;
      }

      this.person.stretchs = this.stretchs;
      this.person.id = this.person.id || null;
      this.person.event_company_id = this.eventCompany.id;
      this.person.company_id = this.person.participant.company_id;
      this.person.event_id = this.person.participant.event_id;
      this.person.participant_id = this.person.participant.id;

      return Loader.add(eventAirTravelService.save(this.person)).then(() => {
        Toast.show("Passagem adicionada com sucesso", "success");
        this.form.$setSubmitted(false);
        this.form.$setPristine(true);
        this.search = null;
        this.person = {};
        $mdDialog.hide();

      }).catch(response => {
        if (response.data.message === 'spots-over') {
          return Toast.show("Quantidade de passagens esgotou", "error");
        }
        if (response.data.message === 'duplicates-key') {
          return Toast.show("Esse participante j� possui uma passagem", "error");
        }

        Toast.httpHandler(response);
      });
    };

    this.cancel = () => {
      $mdDialog.hide();
    };

    this.removeTicket = (ticket) => {
      eventAirTravelService.remove(ticket.id).then(() => {
        Toast.show("Passagem removida com sucesso", "success");
      });
    };

    this.addTicket = () => {
      this.stretchs.push({
        event_air_travel_id: ticket ? ticket.id : null,
        checkin_exit_date: null,
        checkin_arrival_date: null,
        checkin_exit_time: null,
        checkin_arrival_time: null,
        route_origin: null,
        route_destination: null,
        period: null,
        e_ticket: null,
        locator: null,
        num_air_travel: null,
        air_travel_company: null
      });
    };
  }
})(angular || {});
