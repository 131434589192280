(angular => {
  "use strict";

  angular.module("EventCost").controller("EventCost.DetailsCtrl", [
    "$route",
    "$routeParams",
    "$mdpDatePicker",
    "$location",
    "Loader",
    "Toast",
    "EventCostService",
    "AuthService",
    DetailsCtrl
  ]);

  function DetailsCtrl($route, $routeParams, $mdpDatePicker, $location, Loader, Toast, EventCostService, authService) {
    this.user = authService.getTokenData();
    this.load = () => {
      EventCostService.get($routeParams.costId).then(cost => {
        this.model = cost;
        this.files = cost.files;
      }).catch(err => Toast.httpHandler(err));
    };
    this.load();
    this.form = ($event, model) => {
      EventCostService.form($event, model).then(() => {
        $route.reload();
      });
    };

    this.pay = (parcel, $event) => {
      $mdpDatePicker(new Date(), { targetEvent: $event }).then(selectedDate => {
        parcel.pay_day = selectedDate;
        Loader.add(EventCostService.pay(parcel.id, parcel.pay_day)).then(() => {
          Toast.show("Parcela paga com sucesso", "success");
          this.load();
        }).catch(err => Toast.httpHandler(err));

      });
    };

    this.remove = () => {
      EventCostService.remove(this.model.id).then(() => {
        $location.path("/costs");
      }).catch(err => Toast.httpHandler(err));
    };
  }
})(angular || {});
