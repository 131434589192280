((angular) => {
  'use strict';

  angular
    .module('FindRetailerNotBussinesClub')
    .factory('FindRetailerNotBussinesClubService', [
      '$q',
      '$http',
      'API',
      'downloadjs',
      FindRetailerNotBussinesClubService,
    ]);

  function FindRetailerNotBussinesClubService($q, $http, API, download) {
    this.params = {};

    const exportList = (err, title) => {
      return $http
        .post(
          `${API}/activity/exportErrorList`,
          { err, title },
          { responseType: 'blob' }
        )
        .then((res) => {
          let header = res.headers('Content-Disposition');
          // eslint-disable-next-line no-useless-escape
          let fileName = header.split('=')[1].replace(/\"/gi, '');
          download(res.data, fileName, 'application/vnd.ms-excel');
          return err;
        });
    };

    const listForNotBussinessClub = (eventId, companyId, query) => {
      return $http({
        method: 'GET',
        url: `${API}/participant/listForNotBussinessClub`,
        params: { eventId, companyId, query },
      }).then((res) => {
        return res.data;
      });
    };

    const findInviteCompany = (cpf) => {
      // console.log(cpf);
      return $http({
        method: 'GET',
        url: `${API}/activity/findInviteCompany/${cpf}`,
      }).then((res) => res.data);
    };

    return {
      exportList,
      listForNotBussinessClub,
      findInviteCompany,
    };
  }
})(angular);
