(angular => {
  "use strict";

  angular
    .module("EventGuest")
    .factory("SponsorshipTypeService", ["$http", "API", SponsorshipTypeService]);

  function SponsorshipTypeService($http, API) {
    const list = params => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/sponsorshipType`,
        params
      }).then(res => res.data);
    };

    return {
      list
    };
  }
})(angular);
