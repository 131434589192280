((angular) => {
  'use strict';

  angular
    .module('FindRetailerBussinesClub')
    .controller('FindRetailerBussinesClub.ConfirmCtrl', [
      '$scope',
      '$timeout',
      '$route',
      '$routeParams',
      '$mdDialog',
      '$q',
      'XLSReader',
      'Loader',
      'Toast',
      'AuthService',
      'id',
      'FindRetailerBussinesClubService',
      ConfirmCtrl,
    ]);

  function ConfirmCtrl(
    $scope,
    $timeout,
    $route,
    $routeParams,
    $mdDialog,
    $q,
    XLSReader,
    Loader,
    Toast,
    authService,
    id,
    FindRetailerBussinesClubService
  ) {
    this.user = authService.getTokenData();
    this.idDelete = id;

    this.cancel = $mdDialog.cancel;

    this.removeParticipant = () => {
      // console.log(this.idDelete);
      Loader.add(
        FindRetailerBussinesClubService.deleteRestrictedListBussines(id).then(
          (result) => {
            $mdDialog.hide(result);
            Toast.show('Participante excluido com sucesso', 'success');
          }
        )
      ).catch((err) => Toast.httpHandler(err));
    };
  }
})(angular || {});
