(function() {
  'use strict';
  angular.module('EventAirTravel').directive("loadLimitator", function() {
    return {
      restrict: "A",
      link: function(scope, element, attrs) {
        let container = element.find('md-content');
        container.css('height', '300px');
        let visibleHeight = 300;
        let threshold = 100;

        container.on('scroll', function() {
          let scrollableHeight = container.prop('scrollHeight');
          let hiddenContentHeight = scrollableHeight - visibleHeight;
          if (hiddenContentHeight - container[0].scrollTop <= threshold) {
            scope.$apply(attrs.loadLimitator);
          }
        });

      }
    };
  });
})();
