(angular => {
  "use strict";

  angular.module("Event").controller("Event.ListCtrl", [
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "AuthService",
    "ParticipantService",
    "EventService",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, $mdDialog, Loader, Toast, AuthService, ParticipantService, EventService) {
    let queryTimeout = null;

    this.promise = null;
    this.events = null;
    this.query = {
      orderBy: "-start_date",
      page: 1,
      limit: 25
    };

    this.dateEventFind = null;

    this.loadData = () => {
      this.promise = EventService.list(this.query).then((events) => {
        this.events = events;
      }).catch(err => Toast.httpHandler(err));
    };

    this.remove = (event) => {
      let textContent;

      if (event.participant && event.participant.length && event.activity.length) {
        textContent = `Este evento possui ${event.participant.length} confirmados e ${event.activity.length} atividades cadastradas`;
      } else {
        if (event.participant && event.participant.length) {
          textContent = `Este evento possui ${event.participant.length} confirmados`;
        }
        if (event.activity && event.activity.length) {
          textContent = `Este evento possui ${event.activity.length} atividades cadastradas`;
        }
      }

      const confirm = $mdDialog.confirm()
        .title("Tem certeza que deseja excluir este evento?")
        .textContent(textContent)
        .ariaLabel("Excluir Evento")
        .ok("Excluir")
        .cancel("Cancelar");

      $mdDialog.show(confirm).then(() => {
        Loader.add(EventService.remove(event)).then(() => {
          Toast.show("Evento excluído com sucesso", "success");
          this.loadData();
        });
      });
    };

    this.exportParticipants = event => {
      EventService.exportParticipants(event);
    };

    this.exportTransmissionPlatform = (event, dateEventFind) => {
      EventService.exportTransmissionPlatform(event, dateEventFind);
    };

    this.validateExportLabel = event => {
      ParticipantService.list().then(participants => {
        if (!participants.length) {
          return $mdDialog.show(
            $mdDialog.alert()
            .title("Nenhum participante cadastrado nesse evento")
            .ok("Ok")
          );
        }

        EventService.exportLabels(event);
      }).catch(err => Toast.httpHandler(err));
    };

    this.form = ($event, event) => {
      EventService.form($event, event).then(() => {
        this.loadData();
      });
    };

    this.exportTransmissionPlatform = ($event, event) => {
      EventService.exportTransmission($event, event).then(() => {
        this.loadData();
      });
    };
    this.exportToken = ($event, event) => {
      EventService.exportTokenTemplate($event, event).then(() => {
        this.loadData();
      });
    };

    this.importTransmissionPlatform = ($event, event) => {
      EventService.importTransmission($event, event).then(() => {
        this.loadData();
      });
    };

    this.download = ($event, event) => {
      window.open(`/api/desktop/download/${event.id}/all?t=${AuthService.getToken()}`);
    };

    this.importDesktop = $event => {
      EventService.importDesktop($event, this.events).then(() => {
        this.loadData();
      });
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadData(), 500);
    }, true);
  }
})(angular || {});
