(angular => {

  angular.module("EffectiveRevenues", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/revenues", {
        controller: "EffectiveRevenues.AccountingYearListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/effectiveRevenues/accountingYear/accountingYearList.html`,
        allowWithoutEvent: true
      })
      .when("/revenues/:accountingYearId", {
        controller: "EffectiveRevenues.RevenuesListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/effectiveRevenues/list.html`,
        allowWithoutEvent: true
      })
      .when("/revenues/details/:revenueId", {
        controller: "EffectiveRevenues.RevenuesDetailsCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/effectiveRevenues/details.html`,
        allowWithoutEvent: true
      });
  }

})(angular);
