(angular => {
  "use strict";

  angular.module("Activity")
    .controller("Activity.FormSendInviteAllCtrl", ["$mdDialog", "Toast", "Loader", "ActivityService", "eventId", FormSendInviteAllCtrl]);

  function FormSendInviteAllCtrl($mdDialog, Toast, Loader, ActivityService, eventId) {
    this.eventId = eventId;
    this.activity = {};
    this.cpf = "";

    this.inviteCPF = () => {
      Loader.add(ActivityService.inviteRestrictedListAll(this.eventId, this.cpf)).then(() => {
        if(this.cpf) {
          Toast.show("Convite enviado com sucesso", "success");
          $mdDialog.hide();
        } else {
          Toast.show("Convites enviados com sucesso", "success");
          $mdDialog.hide();
        }
      }).catch(error => {
        if (error.message === 'participant-not-found') {
          return Toast.show('Participante não encontrado na lista restrita', "error");
        }
        if (error.message === 'empty-restricted-list') {
          $mdDialog.hide();
          return Toast.show('Não há participantes cadastrados na lista restrita', "error");
        }
        if (error.message === 'error-service-email') {
          $mdDialog.hide();
          return Toast.show('Erro ao enviar e-mail.', "error");
        }
        Toast.httpHandler(error);
        $mdDialog.hide();
      });

    };

    this.cancel = () => {
      $mdDialog.hide();
    };

  }
})(angular || {});
