(angular => {

  angular.module("EventCompanyEmployeeCredential", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/credenciais", {
        controller: "EventCompanyEmployeeCredential.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/credentials/list/list.html`
      })
      .when("/credenciais/:companyId", {
        controller: "EventCompanyEmployeeCredential.DetailsCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/credentials/details/details.html`
      });
  }

})(angular);
