(angular => {

  angular.module("Common").filter("guestStatusIcon", [
    CompanyTypeFilter
  ]);

  function CompanyTypeFilter() {
    const types = {
      'invited': 'clock',
      'accepted': 'check',
      'refused': 'close',
      'confirmed': 'check-all',
      'subscription at time' : 'check-all'
    };

    function filter(type) {
      if (!types[type]) {
        return type;
      }

      return types[type];
    }

    return filter;
  }

})(angular);
