((angular) => {
  'use strict';

  angular
    .module('Activity')
    .controller('Activity.FormAttachCtrl', [
      '$scope',
      '$timeout',
      '$routeParams',
      '$mdDialog',
      '$q',
      'Loader',
      'Toast',
      '$sce',
      'ActivityService',
      'SponsorshipTypeService',
      'activity',
      FormAttachCtrl,
    ]);

  function FormAttachCtrl(
    $scope,
    $timeout,
    $routeParams,
    $mdDialog,
    $q,
    Loader,
    Toast,
    $sce,
    ActivityService,
    SponsorshipTypeService,
    activity
  ) {
    this.associated = false;
    this.invited = false;
    this.entity = false;
    this.government = false;
    this.press = false;
    this.official_assembler = false;
    this.not_informed = false;
    this.organizer = false;
    this.commercial_partner = false;
    this.companyTypes;
    this.sponsorShipTypes;
    this.selectedCompanyTypes = [];
    this.selectedSponsorShipTypes = [];
    this.filenameTerm;
    this.fileBase64;
    this.attachCompanyType;
    this.attachSponsorShipType;

    ActivityService.getCompanytypes().then((result) => {
      this.companyTypes = result;
    });

    SponsorshipTypeService.list().then((result) => {
      this.sponsorShipTypes = result;
    });

    this.addCompanyType = (companyType) => {
      if (this.selectedCompanyTypes.length > 0) {
        const index = this.selectedCompanyTypes.indexOf(companyType);
        if (index > -1) {
          this.selectedCompanyTypes.splice(index, 1);
        } else {
          this.selectedCompanyTypes.push(companyType);
        }
      } else {
        this.selectedCompanyTypes.push(companyType);
      }
      // console.log(this.selectedCompanyTypes);
    };

    this.addSponsorShipType = (sponsorShipType) => {
      if (this.selectedSponsorShipTypes.length > 0) {
        const index = this.selectedSponsorShipTypes.indexOf(sponsorShipType);
        if (index > -1) {
          this.selectedSponsorShipTypes.splice(index, 1);
        } else {
          this.selectedSponsorShipTypes.push(sponsorShipType);
        }
      } else {
        this.selectedSponsorShipTypes.push(sponsorShipType);
      }
      // console.log(this.selectedSponsorShipTypes);
    };
    this.cancel = () => {
      $mdDialog.hide();
    };

    this.addAttachment = (result) => {
      this.filenameTerm = result.filename;
      this.fileBase64 = result.base64;

      this.attachCompanyType = this.selectedCompanyTypes.map((val) => ({
        activity_id: activity.id,
        company_type_id: val.id,
      }));

      this.attachSponsorShipType = this.selectedSponsorShipTypes.map((val) => ({
        activity_id: activity.id,
        sponsorship_type_id: val.id,
      }));

      if (!result.success) {
        switch (result.reason) {
          case 'invalidExtension':
            Toast.show('Extensão inválida', 'error');
            this.cancel();
            break;
          case 'maxFileSize':
            Toast.show('Tamanho máximo é de 5MB', 'error');
            break;
        }
        return;
      }
    };

    this.removeUploadTerm = () => {
      this.fileBase64 = '';
      this.filenameTerm = '';
    };

    this.save = () => {
      if (this.attachCompanyType) {
        if (
          this.filenameTerm !== undefined &&
          this.filenameTerm !== '' &&
          this.fileBase64 !== undefined &&
          this.fileBase64 !== ''
        ) {
          Loader.add(
            ActivityService.addAttachment(
              activity,
              this.filenameTerm,
              this.fileBase64,
              this.attachCompanyType,
              this.attachSponsorShipType
            )
          )
            .then(() => {
              Toast.show('Anexo criada com sucesso', 'success');
              this.cancel();
            })
            .catch((err) => Toast.httpHandler(err));
        } else {
          Toast.show('Você não anexou nenhum arquivo.', 'error');
        }
      } else {
        Toast.show('Você não selecionou nenhum tipo de empresa.', 'error');
      }
    };
  }
})(angular || {});
