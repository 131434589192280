(angular => {
  "use strict";


  angular.module("EventManualUpload").controller("EventManualUpload.ListCtrl", [
    "$scope",
    "$q",
    "$timeout",
    "Loader",
    "Toast",
    "$routeParams",
    "CompanyService",
    "EventManualUploadService",
    "AuthService",
    ListCtrl
  ]);

  function ListCtrl($scope, $q, $timeout, Loader, Toast, $routeParams, companyService, EventManualUploadService, AuthService) {
    this.pagination = { page: 1, limit: 10 };
    this.user = AuthService.getTokenData();

    this.form = ($event, model) => {
      EventManualUploadService.form($event, model).then(() => {
        this.loadManualUploads();
      });
    };

    this.loadCompanies = () => {
      companyService.list().then(companies => {
        this.companies = companies;
      });
    };

    this.managerCompanies = () => {
      this.user.companyIds.map(userCompanyId => {
        this.companies = this.companies.filter(company => {
          return company.id = userCompanyId;
        });
      });
    };

    this.loadCompanies();

    this.loadManualUploads = () => {
      EventManualUploadService.list().then(files => {
        if (this.user.role !== 'company_manager') {
          this.files = files;
          return;
        }

        this.managerCompanies();

        this.files = files.filter(file => {
          const isAssociated = this.companies.some(c => c.type === 'associated') && file.type === 'associateManual';
          const isNotAvaliable = this.companies.some(c => c.type !== null || c.type !== 'not informed' || c.type !== 'associated');
          const isCommercial = this.companies.some(c => c.type === 'commercial partner') && file.type ==='exhibitorManual';
          if (isAssociated || isCommercial) return true;
      
          if(isNotAvaliable) return false;
         
          return this.companies.some(c => c.type !== 'associated') && file.type === 'exhibitorManual';
        });
      });
    };

    this.loadManualUploads();

    this.remove = ($event, model) => {
      Loader.add(EventManualUploadService.remove(model.id)).then(() => {
        Toast.show("Upload de manual removido com sucesso", "success");
        this.loadManualUploads();
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadProviderUploads = () => {
      Loader.add(EventManualUploadService.listCompanies()).then(archives => {
        this.archives = archives;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadProviderUploads();

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

    $scope.$watch('headerTabs.current', value => {
      this.currentTab = value;
    });

  }
})(angular || {});
