(angular => {

  angular.module("Contract", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/contract", {
        controller: "Contract.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/contract/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin']
      })
      .when("/contract/:modelId", {
        controller: "Contract.FormBenefitsCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/contract/formBenefits/form.html`,
        allowWithoutEvent: true,
        role: ['admin']
      });
  }

})(angular);
