(angular => {

  angular.module("EventCompanyAction", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/event-company-action", {
        controller: "EventCompanyAction.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/eventCompanyAction/list/list.html`,
        role: ['admin', 'user']
      });
  }

})(angular);
