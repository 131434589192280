(angular => {
  "use strict";

  angular.module("App").component("satisfaction", {
    templateUrl: 'admin/app/dashboard/components/satisfaction.html',
    controller: [
      '$q',
      '$scope',
      '$timeout',
      'Loader',
      'EventService',
      'mathjs',
      'EventQuestionService',
      Controller
    ],
    bindings: {
      onReady: '&'
    }
  });

  function Controller($q, $scope, $timeout, loader, eventService, math, eventQuestionService) {

    this.questions = [];
    this.totalSatisfaction = 0;
    this.totalPoints = 0;
    this.loading = true;

    this.$onInit = () => {
      eventService.current().then(event => this.event = event);
      eventQuestionService.listAnswers().then(participants => {
        const questions = participants.reduce((questions, participant) => {
          participant.answers.forEach(answer => {
            let question = questions.filter(a => a.id === answer.question.id)[0];

            if (!question) {
              question = answer.question;
              question.answers = [];
              questions.push(question);
            }

            delete answer.question;
            if (question.answers) {
              question.answers.push(answer);
            }

          });

          return questions;
        }, []).filter(q => q.type === 'multiple-choice');

        questions.forEach(question => {

          const subTotalPoints = question.answers.reduce((acc, a) => acc + Number(a.answer), 0);
          const totalAnswers = question.answers.reduce((acc) => acc + 10, 0);
          const standardDeviation = math.std(question.answers.map(a => Number(a.answer)));
          const satisfaction = Number(((subTotalPoints * 100) / totalAnswers).toFixed(1));

          this.questions.push({
            average: Number((subTotalPoints / question.answers.length).toFixed(2)),
            satisfaction: satisfaction,
            description: question.description,
            standardDeviation: Number(standardDeviation.toFixed(2)),
            points: subTotalPoints,
            subTotalAnswers: question.answers.length * 10
          });

          this.totalSatisfaction += satisfaction;
        });
        this.totalSatisfaction = Number((this.totalSatisfaction / questions.length).toFixed(1));

        this.totalPoints = this.questions.reduce((acc, question) => acc + question.points, 0);
        this.totalPointsGeneral = this.questions.reduce((acc, question) => acc + question.subTotalAnswers, 0);
        this.totalAnswered = participants.length;

        this.loading = false;
      }).then(() => this.onReady && this.onReady());
    };

  }

})(angular);
