(angular => {
  "use strict";

  angular.module("ActivityPremium").factory("ActivityPremiumService", [
    "$http",
    "API",
    "$mdDialog",
    "FormRedirectService",
    ActivityPremiumService
  ]);

  function ActivityPremiumService($http, API, $mdDialog, FormRedirectService) {

    const listWithParticipants = () => {
      return $http.get(`${API}/activity/list-with-participants`).then(res => res.data);
    };

    const formSPot = ($event, person, activities, hasSpotsActivities) => {
      return $mdDialog.show({
        templateUrl: `admin/activityPremium/form-spot/form-spot.html`,
        controller: "ActivityPremium.FormSpotCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          person: angular.copy(person),
          activities: angular.copy(activities),
          hasSpotsActivities: angular.copy(hasSpotsActivities)
        },
        targetEvent: $event
      });
    };

    const exportList = () => {
      return FormRedirectService.submit(`${API}/activity/export-premium`);
    };

    return {
      listWithParticipants,
      formSPot,
      exportList
    };
  }
})(angular);
