(angular => {

  angular.module("Common").filter("paymentStatus", [Filter]);

  function Filter() {

    const types = {
      'pending': 'Pendente',
      'analyzing': 'Em Análise',
      'approved': 'Aprovado',
      'available': 'Disponível',
      'contested': 'Contestado',
      'refunded': 'Reembolsado',
      'cancelled': 'Cancelado',
      'exempted': 'Isento',
      'manual-discharge': 'Baixado Manualmente'
    };

    function filter(type) {
      if (!type) return "Não iniciado";
      return types[type] || type;
    }

    return filter;
  }

})(angular);
