(angular => {
  "use strict";

  angular.module("Event").controller("Event.ImportDesktopCtrl", [
    "$mdDialog",
    "$timeout",
    "$interval",
    "Loader",
    "Toast",
    "EventService",
    CreateDesktopCtrl
  ]);

  function CreateDesktopCtrl($mdDialog, $timeout, $interval, loader, toast, eventService) {

    this.fileProgress, this.fileName, this.historicFiles = null;
    this.getHistoric = () => eventService.getHistoricImport().then(historic => this.historicFiles = historic);

    const interval = $interval(this.getHistoric, 3000);
    this.getHistoric();

    setTimeout(() => {
      const resumable = eventService.getResumable();
      resumable.assignBrowse(document.getElementById('file-import'));

      resumable.on('fileAdded', () => {
        resumable.upload();
      });

      resumable.on('fileProgress', (file) => {
        $timeout(() => {
          this.fileName = `  ${file.fileName}`;
          this.fileProgress = ` ${Math.floor(file.progress() * 100)} %`;
        });
      });

      resumable.on('fileSuccess', (fileCompleted) => {
        $timeout(() => { this.fileName = null, this.fileProgress = null; });
        eventService.uploadCompleted({
          fileName: fileCompleted.file.name,
          uniqueIdentifier: fileCompleted.file.uniqueIdentifier
        });
      });
    }, 500);

    this.cancel = () => {
      $interval.cancel(interval);
      return $mdDialog.cancel();
    };
  }

})(angular);
