(angular => {
  "use strict";

  angular.module("Participant").controller("Participant.LinkEventCtrl", [
    "$timeout",
    "$mdDialog",
    "Toast",
    "Loader",
    "$filter",
    "$window",
    "ParticipantService",
    "event",
    "participant",
    LinkEventCtrl
  ]);

  function LinkEventCtrl($timeout, $mdDialog, Toast, Loader, $filter, $window, ParticipantService, event, participant) {

    this.event = event;
    this.participant = participant;
    this.query = {};
    this.eventDays = ParticipantService.getEventDays(event);
    this.dateEventFind = this.eventDays[0];
    this.participantToken = {};

    this.cancel = $mdDialog.cancel;
    this.close = $mdDialog.hide;

    this.findLinkEventByDate = () => {
      this.participantToken = {};
      return Loader.add(ParticipantService.findLinkEventByDate(this.event.id, this.dateEventFind, participant.cpf)).then((result) => {
        this.participantToken = result;
      }).catch(err => Toast.httpHandler(err));
    };
    this.findTokenEventByDate = () => {
      this.participantToken = {};
      return Loader.add(ParticipantService.findTokenEventByDate(this.event.id, this.dateEventFind, participant.cpf)).then((result) => {
        this.participantToken = result;
      }).catch(err => Toast.httpHandler(err));
    };

  }
})(angular || {});
