((angular) => {
  'use strict';

  angular
    .module('Event')
    .factory('EventService', [
      '$rootScope',
      '$q',
      '$http',
      '$mdDialog',
      'API',
      'Resumable',
      'dateHelper',
      'AuthService',
      'FormRedirectService',
      'moment',
      'XLSReader',
      EventService,
    ]);

  function EventService(
    $rootScope,
    $q,
    $http,
    $mdDialog,
    API,
    Resumable,
    dateHelper,
    AuthService,
    FormRedirectService,
    moment,
    XLSReader
  ) {
    let currentEventPromise, currentEvent;
    this.params = {};

    const get = (eventId) => {
      return $http.get(`${API}/event/${eventId}`).then((res) => {
        return res.data;
      });
    };

    const current = (refresh) => {
      if (
        refresh ||
        (currentEvent && currentEvent.id !== AuthService.getTokenData().eventId)
      ) {
        currentEventPromise = null;
      }

      if (!currentEventPromise) {
        currentEventPromise = $http.get(`${API}/event/current`).then((res) => {
          currentEvent = res.data;
          return currentEvent;
        });
      }

      return currentEventPromise;
    };

    const query = (term) => {
      return $http({
        method: 'GET',
        url: `${API}/event/query`,
        params: { term },
      }).then((res) => {
        return res.data.map((e) => dateHelper.parseObj(e));
      });
    };

    const list = (params) => {
      params = angular.copy(params);

      if (params) {
        params.direction = 'asc';
      }

      if (params && params.orderBy && params.orderBy[0] === '-') {
        params.orderBy = params.orderBy.replace('-', '');
        params.direction = 'desc';
      }

      return $http({
        method: 'GET',
        url: `${API}/event`,
        params: params || this.params,
        timeout: 3600000,
      }).then((res) => {
        return res.data.map((e) => dateHelper.parseObj(e));
      });
    };

    const listOrganizers = (eventId) => {
      return $http
        .get(`${API}/event/organizers/${eventId}`)
        .then((res) => res.data);
    };

    const save = (event) => {
      return $http.post(`${API}/event`, event).then((res) => {
        $rootScope.$broadcast('event-updated', event);
        return res.data;
      });
    };

    const remove = (event) => {
      return $http
        .delete(`${API}/event/${event.id}`)
        .then(list)
        .then(() => event);
    };

    const getEventDays = (event) => {
      let eventDays = [];
      let currentDate = moment(event.start_date);
      let endDate = moment(event.end_date);

      eventDays.push(angular.copy(currentDate));

      while (currentDate.isBefore(endDate, 'day')) {
        currentDate.add(1, 'days');
        eventDays.push(angular.copy(currentDate));
      }

      eventDays = eventDays.map((d) => {
        return d.toDate();
      });

      return eventDays;
    };

    const getEventsByYear = (year, events) => {
      return events.filter((e) => e.end_date.getFullYear() === year);
    };

    const getYears = (events) => {
      const years = events.map((e) => e.end_date.getFullYear());

      return years.filter(function (elem, index, years) {
        return years.indexOf(elem) === index;
      });
    };

    const exportParticipants = (event) => {
      return FormRedirectService.submit(
        `${API}/event/${event.id}/exportParticipants`,
        event
      );
    };

    const exportTransmissionPlatform = (event, dateEvent) => {
      return FormRedirectService.submit(
        `${API}/event/${dateEvent}/exportTransmissionPlatform`,
        event
      );
    };
    const exportToken = (event, dateEvent) => {
      return FormRedirectService.submit(
        `${API}/event/${dateEvent}/exportTransmissionPlatform`,
        event
      );
    };

    const exportLabels = (event) => {
      return FormRedirectService.submit(
        `${API}/event/${event.id}/exportLabels`
      );
    };

    const openSelect = ($event) => {
      return $mdDialog.show({
        templateUrl: `admin/event/select/select.html`,
        controller: 'Event.SelectCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        targetEvent: $event,
      });
    };

    const form = ($event, event) => {
      return $mdDialog.show({
        templateUrl: `admin/event/form/form.html`,
        controller: 'Event.CreateCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          event: angular.copy(event),
        },
        targetEvent: $event,
      });
    };

    const importTransmission = ($event, event) => {
      return XLSReader.loadFile('participant-list').then((eventImport) => {
        return $mdDialog.show({
          templateUrl: `admin/event/import/import_transmission_platform.html`,
          controller: 'Event.ImportCtrl',
          controllerAs: '$ctrl',
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: true,
          locals: {
            event: angular.copy(event),
            eventImport: angular.copy(eventImport),
          },
          targetEvent: $event,
        });
      });
    };

    const exportTransmission = ($event, event) => {
      return $mdDialog.show({
        templateUrl: `admin/event/export/export_transmission_platform.html`,
        controller: 'Event.ExportCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          event: angular.copy(event),
        },
        targetEvent: $event,
      });
    };
    const exportTokenTemplate = ($event, event) => {
      return $mdDialog.show({
        templateUrl: `admin/event/export/export_token.html`,
        controller: 'Event.ExportTokenCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          event: angular.copy(event),
        },
        targetEvent: $event,
      });
    };

    const importListTransmission = (dateEvent, eventId, data) => {
      return $http
        .post(
          `${API}/event/${dateEvent}/${eventId}/importTransmissionPlatform`,
          { data }
        )
        .then((result) => result.data);
    };

    const importDesktop = ($event, events) => {
      return $mdDialog.show({
        templateUrl: `admin/event/import-desktop/import-desktop.html`,
        controller: 'Event.ImportDesktopCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        targetEvent: $event,
        locals: {
          events: angular.copy(events),
        },
      });
    };

    const saveImportDesktop = (file) => {
      const formData = new FormData();
      formData.append('file', file);

      return $http({
        url: `${API}/event/import`,
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': undefined },
        transformRequest: angular.identity,
      }).then((res) => res.data);
    };

    const getStatus = () => {
      return {
        main: 'Corporativa',
        additional: 'Complementar',
        premium:
          currentEvent && currentEvent.activity_premium
            ? currentEvent.name_activity_premium
            : 'Atividade Premium',
        marketing_activity:
          currentEvent && currentEvent.marketing_activity
            ? currentEvent.marketing_activity_name
            : 'Atividade Marketing',
      };
    };

    const getHistoricImport = () => {
      return $http.get(`${API}/event/historic-import`).then((res) => res.data);
    };
    const uploadCompleted = (file) => {
      return $http
        .post(`${API}/event/upload-completed`, file)
        .then((res) => res.data);
    };

    const getResumable = () => {
      const test = new Resumable({
        target: `${API}/event/upload`,
        chunkSize: 1 * 1024 * 1024,
        simultaneousUploads: 1,
        testChunks: false,
        headers: { Authorization: 'Bearer ' + AuthService.getToken() },
      });

      console.log(test);

      return test;
    };

    return {
      get,
      listOrganizers,
      current,
      query,
      list,
      remove,
      save,
      getEventDays,
      exportParticipants,
      exportLabels,
      openSelect,
      form,
      importDesktop,
      saveImportDesktop,
      getStatus,
      getHistoricImport,
      uploadCompleted,
      getResumable,
      getEventsByYear,
      getYears,
      exportTransmissionPlatform,
      exportToken,
      exportTransmission,
      exportTokenTemplate,
      importTransmission,
      importListTransmission,
    };
  }
})(angular);
