(angular => {

  angular.module("Event")

    .filter("automakerType", [AutomakerType]);

  function AutomakerType() {

    const types = {
      "standBuilder": "Montadora",
      "agency": "Agência",
      "maintenance": "Manutenção"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
