((angular) => {
  'use strict';

  angular
    .module('FindRetailerBussinesClub')
    .controller('FindRetailerBussinesClub.IndexCtrl', [
      '$scope',
      '$timeout',
      '$routeParams',
      '$mdDialog',
      '$q',
      'XLSReader',
      'Loader',
      'Toast',
      'AuthService',
      'FindRetailerBussinesClubService',
      '$sce',
      IndexCtrl,
    ]);

  function IndexCtrl(
    $scope,
    $timeout,
    $routeParams,
    $mdDialog,
    $q,
    XLSReader,
    Loader,
    Toast,
    authService,
    FindRetailerBussinesClubService,
    $sce
  ) {
    this.user = authService.getTokenData();
    // console.log(this.user);
    this.promise = null;
    this.selected = [];
    this.query = { orderBy: 'name' };
    this.pagination = { page: 1, limit: 10 };
    this.participants = [];
    this.activities = [];
    this.eventDays = [];
    this.statusSelected = '';
    this.status = ['Selecionado', 'Não Selecionado'];
    this.formateDate = '';
    this.query1 = { orderBy: 'name' };
    this.spots_company = 0;

    this.formatTime = (date) => {
      if (date !== undefined) return date.substring(11, 15);
    };

    this.loadActivitys = () => {
      return (this.promise = $q
        .all([
          FindRetailerBussinesClubService.findActivityByMarketingActivity(),
          FindRetailerBussinesClubService.findSpotsByCompany(
            this.user.eventId,
            this.user.companyIds[0]
          ),
        ])
        .then(([activities, spots_company]) => {
          this.activities = activities;
          this.spots_company = spots_company.spots_marketing_activity || 0;
          this.activities.forEach((a) => {
            a.formatedDescription = $sce.trustAsHtml(a.description);
            FindRetailerBussinesClubService.listRestrictedListBussines(
              a.id,
              this.user.companyIds[0]
            )
              .then((spots) => {
                a.spost_used_company = spots.length;
              })
              .catch((err) => Toast.httpHandler(err));
          });
          this.activities.map((activity) => {
            activity.formatedDescription = $sce.trustAsHtml(
              activity.description
            );
          });
        })
        .catch((err) => Toast.httpHandler(err)));
    };

    this.formateDateActivity = (date) => {
      if (date !== undefined) {
        let format = new Date(date);
        let dia = format.getDate().toString();
        let diaF = dia.length === 1 ? '0' + dia : dia;
        let mes = (format.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        let mesF = mes.length === 1 ? '0' + mes : mes;
        let anoF = format.getFullYear();

        return diaF + '/' + mesF + '/' + anoF;
      }
    };

    this.openModal = (activitie) => {
      // console.log(activitie);
      $mdDialog
        .show({
          templateUrl: `admin/findRetailerBussinesClub/form/form.html`,
          controller: 'FindRetailerBussinesClub.FormCtrl',
          controllerAs: '$ctrl',
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: true,
          locals: {
            activity: activitie,
            spots_company: this.spots_company,
          },
        })
        .then(() => {
          // console.log('passei2');
          this.loadActivitys();
        });
    };

    this.loadActivitys();
  }
})(angular || {});
