(angular => {
  "use strict";

  angular.module("Activity").controller("Activity.ImportCtrl", [
    "$timeout",
    "$mdDialog",
    "$window",
    "$sce",
    "Toast",
    "Loader",
    "$filter",
    "participants",
    "ActivityService",
    ImportCtrl
  ]);

  function ImportCtrl($timeout, $mdDialog, $window, $sce, Toast, Loader, $filter, participants, ActivityService) {
    this.error = false;
    this.query = { page: 1, limit: 10 };
    this.participants = participants;

    this.loadActivities = () => {
      this.promise = ActivityService.listByDay(this.query);
      this.promise.then(res => {
        this.activities = res;
        this.activities.map(activity => {
          activity.speakers = activity.speakers.map(s => s.speaker);
          if (!activity.spots) activity.spots = 0;
          activity.description = $sce.trustAsHtml(activity.description);
        });
      });
    };

    this.cancel = $mdDialog.cancel;
    this.close = () => {
      this.loadActivities();
      $mdDialog.hide();
    };
  }
})(angular || {});
