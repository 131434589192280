(angular => {

  angular.module("EmailMarketing", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/email-marketing", {
        controller: "EmailMarketing.IndexCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/emailMarketing/index/index.html`,
        role: ['admin']
      });
  }

})(angular);
