(angular => {
  "use strict";

  angular.module("EventQuestion").component("eventQuestionListAnswers", {
    templateUrl: 'admin/eventQuestion/list/components/answers.html',
    controller: [
      '$scope',
      'Dialog',
      'Loader',
      'Toast',
      'EventQuestionService',
      Controller
    ],
    bindings: {
      'onLoad': '&'
    }
  });

  function Controller($scope, dialog, loader, toast, eventQuestionService) {
    this.participants = [];

    this.query = { orderBy: "name" };
    this.pagination = { page: 1, limit: 10 };

    loader.add(eventQuestionService.listAnswers()).then(participants => {
      this.participants = participants;
      this.onLoad({ $state: this.participants });
    }).catch(err => toast.httpHandler(err));

    this.details = ($event, participant) => {
      if (!participant.sections) {
        participant.sections = participant.answers.reduce((acc, answer) => {
          let section = acc.filter(s => s.id === answer.question.section.id)[0];

          if (!section) {
            section = answer.question.section;
            section.questions = [];
            acc.push(section);
          }

          let question = section.questions.filter(q => q.id === answer.question.id)[0];

          if (!question) {
            question = answer.question;
            section.questions.push(question);
          }

          question.answer = answer.answer;
          return acc;
        }, []);
      }

      dialog({
        templateUrl: 'admin/eventQuestion/list/components/answers-details.html',
        locals: { participant },
        targetEvent: $event
      });
    };

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };
  }

})(angular);
