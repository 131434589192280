(angular => {
  "use strict";

  angular.module("Common")
    .factory("Loader", ["$rootScope", "$q", "$timeout", service]);

  function service($rootScope, $q, $timeout) {
    let loaders = 0,
      timeoutHide;

    const add = promise => {
      $timeout.cancel(timeoutHide);

      if (++loaders === 1) {
        $rootScope.$broadcast("loading-started");
      }

      promise.finally(() => {
        if (--loaders !== 0) return;
        timeoutHide = $timeout(() => $rootScope.$broadcast("loading-finished"), 500);
      });

      return promise;
    };

    return { add };
  }

})(angular);
