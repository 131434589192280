 (angular => {
   "use strict";

   angular.module("EventHotel").controller("EventHotel.FormRecreationHotelCtrl", [
     "$mdDialog",
     "Loader",
     "Toast",
     "EventHotelService",
     "hotel",
     "recreation",
     FormRecreationHotelCtrl
   ]);

   function FormRecreationHotelCtrl($mdDialog, Loader, Toast, eventHotelService, hotel, recreation) {
     this.recreation = recreation;

     this.submit = () => {
       this.recreation.event_hotel_id = hotel.id;

       eventHotelService.saveRecreation(this.recreation).then(() => {
         Toast.show("Atividade salva com sucesso", "success");
         $mdDialog.hide();
       }).catch(err => Toast.httpHandler(err));
     };

     this.cancel = $mdDialog.cancel;
   }
 })(angular || {});
