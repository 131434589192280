(angular => {
  "use strict";


  angular.module("EventCompanyEmployeeCredential").controller("EventCompanyEmployeeCredential.DetailsCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "$routeParams",
    "EventService",
    "EventCompanyService",
    "EventCompanyEmployeeCredentialService",
    DetailsCtrl
  ]);

  function DetailsCtrl($scope, $timeout, Loader, Toast, $routeParams, eventService, EventCompanyService, EventCompanyEmployeeCredentialService) {
    this.query = { orderBy: 'name' };

    this.form = ($event, model) => {
      EventCompanyEmployeeCredentialService.form($event, model, this.exhibitor.employees, this.exhibitor.event_id).then(() => {
        this.loadCredentials();
      });
    };

    this.remove = ($event, model) => {
      Loader.add(EventCompanyEmployeeCredentialService.remove(model.id)).then(() => {
        Toast.show("Credencial removida com sucesso", "success");
        this.loadCredentials();
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadCredentials = () => {
      this.exhibitor = null;

      Loader.add(EventCompanyEmployeeCredentialService.list($routeParams.companyId)).then(result => {
        this.exhibitor = result.exhibitor;
        this.exhibitor.totalCredentials = (this.exhibitor.employees || []).reduce((acc, i) => acc + (i.credential_amount || 0), 0);
        this.exhibitor.usedCredentials = (this.exhibitor.employees || []).reduce((acc, i) => acc + (i.credentials || []).length, 0);

        this.credentials = result.credentials;
      }).catch(err => Toast.httpHandler(err));
    };
    this.loadCredentials();

    this.onPaginate = () => {
      $scope.$emit("scroll-top");
    };

  }
})(angular || {});
