(angular => {
  "use strict";

  angular.module("EventChecklist").factory("EventChecklistExhibitorService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "AuthService",
    EventChecklistExhibitorService
  ]);

  function EventChecklistExhibitorService($q, $http, $mdDialog, API) {

    const list = () => {
      return $http.get(`${API}/event/checklist`).then(res => res.data);
    };

    const form = ($event, model, service) => {
      return $mdDialog.show({
        templateUrl: `admin/eventChecklist/form/form.html`,
        controller: "EventChecklist.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model),
          service
        }
      });
    };

    const save = checklist => {
      return $http.post(`${API}/event/checklist`, checklist).then(res => res.data);
    };

    const changeStatus = (event_checklist_id, date, status) => {
      return $http.post(`${API}/event/checklist/changeStatus`, {
        event_checklist_id,
        date,
        status
      });
    };


    const remove = (checklistId) => {
      return $http.delete(`${API}/event/checklist/${checklistId}`);
    };

    const listCompanyChecklist = () => {
      return $http.get(`${API}/event/checklist/company`).then(res => res.data);
    };

    return {
      list,
      save,
      remove,
      form,
      changeStatus,
      listCompanyChecklist
    };
  }
})(angular);
