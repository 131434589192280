(angular => {

  angular.module("Event", [
      "Config"
    ])

    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/event", {
        controller: "Event.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/event/list/list.html`,
        allowWithoutEvent: true,
        role: ['admin', 'user', 'organizer', 'activity_speaker', 'coordinator']
      });
  }

})(angular);
