(angular => {
  "use strict";

  angular.module("Contract").factory("ContractService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "XLSReader",
    ContractService
  ]);

  function ContractService($q, $http, $mdDialog, API, XLSReader) {

    const list = () => {
      return $http.get(`${API}/contract-model/`).then(res => res.data);
    };

    const get = modelId => {
      return $http.get(`${API}/contract-model/${modelId}`).then(res => res.data);
    };

    const save = model => {
      return $http.post(`${API}/contract-model/`, model).then(res => res.data);
    };

    const remove = (modelId) => {
      return $http.delete(`${API}/contract-model/${modelId}`);
    };

    const listBenefits = () => {
      return $http.get(`${API}/contract-benefits/`).then(res => res.data);
    };

    const saveBenefit = benefit => {
      return $http.post(`${API}/contract-benefits/`, benefit).then(res => res.data);
    };

    const removeBenefit = (benefitId) => {
      return $http.delete(`${API}/contract-benefits/${benefitId}`);
    };

    const formBenefits = (benefit, modelId) => {
      return $mdDialog.show({
        templateUrl: `admin/contract/formBenefits/form/form.html`,
        controller: "Contract.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          benefit: angular.copy(benefit),
          modelId: angular.copy(modelId)
        }
      });
    };

    const formModel = (model) => {
      return $mdDialog.show({
        templateUrl: `admin/contract/formModel/form.html`,
        controller: "Contract.FormModelCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model),
        }
      });
    };

    const formImportList = ($event, modelId) => {
      return XLSReader.loadFile('benefits-import').then(benefits => {
        return $mdDialog.show({
          templateUrl: `admin/contract/import/import.html`,
          controller: "Contract.ImportModalCtrl",
          controllerAs: "$ctrl",
          clickOutsideToClose: true,
          escapeToClose: true,
          fullscreen: true,
          locals: {
            benefits,
            modelId: angular.copy(modelId)
          },
          targetEvent: $event
        });
      });
    };

    const importList = (data) => {
      return $http.post(`${API}/contract-benefits/import`, data).then(result => result.data);
    };


    return {
      list,
      get,
      save,
      remove,
      listBenefits,
      saveBenefit,
      removeBenefit,
      formBenefits,
      formModel,
      formImportList,
      importList
    };
  }
})(angular);
