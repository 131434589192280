(angular => {
  "use strict";


  angular.module("EffectiveRevenues").controller("EffectiveRevenues.AccountingYearListCtrl", [
    "$scope",
    "$timeout",
    "Loader",
    "Toast",
    "EffectiveRevenuesService",
    AccountingYearListCtrl
  ]);

  function AccountingYearListCtrl($scope, $timeout, Loader, Toast, EffectiveRevenuesService) {
    let queryTimeout = null;
    this.promise = null;

    this.formAccountingYear = ($event, accountingYear) => {
      EffectiveRevenuesService.formAccountingYear($event, accountingYear).then(() => {
        this.load();
      });
    };

    this.load = () => {
      this.promise = EffectiveRevenuesService.listAccountingYear().then(accountingYears => {
        this.accountingYears = accountingYears;
      }).catch(err => Toast.httpHandler(err));
    };

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.load(), 500);
    }, true);

  }
})(angular || {});
