(angular => {
  "use strict";

  angular.module("EventCompanyEmployeeCredential").factory("EventCompanyEmployeeCredentialService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    EventCompanyEmployeeCredentialService
  ]);

  function EventCompanyEmployeeCredentialService($q, $http, $mdDialog, API) {
    const list = (companyId) => {
      return $http.get(`${API}/event/event-company-employee-credential/${companyId}`).then(res => res.data);
    };

    const form = ($event, model, employees, event_id) => {
      return $mdDialog.show({
        templateUrl: `admin/credentials/form/form.html`,
        controller: "EventCompanyEmployeeCredential.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model),
          employees,
          event_id,
        }
      });
    };

    const save = credencial => {
      return $http.post(`${API}/event/event-company-employee-credential/`, credencial).then(res => res.data);
    };

    const remove = (eventCompanyEmployeeCredentialId) => {
      return $http.delete(`${API}/event/event-company-employee-credential/${eventCompanyEmployeeCredentialId}`);
    };

    return {
      list,
      form,
      save,
      remove
    };
  }
})(angular);
