(angular => {
  "use strict";

  angular.module("EventManualUpload").factory("EventManualUploadService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    EventManualUploadService
  ]);

  function EventManualUploadService($q, $http, $mdDialog, API) {
    const list = () => {
      return $http.get(`${API}/event/manual-upload/`).then(res => res.data);
    };

    const save = eventManualUpload => {
      return $http.post(`${API}/event/manual-upload/`, eventManualUpload).then(res => res.data);
    };
    const remove = (eventManualUploadId) => {
      return $http.delete(`${API}/event/manual-upload/${eventManualUploadId}`);
    };

    const form = ($event, model) => {
      return $mdDialog.show({
        templateUrl: `admin/eventManualUpload/form/form.html`,
        controller: "EventManualUpload.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model)
        }
      });
    };

    const listCompanies = () => {
      return $http.get(`${API}/event/provider-upload/`).then(res => res.data);
    };

    return {
      list,
      save,
      remove,
      form,
      listCompanies
    };
  }
})(angular);
