(angular => {

  angular.module("Participant")

    .filter("participantGender", [ParticipantGenderFilter]);

  function ParticipantGenderFilter() {

    const types = {
      "male": "Masculino",
      "female": "Feminino",
      "other": "Outro",
      "not informed": "Não Informado"
    };

    function filter(type) {
      return types[type] || "-";
    }

    return filter;
  }

})(angular);
