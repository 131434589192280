(angular => {
  "use strict";

  angular.module("EventCompanyAction").controller("EventCompanyAction.FormCtrl", [
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "EventCompanyActionService",
    "model",
    "CompanyService",
    CreateCtrl
  ]);

  function CreateCtrl($scope, $timeout, $mdDialog, loader, toast, eventCompanyActionService, model, CompanyService) {
    this.model = model || {};

    this.save = () => {
      return loader.add(eventCompanyActionService.save(this.model)).then(() => {
        $mdDialog.hide(this.model);
      }).catch(err => toast.httpHandler(err));
    };

    this.loadCompanies = () => {
      this.promise = CompanyService.list().then(companies => {
        this.companies = companies;
      });
    };

    this.cancel = $mdDialog.cancel;
    this.loadCompanies();

  }
})(angular || {});
