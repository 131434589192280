(angular => {
  "use strict";

  angular.module("EmailMarketing").factory("EmailMarketingService", [
    "$q",
    "$http",
    "$mdDialog",
    "API",
    "AuthService",
    EmailMarketingService
  ]);

  function EmailMarketingService($q, $http, $mdDialog, API) {

    const list = (params) => {
      if (params) {
        params.direction = "asc";
      }

      if (params && params.orderBy && params.orderBy[0] === "-") {
        params.orderBy = params.orderBy.replace("-", "");
        params.direction = "desc";
      }

      return $http({
        method: "GET",
        url: `${API}/event/email-marketing`,
        params: params || this.params,
      }).then((res) => res.data);
    };

    const findByIdHistoric = (id) => {
      return $http.get(`${API}/event/email-marketing/${id}`).then(res => res.data);
    };

    const form = ($event, model, typeForm) => {
      return $mdDialog.show({
        templateUrl: `admin/emailMarketing/form/form.html`,
        controller: "EmailMarketing.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          model: angular.copy(model),
          typeForm: angular.copy(typeForm)
        }
      });
    };

    const historic = ($event, id) => {
      return $mdDialog.show({
        templateUrl: `admin/emailMarketing/historic/historic.html`,
        controller: "EmailMarketing.HistoricCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        locals: {
          id: angular.copy(id)
        }
      });
    };

    const save = email => {
      return $http.post(`${API}/event/email-marketing`, email).then(res => res.data);
    };

    const remove = (emailId) => {
      return $http.delete(`${API}/event/email-marketing/${emailId}`);
    };

    return {
      list,
      findByIdHistoric,
      historic,
      save,
      remove,
      form
    };
  }
})(angular);
