(angular => {
  "use strict";

  angular.module("EventCompanyAction").controller("EventCompanyAction.ListCtrl", [
    "$mdDialog",
    "Loader",
    "Toast",
    "EventCompanyActionService",
    ListCtrl
  ]);

  function ListCtrl($mdDialog, Loader, Toast, eventCompanyActionService) {
    this.event;
    this.query = { orderBy: "company" };

    this.loadActions = () => {
      this.promise = eventCompanyActionService.list().then(actions => {
        this.eventCompanyAction = actions;
      }).catch(err => Toast.httpHandler(err));
    };

    this.loadActions();

    this.form = ($event, model) => {
      eventCompanyActionService.form($event, model).then(() => {
        this.loadActions();
      });
    };

    this.removeAction = (action) => {
      Loader.add(eventCompanyActionService.remove(action.id)).then(() => {
        Toast.show("Ação excluída com sucesso", "success");
        this.loadActions();
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
