(angular => {
  "use strict";

  angular.module("User").controller("User.SelectCtrl", [
    "$scope",
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "UserService",
    "role",
    ListCtrl
  ]);

  function ListCtrl($scope, $timeout, $mdDialog, Loader, Toast, UserService, role) {
    let queryTimeout = null;

    this.promise = null;
    this.users = null;
    this.query = {
      orderBy: "name",
      page: 1,
      limit: 25,
      role: role
    };

    this.loadData = () => {
      const query = angular.copy(this.query);
      this.promise = UserService.list(query).then(users => {
        this.users = users;
      }).catch(err => Toast.httpHandler(err));
    };

    this.select = (user) => {
      $mdDialog.hide(user);
    };

    this.cancel = $mdDialog.cancel;

    $scope.$watch(() => this.query, () => {
      $timeout.cancel(queryTimeout);
      queryTimeout = $timeout(() => this.loadData(), 500);
    }, true);

  }

})(angular || {});
