(angular => {
  "use strict";

  angular.module("EventAirTravel").component("eventAirTravelTickets", {
    templateUrl: 'admin/eventAirTravel/list/airlineTickets/airlineTickets.html',
    controller: [
      "Loader",
      "Toast",
      "EventService",
      "EventCompanyService",
      ListCtrl
    ]
  });

  function ListCtrl(Loader, Toast, eventService, eventCompanyService) {

    eventService.current().then(event => {
      this.event = event;
    });

    Loader.add(eventCompanyService.list()).then(eventCompanies => {
      this.eventCompanies = eventCompanies;
    });
  }
})(angular || {});
