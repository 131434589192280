(angular => {
  "use strict";

  angular.module("EventHotel").component("eventHotelRecreations", {
    templateUrl: 'admin/eventHotel/index/recreations/recreations.html',
    controller: [
      "Loader",
      "Toast",
      "EventService",
      "EventHotelService",
      "AuthService",
      EventHotelRecreations
    ]
  });

  function EventHotelRecreations(Loader, Toast, eventService, eventHotelService, authService) {
    this.user = authService.getTokenData();
    eventService.current().then(event => {
      this.event = event;
    });

    Loader.add(eventHotelService.list()).then(hotels => {
      this.hotels = hotels.filter(h => (h.recreations || []).length);
    }).catch(err => Toast.httpHandler(err));

  }
})(angular || {});
