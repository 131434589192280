(angular => {

  angular.module("Common")

    .filter("byteMask", [EventByteMask]);

  function EventByteMask() {
    return function(bytes, precision) {
      bytes = parseFloat(bytes);

      if (bytes === 0) {
        return '0 bytes';
      }
      if (bytes === 1) {
        return '1 byte';
      }
      if (isNaN(bytes) || !isFinite(bytes)) {
        return '-';
      }
      if (typeof precision === 'undefined') {
        precision = 1;
      }

      let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      let number = Math.floor(Math.log(bytes) / Math.log(1024));
      let value = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

      // FIXME: >PiB values should be handled as PiB
      return (value.match(/\.0*$/) ?
        value.substr(0, value.indexOf('.')) : value) + ' ' + units[number];
    };
  }

  return { bytes: EventByteMask };

})(angular);
