(angular => {
  "use strict";

  angular.module("FindRetailerBussinesClub").factory("FindRetailerBussinesClubService", [
    "$q",
    "$http",
    "API",
    "downloadjs",
    FindRetailerBussinesClubService
  ]);

  function FindRetailerBussinesClubService($q, $http, API, download) {
    this.params = {};

    const exportList = (err, title) => {
      return $http.post(`${API}/activity/exportErrorList`, {err, title}, {responseType: 'blob'})
        .then(res => {
          let header = res.headers('Content-Disposition');
          // eslint-disable-next-line no-useless-escape
          let fileName = header.split("=")[1].replace(/\"/gi, '');
          download(res.data, fileName, 'application/vnd.ms-excel');
          return err;
        });
    };

    const findSpotsByCompany = (eventId, companyId) => {
      return $http({
        method: "GET",
        url: `${API}/event/companies/findByEventIdCompanyId/${eventId}/${companyId}`
      }).then((res) => res.data);
    };

    const saveRestrictedListBussines = (activity) => {
      return $http.post(`${API}/activity/saveRestrictedBussinessClub`, activity)
        .then(res => res.data)
        .catch((err) => {
          if (err.data.message === 'import-list-error') {
            return exportList(err.data, activity.title);
          }
        });
    };

    const findParticipantByCpf = (cpf) => {
      return $http({
        method: "GET",
        url: `${API}/participant/find-by-cpf/${cpf}`
      }).then((res) => res.data);
    };

    const listRestrictedListBussines = (activityId, companyId) => {
      return $http({
        method: "GET",
        url: `${API}/activity/listRestrictedListBussines/${activityId}/${companyId}`
      }).then((res) => res.data);
    };


    const deleteRestrictedListBussines = (idParticipant) => {
      return $http.delete(`${API}/activity/deleteRestrictedListBussines/${idParticipant}`);
    };

    const findUsedSpotsByCompany = (activityId, companyId) => {
      return $http({
        method: "GET",
        url: `${API}/event/companies/findUsedSpotsByCompany/${activityId}/${companyId}`
      }).then((res) => res.data);
    };

    const findActivityByMarketingActivity = () => {
      return $http.get(`${API}/activity/findActivityByMarketingActivity`)
        .then(res => res.data);
    };

    const findRestrictedListByCpfActivity = (activityId, cpf) => {
      return $http.get(`${API}/activity/findRestrictedListByCpfActivity/${activityId}/${cpf}`)
        .then(res => res.data);
    };

    return {
      findSpotsByCompany,
      saveRestrictedListBussines,
      findParticipantByCpf,
      listRestrictedListBussines,
      deleteRestrictedListBussines,
      findUsedSpotsByCompany,
      findActivityByMarketingActivity,
      findRestrictedListByCpfActivity,
    };
  }
})(angular);
