(angular => {
  "use strict";

  angular.module("EventTax").controller("EventTax.OrderFormCtrl", [
    "$mdDialog",
    "Toast",
    "Loader",
    "$scope",
    "EventCompanyService",
    "EventCompanyEmployeeService",
    "EventTaxService",
    OrderFormCtrl
  ]);

  function OrderFormCtrl($mdDialog, toast, loader, $scope, eventCompanyService, eventCompanyEmployeeService, eventTaxService) {
    this.model = { quantity: 1 };
    this.isEventExhibitor = false;

    this.loadExhibitors = () => {
      eventCompanyService.listExhibitors().then(exhibitors => {
        this.eventExhibitors = exhibitors;
      });
    };

    this.eventCompanyEmployees = () => {
      if (this.isEventExhibitor) {
        eventCompanyEmployeeService.listByCompany(this.eventExhibitor.id).then(employees => {
          this.employees = employees;
        });
      }
    };

    this.loadExhibitors();

    loader.add(eventTaxService.taxes()).then(taxes => this.taxes = taxes);

    this.save = () => {
      if (!this.model.paymentType) return toast.show('Seleciona um tipo de pagamento', 'error');
      if (!this.model.payer) return toast.show('Seleciona um pagador', 'error');
      if (this.model.payer === 'exhibitor' && (!this.eventExhibitor.company.phone || !this.eventExhibitor.company.email)) return toast.show('Atualize os dados do expositor', 'error');
      this.model.tax_id = this.tax.id;
      this.model.company_id = this.eventExhibitor.company.id;
      this.model.event_company_employee_id = this.employeeId;
      return loader.add(eventTaxService.saveOrders(this.model)).then(order => {
        toast.show('Pedido criado com successo', 'success');
        $mdDialog.hide(order);
      }).catch(err => toast.httpHandler(err));
    };

    $scope.$watch('$ctrl.eventExhibitor', () => {
      this.eventCompanyEmployees();
      this.isEventExhibitor = true;
    });

    this.cancel = $mdDialog.cancel;
  }
})(angular || {});
