(angular => {
  "use strict";

  angular.module("Activity").controller("Activity.StatisticCtrl", [
    "$routeParams",
    "ActivityService",
    "Loader",
    "Toast",
    StatisticCtrl
  ]);

  function StatisticCtrl($routeParams, activityService, loader, toast) {
    let firstTime = true;

    this.get = () => {
      const promise = activityService.getById($routeParams.activityId);

      if (firstTime) {
        loader.add(promise);
        firstTime = false;
      }

      promise.then(activity => {
        this.banner = activity.event.assets.filter(a => a.type === "banner").map(a => a.path)[0];
        this.totalAnswers = Number(activity.question.answers.length);
        this.activity = activity;
        this.activity.question.choices.forEach(choice => {
          choice.totalAnswers = (((this.activity.question.answers.filter(a => choice.id === a.activity_question_choice_id)).length * 100) / this.totalAnswers).toFixed(1);
        });
      }).catch(err => toast.httpHandler(err)).finally(() => {
        setTimeout(() => this.get(), 5000);
      });
    };

    this.get();
  }
})(angular || {});
