(angular => {

  angular.module("Participant")

    .filter("participantType", [participantType]);

  function participantType() {

    const types = {
      "common": "Comum",
      "staff": "Staff",
    };

    function filter(type) {
      return types[type];
    }

    return filter;
  }

})(angular);
