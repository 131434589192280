(angular => {
  "use strict";

  angular.module("PushNotification")
    .factory("PushNotificationService", [
      "$http",
      "$mdDialog",
      "API",
      "AuthService",
      PushNotificationService
    ]);

  function PushNotificationService($http, $mdDialog, API, AuthService) {

    const list = (params = {}) => {
      params = angular.copy(params);
      const { eventId } = AuthService.getTokenData();

      return $http({
        url: `${API}/push-notification/${eventId}`,
        method: 'GET',
        params
      }).then(({ data }) => data);
    };

    const form = ($event) => {
      return $mdDialog.show({
        templateUrl: `admin/pushNotification/form/form.html`,
        controller: "PushNotification.FormCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        targetEvent: $event
      });
    };

    const push = (title, body) => {

      const { eventId } = AuthService.getTokenData();

      return $http.post(`${API}/push-notification/${eventId}`, { title, body })
        .then(({ data }) => data);
    };

    const history = ($event, notification) => {
      return $mdDialog.show({
        templateUrl: `admin/pushNotification/history/history.html`,
        controller: "PushNotification.HistoryCtrl",
        controllerAs: "$ctrl",
        clickOutsideToClose: true,
        escapeToClose: true,
        fullscreen: true,
        targetEvent: $event,
        locals: {
          notification
        }
      });
    };

    const listHistory = ({ id }, params) => {
      params = angular.copy(params);

      const { eventId } = AuthService.getTokenData();

      return $http({
          url: `${API}/push-notification/${eventId}/${id}/history`,
          method: 'GET',
          params
        })
        .then(({ data }) => data);
    };

    return { list, form, push, history, listHistory };
  }
})(angular);
