(angular => {
  "use strict";

  angular.module("EventTax").component("eventTaxOrderComponent", {
    templateUrl: 'admin/eventTax/index/orders/orders.html',
    controller: [
      "Toast",
      "Loader",
      "EventTaxService",
      OrderCtrl
    ],
    bindings: {
      loadCallback: '='
    }
  });

  function OrderCtrl(toast, loader, eventTaxService) {

    this.loadCallback = this.load = () => {
      loader.add(eventTaxService.listOrders()).then(orders => {
        orders.map(p => {
          if (p.paymentType === "bankDeposit" && (!p.payment)) {
            p.payment = { status: 'pending' };
          }
        });

        this.orders = orders.reduce((acc, order) => {
          let company = acc.find(c => c.id === order.company_id);

          if (!company) {
            company = order.company;
            company.orders = [];
            acc.push(company);
          }

          delete order.company;
          company.orders.push(order);

          return acc;
        }, []);
      }).catch(err => toast.httpHandler(err));
    };

    this.history = ($event, order) => {
      eventTaxService.orderHistory($event, order);
    };

    this.changeStatus = (order, newStatus) => {
      loader.add(eventTaxService.updateOrderStatus(order.id, newStatus)).then(() => {
        toast.show('Pedido alterado', 'success');
        this.load();
      }).catch(err => {
        if (err.data.message === 'invalid-payment-status-flow') {
          toast.show('Ação não permitida', 'error');
          return;
        }
        if (err.data.message === 'spots-used') {
          toast.show('Credenciais já utilizadas', 'error');
          return;
        }

        toast.httpHandler(err);
      });
    };

    this.pay = ($event, order) => {
      if (order.payment) {
        window.open(order.payment.extraData.paymentLink);
        return;
      }

      loader.add(eventTaxService.payOrder(order.id)).then(result => {
        window.open(result.extraData.paymentLink);
        this.load();
      }).catch(err => {
        if (err.data && err.data.message === 'sender-phone-required') {
          return toast.show('É necessário atualizar seus dados, por favor entre em contato com o administrador', 'error');
        }

        toast.httpHandler(err);
      });
    };

    this.load();
  }
})(angular || {});
