(angular => {

  angular.module('Common')

    .filter('companyType', [CompanyTypeFilter]);

  function CompanyTypeFilter() {

    const types = {
      'not informed': 'Nenhum',
      'commercial partner': 'Parceiro Comercial',
      'associated': 'Associado',
      'invited': 'Convidado',
      'government': 'Governo',
      'midia': 'Imprensa',
      'entity': 'Entidade',
      'organizer': 'Organizadora',
      'stand_builder': 'Montadora Oficial',
      'provider': 'Prestador',
      'speaker': 'Palestrante',
      'ticket sale': 'Varejista',
    };

    return type => types[type] || '-';
  }

})(angular);
