((angular) => {
  'use strict';

  angular
    .module('FindRetailerNotBussinesClub')
    .controller('FindRetailerNotBussinesClub.InviteCompanyCtrl', [
      '$scope',
      '$timeout',
      '$routeParams',
      '$mdDialog',
      '$q',
      'XLSReader',
      'Loader',
      'Toast',
      'AuthService',
      'participant',
      'FindRetailerNotBussinesClubService',
      InviteCompany,
    ]);

  function InviteCompany(
    $scope,
    $timeout,
    $routeParams,
    $mdDialog,
    $q,
    XLSReader,
    Loader,
    Toast,
    authService,
    participant,
    FindRetailerNotBussinesClubService
  ) {
    this.user = authService.getTokenData();

    this.participant = participant;
    this.inviteCompanys = [];

    this.cancel = $mdDialog.cancel;

    FindRetailerNotBussinesClubService.findInviteCompany(this.participant.cpf)
      .then((companyName) => {
        if (companyName) {
          this.inviteCompanys = companyName;
          // console.log(this.inviteCompanys);
        } else {
          Toast.show('Nenhuma rede convidou', 'error');
        }
      })
      .catch((err) => Toast.httpHandler(err));
  }
})(angular || {});
