(angular => {

  angular
    .module('Common')

    .filter('companyTypeSponsorship', [CompanyTypeSponsorshipFilter]);

  function CompanyTypeSponsorshipFilter() {

    const types_sponsorship = {
      'business club': 'Business Clube',
      'event punctual': 'Evento Pontual',
      'prospect': 'Prospect'
    };

    return type_sponsorship => types_sponsorship[type_sponsorship] || '*';
  }

})(angular);
