(angular => {
  "use strict";

  angular.module("EventChecklist").controller("EventChecklist.FormCtrl", [
    "$timeout",
    "$mdDialog",
    "Loader",
    "Toast",
    "model",
    "service",
    CreateCtrl
  ]);

  function CreateCtrl($timeout, $mdDialog, loader, Toast, model, service) {
    this.model = model || {};

    this.save = () => {
      return loader.add(service.save(this.model)).then(() => {
        Toast.show(`Checklist salvo com sucesso`, "success");
        $mdDialog.hide(this.model);
      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
