(angular => {
  "use strict";

  angular.module("Accommodation").controller("Accommodation.FormRoomCtrl", [
    "$scope",
    "$mdDialog",
    "Loader",
    "Toast",
    "AccommodationService",
    "EventHotelService",
    "eventCompanyId",
    "room",
    FormRoomCtrl
  ]);

  function FormRoomCtrl($scope, $mdDialog, loader, toast, accommodationService, eventHotelService, eventCompanyId, room) {
    this.model = room || {};
    this.types = eventHotelService.getTypes();
    this.submit = () => {
      this.model.event_company_id = eventCompanyId;

      loader.add(accommodationService.saveRoom(this.model)).then(result => {
        $mdDialog.hide(result);
      }).catch(err => {
        switch ((err.data || {}).message) {
          case 'company-hotel-rooms-excedded':
            toast.show('Número de quartos excedido', 'error');
            break;
          case 'less-spots':
            toast.show('Número de pessoas no quarto é maior que o permitido pelo tipo', 'error');
            break;
          default:
            toast.httpHandler(err);
        }
      });
    };

    this.cancel = $mdDialog.cancel;
  }

})(angular || {});
