(angular => {
  "use strict";

  angular.module("Activity")
    .controller("Activity.FormSpeakerCtrl", ["$mdDialog", "SpeakerService", FormSpeakerCtrl]);

  function FormSpeakerCtrl($mdDialog, SpeakerService) {
    this.save = () => {
      $mdDialog.hide(this.speaker);
    };

    this.cancel = () => {
      $mdDialog.hide();
    };

    this.querySpeaker = term => {
      return SpeakerService.query(term);
    };

    this.changeSpeaker = speaker => {
      if (!speaker) return;
      this.search = speaker.name;
      this.speaker = speaker;
    };
  }
})(angular || {});
