(angular => {

  angular.module("Accommodation", ["Config"])
    .config(["$routeProvider", configRouter]);

  function configRouter($routeProvider) {
    $routeProvider
      .when("/accommodation", {
        controller: "Accommodation.ListCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/accommodation/list/list.html`,
        role: ['admin', 'user', 'company_manager']
      })
      .when("/accommodation/:companyId", {
        controller: "Accommodation.FormCtrl",
        controllerAs: "$ctrl",
        templateUrl: `admin/accommodation/form/form.html`,
        role: ['admin', 'user', 'company_manager']
      });
  }

})(angular);
