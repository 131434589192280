 (angular => {
   "use strict";

   angular.module("EventCompany").controller("EventCompany.FormExhibitorsCtrl", [
     "$scope",
     "$q",
     "$mdDialog",
     "Loader",
     "Toast",
     "CompanyService",
     "EventService",
     "EventCompanyService",
     "EventHotelService",
     "model",
     FormExhibitorsCtrl
   ]);

   function FormExhibitorsCtrl($scope, $q, $mdDialog, Loader, Toast, companyService, eventService, eventCompanyService, eventHotelService, model) {
     this.model = model || {};
     eventService.current().then(event => {
       this.event = event;
     });

     Loader.add(companyService.list()).then(companies => {
       this.companies = companies;
     }).catch(err => Toast.httpHandler(err));

     this.save = () => {
       return Loader.add(eventCompanyService.saveExhibitor(this.model)).then(() => {
         Toast.show("Empresa salva com sucesso", "success");
         $mdDialog.hide();
       }).catch(err => {
         if (err.data.message === 'exhibitors-already-added') {
           Toast.show('Empresa já adicionada', 'error');
           return;
         }

         Toast.httpHandler(err);
       });
     };

     this.cancel = $mdDialog.cancel;

   }
 })(angular || {});
